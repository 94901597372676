import React from 'react';

import { Card, Alert, List } from 'antd';

const ResponseResults = (props) => {
   return (
      <Card>
         {
            props.data && props.data.successes && props.data.successes.length ?
               <Alert
                  closable
                  message="Operazione completata"
                  description={<List
                     size="small"
                     dataSource={props.data.successes}
                     renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                  />}
                  type="success"
                  showIcon
               />
               : null
         }
         {
            props.data && props.data.warnings && props.data.warnings.length ?
               <Alert
                  closable
                  message="Attenzione"
                  description={<List
                     size="small"
                     dataSource={props.data.warnings}
                     renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                  />}
                  type="warning"
                  showIcon
               />
               : null
         }
         {
            props.data && props.data.details ?
               <Alert
                  closable
                  message="Dettagli operazione"
                  type="info"
                  description={<List
                     size="small"
                     dataSource={props.data.details}
                     renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                  />}
                  showIcon
               />
               : null
         }
      </Card>
   )
}

export default ResponseResults;