import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import Moment from 'moment';

import { Table, Card, Input, Select, Button, Space, notification } from 'antd';
import { InfoCircleOutlined, SearchOutlined, UploadOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/vendor_orders';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class VendorOrderDetails extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      loader: true,
      orderDetails: {},
      orderLines: [],
      componentLanguage: this.props.authState.defaultLanguage,
   };

   componentDidMount() {
      const itemId = this.props.match.params.id;
      // asyncronous calls
      storeActions.getItem(itemId)
         .then(response => {
            console.log(response);
            try {
               this.setState({ orderLines: response.data.order_lines, orderDetails: response.data, loader: false });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   getColumnSearchProps = (dataIndex, title) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  this.searchInput = node;
               }}
               placeholder={`Cerca ${title}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
               >
                  Search
            </Button>
               <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
            </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
      onFilterDropdownVisibleChange: visible => {
         if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
         }
      },
      render: (text, record) =>
         this.state.searchedColumn === dataIndex ? (
            <Button className="link" onClick={() => this.props.history.push('/vendor_orders/details/' + record.id)}>
               <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[this.state.searchText]}
                  autoEscape
                  textToHighlight={record[dataIndex] ? record[dataIndex] : ''}
               />
            </Button>
         ) : (
               <Button className="link" onClick={() => this.props.history.push('/vendor_orders/details/' + record.id)}>
                  {record[dataIndex].toString()}
               </Button>
            ),
   });
   handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
         searchText: selectedKeys[0],
         searchedColumn: dataIndex,
      });
   };
   handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
   };

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { Option } = Select;
      const tableLayout = {
         bordered: true,
         size: "small",
         pagination: { pageSize: 50 }
      }
      const order_lines_table_columns = [
         {
            title: 'ASIN',
            width: 120,
            key: 'asin',
            fixed: 'left',
            render: (record) => (
               record.buyer_id
            ),
            sorter: (a, b) => a.buyer_id.localeCompare(b.buyer_id),
            ...this.getColumnSearchProps('asin', 'ASIN'),
         },
         {
            title: 'Vendor Code',
            width: 120,
            key: 'vendor_code',
            fixed: 'left',
            render: (record) => (
               record.vendor_code
            ),
            sorter: (a, b) => a.vendor_code.localeCompare(b.vendor_code),
         },
         {
            title: 'Warehouse ID',
            width: 250,
            key: 'warehouse_id',
            fixed: 'left',
            render: (record) => (
               record.warehouse_id
            ),
            sorter: (a, b) => a.warehouse_id.localeCompare(b.warehouse_id),
            ellipsis: true,
         },
         {
            title: 'Quantità',
            key: 'quantity',
            fixed: 'left',
            render: (record) => (
               record.quantity
            ),
            sorter: (a, b) => a.quantity - b.quantity,
         },
         {
            title: 'Costo unitario',
            key: 'price_unit',
            fixed: 'left',
            render: (record) => (
               record.price_unit + ' €'
            ),
            sorter: (a, b) => a.price_unit - b.price_unit,
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  {/* <Popconfirm
                     placement="topRight"
                     title="Cancelli?"
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button className="link"><i className="far fa-trash-alt"></i></a>
                  </Popconfirm> */}
               </Space>),
         },
      ];
      const order_lines_table = <Table {...tableLayout} columns={order_lines_table_columns} dataSource={this.state.orderLines} />;

      const order_data = (
         <Card title={"Ordine venditore: " + this.state.orderDetails.vendor_order_number}>
            <table>
               <tbody>
                  <tr>
                     <td>Nome ordine</td>
                     <td>{this.state.orderDetails.name}</td>
                  </tr>
                  <tr>
                     <td>Brand</td>
                     <td>{this.state.orderDetails.brand ? this.state.orderDetails.brand.name : 'Non definito'}</td>
                  </tr>
                  <tr>
                     <td>Data ordine</td>
                     <td>{Moment(this.state.orderDetails.order_date).format('L')}</td>
                  </tr>
               </tbody>
            </table>
         </Card>
      );

      const order_wrapper = (
         <div>
            {order_data}
            {order_lines_table}
         </div>
      )

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.vendor_orders.title')}
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              size='large'
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/vendor_order_lines/import/' + this.props.match.params.id)}
                           >
                              Importa linee ordine da CSV
                           </Button>
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : order_wrapper}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">

                     <Space>
                        <Button
                           size='large'
                           icon={<LeftCircleOutlined />}
                           onClick={() => { this.props.history.goBack() }}
                        >Indietro</Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }
      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.vendor_orders.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(VendorOrderDetails);