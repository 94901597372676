import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import _ from 'lodash'

import { Form, Timeline, Select, Button, Space, notification, Card, Typography, List, Tooltip, Row, Col } from 'antd'
import { Input, Table } from 'antd'
import { InfoCircleOutlined, DoubleLeftOutlined, LineChartOutlined, CheckOutlined, CloseOutlined, CheckSquareTwoTone, CloseSquareTwoTone, EyeOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import { startLoading, endLoading } from '../../../shared/utility';
import * as storeActions from './store/actions/searchproducts'

import LookupResultsDrawer from './widget/LookupResultsDrawer'
import Loader from '../../common/PageElements/Loader'
import TimelineItem from 'antd/lib/timeline/TimelineItem'

class SearchProducts extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      loadersLookupCatalogue: [],
      loadersProducts: [],
      productLookupResults: [],
      productsByEan: [],
      showSearchTable: false,
      showLookupResultsDrawer: false,
      componentLanguage: this.props.authState.defaultLanguage,
   };

   componentDidMount() {
      const formValues = {
         language: this.state.componentLanguage
      }
      this.setState({ pageLoader: false });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   //old: search by MPN
   // onFinish = values => {
   //    console.log(values);
   //    this.setState({ formLoader: true });
   //    storeActions.searchProductInCatalogueByMpn(values)
   //       .then(response => {
   //          try {
   //             this.setState({
   //                productsByMpn: response.data,
   //                showSearchTable: true,
   //                formLoader: false
   //             })

   //             notification.open({
   //                message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
   //                description: languageManager.getMessage(this.props.commonState, 'notification.confirm.searchOk'),
   //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //             })
   //          } catch (e) {
   //             this.setState({ formLoader: false });
   //             notification.open({
   //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //                description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
   //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //             })
   //          }

   //       }).catch((e) => {
   //          this.setState({ formLoader: false });
   //          notification.open({
   //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //             description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
   //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //          })
   //       });
   // };
   onFinish = values => {
      console.log(values);
      this.setState({ formLoader: true });
      storeActions.searchProductInCatalogue(values)
         .then(response => {
            this.setState({
               productsByMpn: response.data,
               showSearchTable: true,
               formLoader: false
            })
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.searchOk'),
            })
         }).catch((e) => {
            this.setState({ formLoader: false });
            console.log(e)
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.error({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
      })
   };

   //OLD: ASIN not used
   // lookupAmazonDataByMpnOrEanOrTitle = (mpn, ean, title) => {
   //    console.log(mpn, ean, title);
   //    this.setState({ loadersProducts: startLoading(this.state.loadersProducts, mpn) });
   //    storeActions.lookupAmazonDataByMpnOrEanOrTitle(mpn, ean, title)
   //       .then(response => {
   //          console.log(response)
   //          try {
   //             var newLookupResults = this.state.productLookupResults
   //             newLookupResults[mpn] = !_.isEmpty(response.data) ? { type: response.type, data: response.data } : false
   //             this.setState({
   //                productLookupResults: newLookupResults,
   //                loadersProducts: endLoading(this.state.loadersProducts, mpn)
   //             })

   //             notification.open({
   //                message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
   //                description: languageManager.getMessage(this.props.commonState, 'notification.confirm.searchOk'),
   //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //             })
   //          } catch (e) {
   //             this.setState({ loadersProducts: endLoading(this.state.loadersProducts, mpn) });
   //             notification.open({
   //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //                description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
   //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //             })
   //          }

   //       }).catch((e) => {
   //          this.setState({ loadersProducts: endLoading(this.state.loadersProducts, mpn) });
   //          notification.open({
   //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //             description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
   //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //          })
   //       });
   // }
   lookupAmazonDataByMpnOrAsinOrEanOrTitle = (index, mpn, asin, ean, title) => {
      console.log(mpn, asin, ean, title);
      this.setState({ loadersProducts: startLoading(this.state.loadersProducts, index) });
      storeActions.lookupAmazonDataByMpnOrAsinOrEanOrTitle(mpn, asin, ean, title)
         .then(response => {
            console.log(response)
            try {
               var newLookupResults = this.state.productLookupResults
               newLookupResults[index] = !_.isEmpty(response.data) ? { type: response.type, data: response.data } : false
               this.setState({
                  productLookupResults: newLookupResults,
                  loadersProducts: endLoading(this.state.loadersProducts, index)
               })

               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.searchOk'),
               })
            } catch (e) {
               this.setState({ loadersProducts: endLoading(this.state.loadersProducts, index) });
               console.log(e)
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
            }

         }).catch((e) => {
            this.setState({ loadersProducts: endLoading(this.state.loadersProducts, index) })
            console.log(e)
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
         });
   }

   resetLookup = mpn => {
      var newLookupResults = this.state.productLookupResults
      newLookupResults[mpn] = null
      this.setState({
         productLookupResults: newLookupResults
      })
   }


   showLookupResultsDrawer = (item) => {
      this.setState({ lookupResultsDrawerItem: item, showLookupResultsDrawer: true })
   }
   hideLookupResultsDrawer = () => {
      this.setState({ lookupResultsDrawerItem: null, showLookupResultsDrawer: false })
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }


   render() {
      if (this.state.pageLoader || _.isNil(this.props.authState.userData.languages)) return <Loader />;
      const layout = {
         layout: 'inline',
         // labelCol: { span: 8 },
         // wrapperCol: { span: 16 },
      };
      const { Text, Title } = Typography
      const { Option } = Select
      const searchResultsColumns = [
         {
            title: 'Codice articolo',
            key: 'mpn',
            width: 150,
            fixed: 'left',
            render: (record) => (
               record.mpn
            ),
         },
         {
            title: 'ASIN',
            key: 'asin',
            width: 150,
            fixed: 'left',
            render: (record) => (
               record.asin
            ),
         },
         {
            title: 'EAN/UPC',
            key: 'ean_upc',
            width: 150,
            fixed: 'left',
            render: (record) => (
               record.ean_upc
            ),
         },
         {
            title: 'Prodotto',
            key: 'title',
            render: (record) => (
               record.title
            ),
            sorter: (a, b) => a.title - b.title,
         },
         // {
         //    title: 'Immagine',
         //    key: 'image',
         //    render: (record) => (
         //       record.image_csv
         //    )
         // },
         {
            title: 'Catalogo',
            key: 'in_catalogue',
            width: 70,
            align: 'center',
            fixed: 'right',
            render: (record) => (
               record.in_catalogue ? <CheckSquareTwoTone twoToneColor="#00FF00" /> : <CloseSquareTwoTone twoToneColor="#FF0000" />
            )
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record, index) => (
               <Space size='small'>
                  {
                     record.in_catalogue ? (
                        <Space size='small'>
                           <Link
                              to={{
                                 pathname: '/products/edit/' + (record.parent_id ? record.parent_id : record.id),
                              }}
                           >
                              <Button size="small" type='primary'
                                 icon={<EyeOutlined />}
                              />
                           </Link>
                        </Space>
                     ) : (
                        !_.isNil(this.state.productLookupResults[index]) ? (
                           this.state.productLookupResults[index] ? (
                              <Tooltip
                                 title='Visualizza risultati'
                              >
                                 <Button size="small" type='success'
                                    icon={<CheckOutlined />}
                                    onClick={() => this.showLookupResultsDrawer(this.state.productLookupResults[index])}
                                 />
                              </Tooltip>
                           ) : (
                              <Tooltip
                                 title='Ripeti ricerca'
                              >
                                 <Button size="small" type='danger'
                                    icon={<CloseOutlined />}
                                    onClick={() => this.resetLookup(index)}
                                 />
                              </Tooltip>)
                        ) : (
                           <Space size='small'>
                              <Button size="small" type='primary'
                                 icon={<LineChartOutlined />}
                                 loading={this.state.loadersProducts[index]}
                                 onClick={() => this.lookupAmazonDataByMpnOrAsinOrEanOrTitle(index, record.mpn, record.asin, record.ean, record.title)}
                              />
                           </Space>
                        )
                     )
                  }
                  {/* <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <Button type="primary" size="small"
                        icon={<EditOutlined />}
                        onClick={() => this.editItemHandler(record.id)}
                     />
                  </Tooltip>
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size="small"
                        icon={<DeleteOutlined />}
                     />
                  </Popconfirm> */}
               </Space>),
         },
      ];

      let eanForm = (
         <Form
            {...layout}
            id="processSearchEan"
            name="basic"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               name="mpn"
               label="Codice articolo (MPN)"
               rules={[{ required: false, message: 'Inserisci il codice articolo (MPN)' }]}
            >
               <Input type='text' />
            </Form.Item>
            <Form.Item
               name="asin"
               label="ASIN"
               rules={[{ required: false, message: 'Inserisci il codice ASIN' }]}
            >
               <Input type='text' />
            </Form.Item>
            <Form.Item
               name="ean_upc"
               label="EAN/UPC"
               rules={[{ required: false, message: 'Inserisci il codice EAN/UPC' }]}
            >
               <Input type='text' />
            </Form.Item>
            <Form.Item
               name="title"
               label="Nome"
               rules={[{ required: false, message: 'Inserisci il nome del prodotto' }]}
            >
               <Input type='text' />
            </Form.Item>
            <Form.Item>
               <Button type="primary" htmlType="submit" loading={this.state.formLoader}>Cerca</Button>
            </Form.Item>
         </Form>
      );

      const order_wrapper = (
         <div>
            <Card>
               <Timeline>
                  {/* <Timeline.Item>
                  <Button type="default">Scarica modello file</Button>
               </Timeline.Item> */}
                  <Timeline.Item>
                     <Space direction='vertical'>
                        <Text>Inserisci i dati del prodotto da cercare</Text>
                        {eanForm}
                     </Space>
                  </Timeline.Item>
                  {/* <Timeline.Item>
                  <Button form="processGroupSizes" type="primary" htmlType="submit" disabled={!this.state.fileUploaded} loading={this.state.formLoader}>Elabora file caricato</Button>
               </Timeline.Item> */}
               </Timeline>
               {
                  this.state.showSearchTable ? (
                     <Timeline.Item>
                        <Space direction='vertical' style={{ width: '100%' }}>
                           <Text>Prodotti ricercati</Text>
                           <Table
                              key='ean_search_results'
                              dataSource={this.state.productsByMpn}
                              columns={searchResultsColumns}
                              size='small'
                              bordered={true}
                              pagination={false}
                              loading={this.state.formLoader}
                           />
                        </Space>
                     </Timeline.Item>
                  ) : null
               }
            </Card>
            <LookupResultsDrawer
               visible={this.state.showLookupResultsDrawer}
               item={this.state.lookupResultsDrawerItem}
               onClose={this.hideLookupResultsDrawer}
            />
         </div>
      )

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  <div className="card">
                     <div className="card-header">
                        <Row gutter={16} align='middle'>
                           <Col>
                              <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.search_products.title')}</Title>
                           </Col>
                           <Col flex='auto'></Col>
                           <Col>
                              <Space size='small'>
                                 <Select
                                    showSearch
                                    className="width-70px-I"
                                    placeholder="Seleziona la lingua"
                                    defaultValue={this.state.componentLanguage}
                                    optionFilterProp="children"
                                    onChange={this.setDefaultLanguageHandler}
                                 >
                                    {
                                       this.props.authState.userData.languages.map((lang) => {
                                          return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                       })
                                    }
                                 </Select>
                              </Space>
                           </Col>
                        </Row>
                     </div>
                     <div className="card-body">
                        {order_wrapper}
                     </div>
                     <div className="card-footer">
                        <Row gutter={16}>
                           <Col>
                              <Space size='small'>
                                 <Link to={{ pathname: "/products/list" }} >
                                    <Button icon={<DoubleLeftOutlined />}>
                                       {languageManager.getMessage(this.props.commonState, 'common.back')}
                                    </Button>
                                 </Link>
                              </Space>
                           </Col>
                           <Col flex='auto'></Col>
                           <Col>
                              <Space>
                                 <Button onClick={() => window.location.reload()}>Annulla</Button>
                              </Space>
                           </Col>
                        </Row>
                     </div>
                  </div>
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(SearchProducts);