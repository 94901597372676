import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../services/languageManager'
import * as storeActions from '../store/actions/analysis'

import { Descriptions, Space, notification, Typography, Card, Table } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import AmazonDataModal from '../../catalogue/products/widget/AmazonDataModal'

class CategoryAmazonBestSellersBox extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        categoryAmazonBestSellers: {},
        showProductAmazonDataModal: false,
        selectedProductAmazonDataId: null,
        // rangeChangeLoader: false,
        // rangeLoaders: [],
        // ordersChartData: [],
        // ordersChartDataByDayAverage: [],
        // orderValuesChartData: [],
        // bestSellingItem: {},
        // rangeSelection: [],
        // rangeSelected: false,
    }

    componentDidMount() {
        console.log(this.props)
        Promise.all([
            this.getCategoryAmazonBestSellers(this.props.categoryId),
            // this.getCountOrdersBoxData(),
            // this.prepareRangeSelection(),
        ]).then(() => {
            this.setState({
                componentLoader: false,
                // rangeChangeLoader: false,
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (
            _.isEqual(nextProps.categoryId, this.props.categoryId)
            && _.isEqual(nextState, this.state)
        ) {
            return false
        }
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('componentDidUpdate')
        // console.log(prevProps.categoryId, this.props.categoryId)
        if (!_.isEqual(prevProps.categoryId, this.props.categoryId)) {
            // console.log('componentDidUpdate CONDITION OK')
            this.setState({ componentLoader: true });
            Promise.all([
                this.getCategoryAmazonBestSellers(this.props.categoryId),
                // this.getCountOrdersBoxData(),
                // this.prepareRangeSelection(),
            ]).then(() => {
                this.setState({
                    componentLoader: false,
                    // rangeChangeLoader: false,
                });
            });
        }
    }

    getCategoryAmazonBestSellers = (categoryId) => {
        if (!_.isNil(categoryId)) {
            console.log(categoryId)
            return storeActions.getCategoryAmazonBestSellers(categoryId)
                .then(response => {
                    console.log(response);
                    try {
                        this.setState({
                            categoryAmazonBestSellers: response.data,
                        });
                    } catch (e) {
                        notification.open({
                            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                            description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                        })
                    }
                }).catch((e) => {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                });
        } else {
            return false
        }
    }

    showProductAmazonData = (productAmazonDataId) => {
        // console.log('showProductAmazonData: ' + productAmazonDataId)
        if (productAmazonDataId) {
            this.setState({
                selectedProductAmazonDataId: productAmazonDataId,
                showProductAmazonDataModal: true,
            })
        }
    }
    hideProductAmazonDataModal = () => {
        this.setState({
            selectedProductAmazonDataId: null,
            showProductAmazonDataModal: false,
        })
    }

    render() {
        const { Title } = Typography;
        const amazonData = this.state.categoryAmazonBestSellers
        const productsColumns = [
            {
                title: 'ASIN',
                key: 'asin',
                render: (record) => (
                    record.asin
                ),
            },
            {
                title: 'Prodotto',
                key: 'title',
                render: (record) => (
                    <a onClick={() => this.showProductAmazonData(record.id)}>{record.title}</a>
                ),
                sorter: (a, b) => a.title - b.title,
            },
            {
                title: 'Immagine',
                key: 'image',
                render: (record) => (
                    record.image_csv
                )
            },
            {
                title: 'Prezzo listino',
                key: 'list_price',
                width:100,
                render: (record) => (
                    record.list_price ? record.list_price : null
                )
            },
            {
                title: 'Prezzo BuyBox',
                key: 'buybox_price',
                width:100,
                render: (record) => (
                    record.buybox_price ? record.buybox_price : null
                )
            },
            {
                title: 'Catalogo',
                key: 'image',
                render: (record) => (
                    record.product ? 'SI' : 'NO'
                )
            },
        ];
        return (
            <Card loading={this.state.componentLoader || this.state.dataLoader}
                title={
                    <Space direction='vertical' size='small'>
                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.analysis.categories.amazon_best_sellers.title')}</Title>
                    </Space>
                }
                bordered={true}
                className="card-analysis-data"
            >
                {
                    !_.isNil(amazonData) ? (
                        <div>
                            <Descriptions
                                bordered
                                size='small'
                                column={1}
                            >
                                <Descriptions.Item label="Ultimo aggiornamento">{amazonData.last_update}</Descriptions.Item>
                            </Descriptions>
                            <Table
                                key='best_seller_products'
                                dataSource={amazonData.products}
                                columns={productsColumns}
                                size='small'
                                pagination={false}
                                className="table-in-card"
                            />
                            <AmazonDataModal
                                visible={this.state.showProductAmazonDataModal}
                                productDataId={this.state.selectedProductAmazonDataId}
                                onClose={() => this.hideProductAmazonDataModal()}
                            />
                        </div>
                    ) : 'Nessun dato disponibile'
                }
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(CategoryAmazonBestSellersBox)