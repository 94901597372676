import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import Moment from 'moment';
import _ from 'lodash';

import API_BASE from '../../api/apiBase';
import API_CONFIG from '../../api/apiConfig';

import { Form, Timeline, Select, Button, Space, notification, Card, Typography, Row, Col } from 'antd';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { InfoCircleOutlined, DoubleLeftOutlined, UploadOutlined } from '@ant-design/icons';

import languageManager from '../../services/languageManager';
import * as storeActions from './store/actions/dataprocessing';

import Loader from '../common/PageElements/Loader';
import PageHeader from '../common/PageElements/PageHeader'
import TimelineItem from 'antd/lib/timeline/TimelineItem';

class SearchAsinsInOrders extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage,
      fileUploaded: false,
      chargebacksLink: false
   };

   componentDidMount() {
      const formValues = {
         language: this.state.componentLanguage
      }
      this.setState({ pageLoader: false });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   onFinish = values => {
      console.log(values);
      this.setState({ formLoader: true });
      const data = {};
      data.file_path = values.fileImport[0].response.data.file_path;
      data.file_name = values.fileImport[0].response.data.file_name;
      if (!data.file_path || !data.file_name) {
         this.setState({ formLoader: false });
         notification.open({
            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
            description: languageManager.getMessage(this.props.commonState, 'notification.error.dataNotValid'),
            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
         })
      }
      else {
         let now = Moment().format('YYYY.MM.DD-HH.mm.ss')
         let export_file_name = now + '_search_asins_in_orders.csv'
         data.export_file_name = export_file_name
         storeActions.searchAsinsInOrders(data)
            .then(response => {
               try {
                  this.setState({ formLoader: false })

                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.confirm.exportOk'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               } catch (e) {
                  this.setState({ formLoader: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               }

            }).catch((e) => {
               this.setState({ formLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            });
      }
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }


   render() {
      if (this.state.pageLoader || _.isNil(this.props.authState.userData.languages)) return <Loader />
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };
      const { Title, Text, Link } = Typography
      const { Option } = Select

      const scope = this

      const normFile = (e) => {
         // console.log('Upload event:', e);
         if (Array.isArray(e)) {
            return e;
         }
         if(e.fileList.length > 1) {
            e.fileList.shift()
         }
         return e && e.fileList
      };

      const uploadProps = {
         name: 'file',
         multiple: false,
         // disabled: this.state.draggerDisable,
         action: API_BASE.url + API_CONFIG.appUrls.files.fileImport,
         onChange(info) {
            scope.setState({ fileUploaded: info.fileList.length })
            const { status } = info.file
            if (status !== 'uploading') {
               console.log(info.file, info.fileList)
            }
            if (status === 'done') {
               message.success(`${info.file.name} file uploaded successfully.`)
               scope.setState({ step: 2 })
            } else if (status === 'error') {
               message.error(`${info.file.name} file upload failed.`)
            }
         },
      };

      let uploadForm = (
         <Form
            {...layout}
            id="processSearchAsins"
            name="basic"
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               name="fileImport"
               rules={[{ required: true, message: 'Scegli un file' }]}
               valuePropName="fileList"
               getValueFromEvent={normFile}
               colon={false}
               wrapperCol={24}
            >
               <Upload {...uploadProps}>
                  <Button icon={<UploadOutlined />}>Scegli il file</Button>
               </Upload>
            </Form.Item>
         </Form>
      );

      const instruction_1 = (
         <Card>
            <Timeline>
               {/* <Timeline.Item>
                  <Button type="default">Scarica modello file</Button>
               </Timeline.Item> */}
               <Timeline.Item>
                  <Space direction='vertical'>
                     <Text>Importa CSV "ASIN"</Text>
                     {uploadForm}
                  </Space>
               </Timeline.Item>
               <Timeline.Item>
                  <Button form="processSearchAsins" type="primary" htmlType="submit" disabled={!this.state.fileUploaded} loading={this.state.formLoader}>Elabora file caricato</Button>
               </Timeline.Item>
            </Timeline>
         </Card>
      );

      const pageWrapper = (
         <div>
            {instruction_1}
         </div>
      )

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.search_asin_in_orders.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Select
                           showSearch
                           className="width-70px-I"
                           placeholder="Seleziona la lingua"
                           defaultValue={this.state.componentLanguage}
                           optionFilterProp="children"
                           onChange={this.setDefaultLanguageHandler}
                        >
                           {
                              this.props.authState.userData.languages.map((lang) => {
                                 return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                              })
                           }
                        </Select>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               {pageWrapper}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <RouterLink to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </RouterLink>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Button onClick={() => window.location.reload()}>Annulla</Button>
                        <Button form="importFile" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Importa</Button>
                     </Space>
                  </Col>
               </Row>
            </div>
         </div>
      );

      const buttons = [];

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(SearchAsinsInOrders);