import React, { Component } from 'react';
import { connect } from 'react-redux';

import Moment from 'moment';

import { Form, Input, Select, DatePicker, Button, Space, notification } from 'antd';
import { InfoCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/vendor_orders';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class VendorOrdersEdit extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      loader: true,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage,
      brandsList: [],
   };

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         name: response.name,
         brand_id: response.brand_id,
         date: response.date,
         id: response.id,
         language: lang
      }
      return values;
   }

   componentDidMount() {
      storeActions.getVendorOrdersComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  response: response,
                  brandsList: response.brandsList,
               });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id;
         this.setState({ action: 'edit' });
         // asyncronous calls
         storeActions.getItem(itemId)
            .then(response => {
               console.log(response);
               try {
                  const updatedValues = this.setForm(response.data);
                  this.setState({ response: response.data, formValues: updatedValues, loader: false });

                  // const updatedItemList = updateObject(this.state.itemList, response);
                  // this.setState({itemList: updatedItemList});

                  //  const loadedData = response.data;

                  //  const newState = {...this.state};
                  //  newState.categoriestotals = loadedData;
                  //  this.setState(newState);
                  //  // this.props.showMessage({message: 'Categories totals loaded'});

               } catch (e) {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               }

            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage
         }
         this.setState({ formValues: formValues, action: 'insert', loader: false });
      }

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   onFinish = values => {
      // console.log(values);
      // console.log(Moment(values.date).format('L'));
      this.setState({ loader: true });
      if(values.date) {
         values.order_date = Moment(values.date).format('L');
      }
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }
               const updatedValues = this.setForm(response.data);
               this.setState({ response: response.data, formValues: updatedValues, loader: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   onDateChange(date, dateString) {
      console.log(date, dateString);
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   render() {
      if (!this.props.authState.userData.languages) return '';
      const mainLayout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      // const tabFullLayout = {
      //    labelCol: { span: 8 },
      //    wrapperCol: { span: 14 },
      // };
      // const tabMainLayout = {
      //    labelCol: { span: 6 },
      //    wrapperCol: { span: 20 },
      // };
      // const tabSideLayout = {
      //    labelCol: { span: 4 },
      //    wrapperCol: { span: 22 },
      // };
      // const tailLayout = {
      //    wrapperCol: { offset: 8, span: 16 },
      // };
      const { Option } = Select;

      let form = (
         <Form
            {...mainLayout}
            id="addVendorOrder"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Nome"
               name="name"
               rules={[{ required: true, message: 'Inserisci un nome per l\'ordine' }]}
            >
               <Input />
            </Form.Item>

            <Form.Item
               label="ID ordine"
               name="vendor_order_id"
               rules={[{ message: 'Inserisci l\'identificativo dell\'ordine' }]}
            >
               <Input />
            </Form.Item>

            <Form.Item
               label="Brand"
               name="brand_id"
            >
               <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona il brand"
                  defaultValue={this.state.formValues.brand_id}
                  optionFilterProp="children"
               >
                  {
                     this.state.brandsList.map((brand) => {
                        return <Option key={brand.id} value={brand.id}>{brand.name}</Option>
                     })
                  }
               </Select>
            </Form.Item>

            <Form.Item
               label="Seleziona la data"
               name="date"
               rules={[]}
            >
               <DatePicker onChange={this.onDateChange} />
            </Form.Item>

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>


         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.vendor_orders.title')}
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">

                     <Space>
                        <Button
                           size='large'
                           icon={<LeftCircleOutlined />}
                           onClick={() => { this.props.history.goBack() }}
                        >Indietro</Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="addVendorOrder" htmlType="reset">Annulla</Button>
                           <Button form="addVendorOrder" type="primary" htmlType="submit">Salva</Button>
                           <Button form="addVendorOrder" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.vendor_orders.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(VendorOrdersEdit);