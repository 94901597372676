import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import Moment from 'moment'

import { Drawer, Form, Input, InputNumber, Button, Space, DatePicker, Select, notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import languageManager from '../../../../services/languageManager'
import * as storeActions from '../store/actions/products'

class CreateStockDrawer extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      formLoader: false,
      formValues: {},
      suppliersList: [],
      componentLanguage: this.props.authState.defaultLanguage
   }
   formRef = React.createRef();

   componentDidMount() {
      // console.log('Component did mount')
      Promise.all([
         // this.getSuppliersList()
      ]).then(() => {
         this.setState({
            componentLoader: false
         });
      });
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      // console.log('Component did update', nextProps, this.state)
      if (_.isEqual(nextProps.visible, this.state.visible)) {
         return false
      }
      return true
   }

   componentDidUpdate = (prevProps, prevState) => {
      // console.log('Component did update', prevState, this.props)
      if (!_.isEqual(prevState.visible, this.props.visible)) {
         if (!_.isNil(this.props.item)) {
            var newFormValues = { ...this.props.item }
            newFormValues.purchase_date = newFormValues.purchase_date ? Moment(newFormValues.purchase_date) : null
            newFormValues.expiry_date = newFormValues.expiry_date ? Moment(newFormValues.expiry_date) : null
            this.setFormValues(newFormValues)
         }
         // this.setState({ ...this.props })
         // console.log('Component did update - update state', prevState, this.props)
      }
   }

   getSuppliersList = (current_supplier = false) => {
      
   }

   setFormValues = (item) => {
      // console.log('Set form values')
      // console.log(item)
      // console.log(this.formRef)
      if (this.formRef.current) {
         this.formRef.current.setFieldsValue(item)
      }
   }

   onFinish = values => {
   };

   onClose = () => {
      console.log(this.formRef)
      this.formRef.current.resetFields()
      this.state.onClose()
   }

   render() {
      console.log('Render stock drawer', this.state)
      const { Option } = Select

      return (
         <Drawer
            title="Gestisci disponibilità di magazzino"
            width={500}
            onClose={this.onClose}
            visible={this.props.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.onClose} disabled={this.state.formLoader}>Chiudi</Button>
                     <Button form="createStockDrawer" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                  </Space>
               </div>
            }
         >
            <Form layout="horizontal"
               ref={this.formRef}
               id="createStockDrawer"
               initialValues={this.state.formValues}
               onFinish={this.onFinish}
               onFinishFailed={this.onFinishFailed}>
               <Form.Item
                  label="Fornitore"
                  name="supplier_id"
                  rules={[{ required: true, message: 'Scegli un valore' }]}
               >
                  <Select
                     showSearch
                     placeholder="Seleziona il fornitore"
                     optionFilterProp="children"
                     filterOption={(input, option) =>
                         option.props.searchvalue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }
                  >
                     {
                        this.state.suppliersList.map((supplier) => {
                           return <Option key={supplier.id} value={supplier.id} searchvalue={supplier.company}>{supplier.company}</Option>
                        })
                     }
                  </Select>
               </Form.Item>
               <Form.Item
                  label="Lotto"
                  name="batch"
               >
                  <Input />
               </Form.Item>
               <Form.Item
                  label="Data di acquisto"
                  name="purchase_date"
               >
                  <DatePicker
                     placeholder="Data di acquisto"
                     format="DD-MM-YYYY"
                     allowClear={true}
                  />
               </Form.Item>
               <Form.Item
                  label="Data di scadenza"
                  name="expiry_date"
               >
                  <DatePicker
                     placeholder="Data di scadenza"
                     format="DD-MM-YYYY"
                     allowClear={true}
                  />
               </Form.Item>
               <Form.Item
                  label="Quantità"
                  name="quantity"
                  rules={[{ required: true, message: 'Inserisci la quantità' }]}
               >
                  <InputNumber
                     precision={0}
                     step={1}
                  />
               </Form.Item>
               <Form.Item name="product_id" initialValue={this.props.productId} noStyle><Input type="text" type="hidden" /></Form.Item>
               <Form.Item label="language" name="language" initialValue={this.state.componentLanguage} noStyle><Input type="text" type="hidden" /></Form.Item>
            </Form>
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(CreateStockDrawer)