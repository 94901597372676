import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Table, Select, Button, Space, notification, Popconfirm } from 'antd';
import { InfoCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { PlusCircleOutlined, CloseOutlined, EditTwoTone, DeleteTwoTone } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/categories';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class CategoriesList extends Component {
   state = {
      pageInit: true,
      loader: true,
      parentId: null,
      itemList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage
   };

   getData = (parentId) => {
      // asyncronous calls
      storeActions.getList(parentId)
         .then(response => {
            this.setState({ loader: false });
            console.log(response.data);
            try {

               this.setState({ itemList: response.data });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      // if (this.props.match.params.parent) {
      //    // this.setState({ parentId: this.props.match.params.parent });

      // }
      console.log(this.state);
      console.log(this.props.authState);

      this.getData(this.props.match.params.parent);
   }

   // shouldComponentUpdate(nextProps, nextState) {
   //    console.log('Should component update', this.state.parentId, nextState.parentId);
   //    // return true;
   //    return this.state.pageInit || (this.state.parentId !== nextState.parentId);
   // }

   componentDidUpdate(prevProps, prevState) {
      // debugger;
      if (this.props.match.params.parent !== prevProps.match.params.parent) {
         console.log('Component update');
         this.setState({ loader: true });

         this.getData(this.props.match.params.parent);
      }
   }

   changeLevelHandler = (parentId) => {
      if (parentId) {
         this.props.history.push('/categories/list/' + parentId);
      }
      else {
         this.props.history.push('/categories/list');
      }
   }

   editItemHandler = (itemId) => {
      this.props.history.push('/categories/edit/' + itemId);
   }

   orderLinkHandler = () => {
      this.props.history.push('/categories/order');
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               // let list = [...this.state.itemList];
               // list = list.filter(item => item.id !== itemId);

               this.setState({ itemList: response.data, loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            break;
      }
   }

   toggleValueHandler = (itemId, value) => {
      this.setState({ loader: true });
      storeActions.toggleValue(itemId, value, this.state.componentLanguage)
         .then(response => {
            console.log(response);
            try {
               // this.setState({ itemData: response.data });

               const newState = { ...this.state };
               const itemIndex = newState.itemList.findIndex((obj => obj.id === response.data.id));
               console.log("Found index", itemIndex);
               newState.itemList[itemIndex] = response.data;
               console.log("Item", newState.itemList[itemIndex]);
               this.setState({ itemList: newState.itemList, loader: false });
               console.log(response.data);
               console.log(this.state);



               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.success.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      this.setState({ componentLanguage: value })
   }

   // closeActionHandler = () => {
   //    alert("ciao");
   // }

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { Option } = Select;
      console.log('Rendering...');
      const table_columns = [
         { title: 'Codice', width: 100, dataIndex: 'internal_code', key: 'internal_code', fixed: 'left' },
         {
            title: 'Nome',
            width: 100,
            key: 'name',
            fixed: 'left',
            render: (record) => (
               <a onClick={() => this.changeLevelHandler(record.id)}>
                  <Space size="small">
                     <PlusCircleOutlined />
                     {record.name[this.state.componentLanguage]}
                  </Space>
               </a>
            ),
            sorter: (a, b) => a.name[this.state.componentLanguage].length - b.name[this.state.componentLanguage].length,
         },
         // {
         //    title: 'Descrizione',
         //    width: 100,
         //    key: 'description',
         //    fixed: 'left',
         //    render: (record) => (
         //       !_.isNil(record.description[this.state.componentLanguage]) ? record.description[this.state.componentLanguage] : ''
         //    )
         // },
         // {
         //    title: languageManager.getMessage(this.props.commonState, 'common.visible'), width: 100, dataIndex: 'visible', key: 'percentage', fixed: 'center',
         //    render: (text, record) => (
         //       <span>
         //          <Button className="link" onClick={() => this.toggleValueHandler(record.id, 'visible')}>{record.visible[this.state.componentLanguage] ? <CheckOutlined className="color-green-I" /> : <CloseOutlined className="color-red-I" />}</Button>
         //       </span>),
         //    filters: [
         //       {
         //          text: languageManager.getMessage(this.props.commonState, 'common.visible'),
         //          value: true,
         //       },
         //       {
         //          text: languageManager.getMessage(this.props.commonState, 'common.not_visible'),
         //          value: false,
         //       },
         //    ],
         //    filterMultiple: false,
         //    onFilter: (value, record) => record.visible[this.state.componentLanguage] === value,
         // },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button className="link" onClick={() => this.editItemHandler(record.id)} title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <EditTwoTone />
                  </Button>
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button className="link" title={languageManager.getMessage(this.props.commonState, 'common.delete')}><DeleteTwoTone /></Button>
                  </Popconfirm>
               </Space>),
         },
      ];

      const tableLayout = {
         bordered: true
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.categories.title')}
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              size='large'
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => { this.props.history.push('/categories/order') }}
                           >
                              Ordina categorie
                        </Button>
                           <Button
                              size='large'
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => this.props.history.push('/categories/edit/')}
                           >
                              Crea categoria
                        </Button>
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : (
                  <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} onChange={onChange} scroll={{ x: 1000 }} />
               )}
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  onClick={() => this.changeLevelHandler(this.state.parentId)}
               >
                  <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                  {languageManager.getMessage(this.props.commonState, 'common.back')}
               </Button>
            </div>
         </div>
      );

      function onChange(pagination, filters, sorter, extra) {
         console.log('params', pagination, filters, sorter, extra);
      }

      // const handleButtonClick = (url) => {
      //    if(!url) return;
      //    this.props.history.push({
      //       pathname:  url,
      //       // state: {
      //       //    response: messageFromServer 
      //       // } 
      //    });
      // }

      const helpButton = {
         title: "Aiuto"
      }
      // const newButton = {
      //    title: "NEW",
      //    onclick: () => handleButtonClick('/categories/order')
      // }

      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.categories.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(CategoriesList);