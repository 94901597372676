import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Table, Select, Button, Space, notification } from 'antd';
import { InfoCircleOutlined, PlusSquareOutlined, LeftCircleOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/specifics';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class SpecificsList extends Component {
   state = {
      loader: true,
      itemList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage
   };

   componentDidMount() {
      console.log(this.state);

      // asyncronous calls
      storeActions.getList()
         .then(response => {
            console.log(response);
            try {
               this.setState({ itemList: response.data, loader: false });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   editItemHandler = (itemId) => {
      this.props.history.push('/specifics/edit/' + itemId);
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               // let list = [...this.state.itemList];
               // list = list.filter(item => item.id !== itemId);

               this.setState({ itemList: response.data, loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            break;
      }
   }

   // createItemHandler = () => {
   //    this.props.history.push('/taxes/taxrates/edit/');
   // }

   toggleValueHandler = (itemId, value) => {
      this.setState({ loader: true });
      storeActions.toggleValue(itemId, value)
         .then(response => {
            try {
               const newState = { ...this.state };
               const itemIndex = newState.itemList.findIndex((obj => obj.id === response.data.id));
               newState.itemList[itemIndex] = response.data;
               this.setState({ itemList: newState.itemList, loader: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.success.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   }

   // closeActionHandler = () => {
   //    alert("ciao");
   // }

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { Option } = Select;
      const table_columns = [
         { 
            title: 'Nome',
            width: 100,
            key: 'name',
            fixed: 'left',
            render: (record) => (
               <Button className="link" onClick={() => this.props.history.push('/specifics/values/list/' + record.id)}>
                  {record.name[this.state.componentLanguage]}
                  </Button>
            ),
            sorter: (a, b) => a.name[this.state.componentLanguage].localeCompare(b.name[this.state.componentLanguage]),
         },
         // {
         //    title: 'Attiva', width: 100, dataIndex: 'active', key: 'percentage', fixed: 'center', align: 'center',
         //    render: (text, record) => (
         //       <span>
         //          <a onClick={() => this.toggleValueHandler(record.id, 'active')}>{record.active ? <CheckOutlined className="color-green-I" /> : <CloseOutlined className="color-red-I" />}</a>
         //       </span>),
         //    filters: [
         //       {
         //          text: languageManager.getMessage(this.props.commonState, 'common.active'),
         //          value: true,
         //       },
         //       {
         //          text: languageManager.getMessage(this.props.commonState, 'common.not_active'),
         //          value: false,
         //       },
         //    ],
         //    filterMultiple: false,
         //    onFilter: (value, record) => record.active === value,
         // },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button className="link" onClick={() => this.props.history.push('/specifics/values/list/' + record.id)} title="Mostra valori"><i className="far fa-eye"></i></Button>
                  {/* <a onClick={() => this.editItemHandler(record.id)} title={text}><i className="far fa-edit"></i></a>
                  <Popconfirm
                     placement="topRight"
                     title="Cancelli?"
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button className="link"><i className="far fa-trash-alt"></i></Button>
                  </Popconfirm> */}
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         pagination: { pageSize: 50 }
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.specifics.title')}
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              size='large'
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => this.props.history.push('/specifics/edit/')}
                           >{languageManager.getMessage(this.props.commonState, 'component.specifics.create_new')}</Button>
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} scroll={{ x: 1000 }} />}
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  icon={<LeftCircleOutlined />}
                  onClick={() => { this.props.history.goBack() }}
               >Indietro</Button>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }
      const buttons = [];
      buttons.push(helpButton);

      // const editForm = (
      //    <div className="card">
      //       <div className="card-header">Tasse</div>
      //       <div className="card-body">
      //          <TaxRatesForm action={this.state.action} itemData={this.state.itemData} closeAction={() => this.closeActionHandler()} />
      //       </div>
      //    </div>
      // );

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.specifics.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getSpecificsList() )

//     };
// };

export default connect(mapStateToProps)(SpecificsList);