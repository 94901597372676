import React from 'react';
import { Space } from 'antd';

// import classes from './PageHeader.css';

const pageHeader = (props) => {

   return (
      <div className="align-items-center row">
         <div className="col-sm-6">
            <div className="page-title-box">
               <h4 className="font-size-18">{props.title}</h4>
               {/* <Breadcrumb /> */}
            </div>
         </div>
         <div className="col-sm-6">
            <div className="float-right d-none d-md-block">
               <Space>
                  {/* {props.buttons.map( (button, index) => {
                     return (
                        <Button
                           key = { index }
                           size = 'large'
                           type = { button.style ? button.style : 'default' }
                           icon = { button.icon==='new' ? <PlusSquareOutlined /> : ''}
                           onClick = { button.onclick }
                        >
                           {button.title}
                        </Button>
                     )
                  })} */}
               </Space>
               {/* <div className="dropdown">
                  <button type="button" aria-haspopup="true" aria-expanded="false" className="btn btn-primary dropdown-toggle waves-effect waves-light btn btn-primary"><i className="mdi mdi-settings mr-2"></i> Settings</button>
                  <div tabIndex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                     <Button className="link" tabIndex="0" role="menuitem" className="dropdown-item">Action</Button>
                     <Button className="link" tabIndex="0" role="menuitem" className="dropdown-item">Another action</Button>
                     <Button className="link" tabIndex="0" role="menuitem" className="dropdown-item">Something else here</Button>
                     <div tabIndex="-1" className="dropdown-divider"></div>
                     <Button className="link" tabIndex="0" role="menuitem" className="dropdown-item">Separated link</Button>
                  </div>
               </div> */}
            </div>
         </div>
      </div>
   );
};

export default pageHeader;

