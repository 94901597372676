import React, { Component } from 'react';
import _ from 'lodash'
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, Button, Space, Select } from 'antd';

const { Option } = Select;

class SpecificsSelect extends Component {
   state = {
      ...this.props,
      valuesList: [],
      currentSpecific: null,
      currentValue: null,
   }

   // constructor(props) {
   //    super(props);

   //    this.state = {
   //       ...props,
   //       valuesList: [],
   //       currentValue: null,
   //    };
   // }

   componentDidMount = () => {
      console.log(this.state);
      if (!_.isNil(this.state.currentSpec)) {
         // console.log(this.state.currentSpecific.id);
         // console.log(this.getListIndex(this.state.specificsList, this.state.currentSpecific.specific.id));
         this.setState(
            {
               valuesList: this.state.specificsList[this.getListIndex(this.state.specificsList, this.state.currentSpec.specific.id)].specific_values,
               currentSpecific: this.state.currentSpec.specific,
               currentValue: this.state.currentSpec,
            }
         )
      }
   }

   // shouldComponentUpdate(nextProps, nextState) {
   //    console.log(nextProps);
   //    console.log(this.props);
   //    if (!_.isEqual(this.state.currentSpec, nextProps.currentSpec)) {
   //       return true
   //    }
   //    return false
   // }

   componentDidUpdate = (prevProps, prevState) => {
      // console.log('prevState', prevState)
      // console.log('state', this.state)
      // console.log('prevProps', prevProps)
      console.log('props', this.props)

      if (!_.isEqual(prevState.currentSpecificId, this.props.currentSpecificId)) {
         console.log('PROPS UPDATE')
         this.setState(
            {
               ...this.props,
               valuesList: [],
               currentSpecific: null,
               currentValue: null,
            }
         )
         if (!_.isNil(this.state.currentSpec)) {
            // console.log(this.state.currentSpecific.id);
            // console.log(this.getListIndex(this.state.specificsList, this.state.currentSpecific.specific.id));
            this.setState(
               {
                  valuesList: this.state.specificsList[this.getListIndex(this.state.specificsList, this.state.currentSpec.specific.id)].specific_values,
                  currentSpecific: this.state.currentSpec.specific,
                  currentValue: this.state.currentSpec,
               }
            )
         }
      }
   }

   getListIndex = (list, id) => {
      let found
      _.forEach(list, function (obj, index) {
         // console.log(spec, index);
         if (obj.id === id) {
            found = index
            return false
         }
      });
      return parseInt(found)
   }


   handleSpecificChange = (value) => {
      // console.log(value);
      // console.log(this.getListIndex(this.state.specificsList, value));
      this.setState(
         {
            valuesList: this.state.specificsList[this.getListIndex(this.state.specificsList, value)].specific_values,
            currentSpecific: this.state.specificsList[this.getListIndex(this.state.specificsList, value)],
            currentValue: null,
            currentSpecificId: null
         }
      )
   };

   handleValueChange = (value) => {
      // this.setState(
      //    {
      //       currentValue: this.state.valuesList[this.getListIndex(this.state.valuesList, value)],
      //       currentSpecificId: value
      //    }
      // )
      let specific = null
      _.forEach(this.state.valuesList, function (obj) {
         if (obj.id === value) {
            specific = obj
            return false
         }
      });
      if (!_.isNil(specific)) {
         specific.specific = {...this.state.currentSpecific}
         specific.specific.specific_values = null
         this.state.updateSpecific(this.state.index, specific)
      }
   };
   render() {
      console.log(this.state)
      return (
         <Space>
            <Select
               showSearch
               style={{ width: 300 }}
               placeholder="Seleziona la specifica"
               onChange={this.handleSpecificChange}
               optionFilterProp="children"
               filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               }
               // filterSort={(optionA, optionB) =>
               //    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
               // }
               value={_.isNil(this.state.currentSpecific) ? '' : this.state.currentSpecific.name.it}

            >
               {this.state.specificsList.map(item => (
                  <Option key={item.id} value={item.id}>
                     {item.t_name}
                  </Option>
               ))}
            </Select>
            <Select
               showSearch
               style={{ width: 300 }}
               placeholder="Seleziona il valore"
               onChange={this.handleValueChange}
               optionFilterProp="children"
               filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               }
               // filterSort={(optionA, optionB) =>
               //    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
               // }
               value={_.isNil(this.state.currentValue) ? '' : this.state.currentValue.name.it}
            >
               {this.state.valuesList.map(item => (
                  <Option key={item.id} value={item.id}>
                     {item.name.it}
                  </Option>
               ))}
            </Select>
            <Button type="danger" icon={<DeleteOutlined />} onClick={() => this.state.deleteSpecific(this.state.index)} />
            {/* {
               this.state.index === 'new_spec' ?
                  <Button type="success" icon={<CheckOutlined />} onClick={() => this.state.saveSpecific()} />
                  :
                  <Button type="danger" icon={<DeleteOutlined />} onClick={() => this.state.deleteSpecific()} />
            } */}
         </Space>
      )

   }
};


export default SpecificsSelect;

