import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../services/languageManager'
import * as storeActions from '../store/actions/analysis'

import { Descriptions, Space, notification, Typography, Card } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

class CategoryAmazonDataBox extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        categoryAmazonData: {},
        // rangeChangeLoader: false,
        // rangeLoaders: [],
        // ordersChartData: [],
        // ordersChartDataByDayAverage: [],
        // orderValuesChartData: [],
        // bestSellingItem: {},
        // rangeSelection: [],
        // rangeSelected: false,
    }

    componentDidMount() {
        console.log(this.props)
        Promise.all([
            this.getCategoryAmazonData(this.props.categoryId),
            // this.getCountOrdersBoxData(),
            // this.prepareRangeSelection(),
        ]).then(() => {
            this.setState({
                componentLoader: false,
                // rangeChangeLoader: false,
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (
            _.isEqual(nextProps.categoryId, this.props.categoryId)
            && _.isEqual(nextState, this.state)
        ) {
            return false
        }
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        // console.log('componentDidUpdate')
        // console.log(prevProps.categoryId, this.props.categoryId)
        if (!_.isEqual(prevProps.categoryId, this.props.categoryId)) {
            // console.log('componentDidUpdate CONDITION OK')
            this.setState({ componentLoader: true });
            Promise.all([
                this.getCategoryAmazonData(this.props.categoryId),
                // this.getCountOrdersBoxData(),
                // this.prepareRangeSelection(),
            ]).then(() => {
                this.setState({
                    componentLoader: false,
                    // rangeChangeLoader: false,
                });
            });
        }
    }

    getCategoryAmazonData = (categoryId) => {
        if (!_.isNil(categoryId)) {
            console.log(categoryId)
            return storeActions.getCategoryAmazonData(categoryId)
                .then(response => {
                    console.log(response);
                    try {
                        this.setState({
                            categoryAmazonData: response.data,
                        });
                    } catch (e) {
                        notification.open({
                            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                            description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                        })
                    }
                }).catch((e) => {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                });
        } else {
            return false
        }
    }

    render() {
        const { Title } = Typography;
        const amazonData = this.state.categoryAmazonData
        return (
            <Card loading={this.state.componentLoader || this.state.dataLoader}
                title={
                    <Space direction='vertical' size='small'>
                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.analysis.categories.amazon_data.title')}</Title>
                    </Space>
                }
                bordered={true}
                className="card-analysis-data"
            >
                {
                    !_.isNil(amazonData) ? (
                        <div>
                            <Descriptions
                                bordered
                                // title={languageManager.getMessage(this.props.commonState, 'component.analysis.category_amazon_data.title')}
                                size='small'
                                column={1}
                            >
                                <Descriptions.Item label="ID Categoria">{amazonData.cat_id}</Descriptions.Item>
                                <Descriptions.Item label="Nome">{amazonData.name}</Descriptions.Item>
                                <Descriptions.Item label="Nome semplificato">{amazonData.context_free_name}</Descriptions.Item>
                                <Descriptions.Item label="Numero prodotti (stima)">{amazonData.product_count}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    ) : 'Nessun dato disponibile'
                }
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(CategoryAmazonDataBox)