import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { updateObject } from '../../../shared/utility';

import { Form, Input, InputNumber, Select, TreeSelect, Switch, Button, Space, notification } from 'antd';
import { InfoCircleOutlined, CheckOutlined, CloseOutlined, PlusSquareOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/categories';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class CategoriesEdit extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      loader: true,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   };

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         parent_id: response.parent_id,
         parent_name: !_.isNil(response.parent) ? response.parent.name[lang] : 'Categoria di primo livello',
         internal_code: response.internal_code,
         name: response.name[lang],
         intro: !_.isNil(response.intro) ? response.intro[lang] : '',
         description: !_.isNil(response.description) ? response.description[lang] : '',
         is_visible: !_.isNil(response.visible) ? response.visible[lang] : true,
         id: response.id,
         language: lang
      }
      return values;
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id;
         this.setState({ action: 'edit' });
         // asyncronous calls
         storeActions.getItem(itemId)
            .then(response => {
               console.log(response);
               try {
                  const updatedValues = this.setForm(response.data);
                  this.setState({ response: response.data, formValues: updatedValues, loader: false });

                  // const updatedItemList = updateObject(this.state.itemList, response);
                  // this.setState({itemList: updatedItemList});

                  //  const loadedData = response.data;

                  //  const newState = {...this.state};
                  //  newState.categoriestotals = loadedData;
                  //  this.setState(newState);
                  //  // this.props.showMessage({message: 'Categories totals loaded'});

               } catch (e) {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               }

            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage
         }
         // asyncronous calls
         storeActions.getTree()
            .then(response => {
               console.log(response);
               try {
                  this.setState({ categories_tree: response.data, formValues: formValues, loader: false });

               } catch (e) {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               }

            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   // onParentChange = value => {
   //    console.log(value);
   //    this.setState({ value });
   // };

   onFinish = values => {
      this.setState({ loader: true });
      if (this.state.action != 'edit') {
         values.parent_id = values.parent_name;
      }
      values.visible = true;
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }
               const updatedValues = this.setForm(response.data);
               this.setState({ response: response.data, formValues: updatedValues, loader: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   onVisibleSwitchChange = (checked) => {
      let newFormValues = { ...this.state.formValues }
      newFormValues.is_visible = checked;
      this.setState({ formValues: newFormValues });
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   render() {
      if (!this.props.authState.userData.languages) return '';
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      const tailLayout = {
         wrapperCol: { offset: 8, span: 16 },
      };
      const { TextArea } = Input;
      const { Option } = Select;


      const categories_tree = (
         <TreeSelect
            style={{ width: '100%' }}
            // value={this.state.value}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={this.state.categories_tree}
            allowClear
            placeholder="Categoria di primo livello"
         // onChange={this.onChange}
         />)

      let form = (
         <Form
            {...layout}
            id="editCategory"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Categoria padre"
               name="parent_name"
               extra="Seleziona la categoria"
               rules={[]}
            >
               {this.state.action === 'edit' ? <Input value={this.state.formValues.parent_name} disabled /> : categories_tree}
            </Form.Item>
            {/* <Form.Item
               label="Codice"
               name="internal_code"
               rules={[{ required: true, message: 'Inserisci il codice' }]}
            >
               <Input />
            </Form.Item> */}
            <Form.Item
               label="Nome"
               name="name"
               rules={[{ required: true, message: 'Inserisci il nome' }]}
            >
               <Input />
            </Form.Item>
            {/* <Form.Item
               label="Attivo"
               name="visible"
               valuePropName="checked"
            >

               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={this.state.formValues.is_visible}
                  onChange={this.onVisibleSwitchChange}
               />
            </Form.Item> */}


            {/* <Form.Item
               label="Intro"
               name="intro"
               rules={[{ message: 'Testo di introduzione' }]}
            >
               <TextArea
                  rows={2}
               />
            </Form.Item> */}

            <Form.Item
               label="Descrizione"
               name="description"
               rules={[{ message: 'Inserisci la descrizione' }]}
            >
               <TextArea
                  rows={4}
               />
            </Form.Item>

            <Form.Item label="id" name="id" noStyle><Input type="text" type="hidden" /></Form.Item>
            <Form.Item label="parent_id" name="parent_id" noStyle><Input type="text" type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="text" type="hidden" /></Form.Item>

         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-12">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              size='large'
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => { this.props.history.push('/categories/order') }}
                           >
                              Ordina categorie
                        </Button>
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="editCategory" htmlType="button">Annulla</Button>
                           <Button form="editCategory" type="primary" htmlType="submit">Salva</Button>
                           <Button form="editCategory" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);




      console.log(this.state);
      // this.props.action === 'edit' ? (
      //    initial_values = {
      //       name: this.props.itemData.t_name,
      //       percentage: this.props.itemData.percentage
      //    }) : null;

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.categories.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(CategoriesEdit);