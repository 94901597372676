import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';


import languageManager from '../../services/languageManager';

const Unauthorized = ({ commonState, code = '' }) => {

    let codeCol;
    if (!_.isEmpty(code)) {
        codeCol = <Col xs={{ span: 15, offset: 3 }} xl={{ span: 12, offset: 6 }}>
            <p>
                {code}
            </p>
        </Col>;
    }

    return (
        <Row className='error-page'>
            <Col xs={{ span: 15, offset: 3 }} xl={{ span: 12, offset: 6 }}>
                <h1>{languageManager.getMessage(commonState, 'error.unauthorized')}</h1>
            </Col>
            {codeCol}
        </Row>
    );
};

Unauthorized.propTypes = {
    code: PropTypes.string,
    commonState: PropTypes.object.isRequired
};

export default Unauthorized;