import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class authenticationApi {

    /**
     *
     * @param userData
     * @returns {Promise<unknown>}
     */
    static login(userData) {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.loginUrl, userData)
                .then(response => {
                    // console.log(response);
                    if (response.data.success === true) {
                        const access_token = response.data.data.access_token;
                        Axios.post(API_BASE.url + API_CONFIG.appUrls.getUserDataUrl, null, {
                            headers: {
                                'Authorization': 'Bearer ' + access_token
                            }
                        })
                            .then(response => {
                                response.data.data.access_token = access_token;
                                // console.log(response);
                                resolve(response.data);
                            })
                            .catch(error => {
                                reject(error);
                            });
                    }
                    else {
                        resolve(response);
                    }
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    /**
     *
     * @param
     * @returns {Promise<unknown>}
     */
    static logout() {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.logout)
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    /**
     *
     * @param
     * @returns {Promise<unknown>}
     */
    static editPassword(data) {
        return new Promise((resolve, reject) => {
            Axios.post(API_BASE.url + API_CONFIG.appUrls.users.editPassword, {
                ...data
            })
                .then(response => {
                    console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });

        });
    }
}


export default authenticationApi;