import AnalysisApi from '../../../../api/AnalysisApi';
import { prepareTree } from '../../../catalogue/categories/store/actions/categories';
import _ from 'lodash';

export function getCategoriesComplements() {
   return AnalysisApi.getCategoriesComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if (el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = (i === 'categoriesTree') ? prepareTree(el.data.data) : el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         throw error;
      });
}

export function getCategoryAmazonData(categoryId) {
   return AnalysisApi.getCategoryAmazonData(categoryId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getCategoryAmazonBestSellers(categoryId) {
   return AnalysisApi.getCategoryAmazonBestSellers(categoryId)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}