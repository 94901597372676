import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Input, Select, Button, Space, notification, List, Switch } from 'antd';
import { InfoCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/vendor_codes';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class MarketplacesEdit extends Component {

   state = {
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      formValues: {},
      language: 'it',
      componentLanguage: this.props.authState.defaultLanguage,
      activeMarketplacesList: [],
      parentCodesList: [],
   };

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         parent_id: response.parent_id,
         marketplace_id: response.marketplace_id,
         code: response.code,
         warehouse_code: response.warehouse_code,
         active: response.active,
         id: response.id,
      }
      return values;
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'edit',
               itemId: itemId,
               pageLoader: false
            });
         });
      }
      else {
         Promise.all([
            this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'insert',
               pageLoader: false
            });
         });
      }
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               this.setState({ response: response.data, formValues: updatedValues });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            try {
               this.setState({
                  activeMarketplacesList: response.activeMarketplacesList,
                  parentCodesList: response.parentCodesList,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  duration: 0,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   onFinish = values => {
      this.setState({ formLoader: true });
      values.visible = true;
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.push('/vendor_codes/list');
               }
               const updatedValues = this.setForm(response.data);
               this.setState({ response: response.data, itemId: response.data.id, action: 'edit', formValues: updatedValues, formLoader: false });

               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: response.human ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={response.human}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  duration: 0
               })
            } catch (e) {
               this.setState({ formLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <CloseCircleOutlined className="color-red-I" />,
      })
   };

   render() {
      // console.log(this.props.authState.userData.languages);
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      const { TextArea } = Input;
      const { Option } = Select;

      let form = (
         <Form
            {...layout}
            id="formVendorCode"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Marketplace"
               name="marketplace_id"
               rules={[{ required: true }]}
            >
               <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona il Marketplace"
                  optionFilterProp="children"
               >
                  {
                     this.state.activeMarketplacesList.map((marketplace) => {
                        return <Option key={marketplace.id} value={marketplace.id}>{marketplace.name}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Codice principale"
               name="parent_id"
            >
               <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona il codice principale"
                  optionFilterProp="children"
               >
                  {
                     this.state.parentCodesList.map((code) => {
                        return <Option key={code.id} value={code.id}>{code.code}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Codice"
               name="code"
               rules={[{ required: true, message: 'Inserisci il codice' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Codice magazzino"
               name="warehouse_code"
               rules={[{ required: true, message: 'Inserisci il codice del magazzino' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Attivo"
               name='active'
            >
               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={this.state.formValues.active}
               />
            </Form.Item>

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header"></div>
            <div className="card-body">
               {this.state.pageLoader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           onClick={() => { this.props.history.goBack() }}
                        >
                           <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                           {languageManager.getMessage(this.props.commonState, 'common.back')}
                        </Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="formVendorCode" htmlType="button" onClick={this.onReset} disabled={this.state.formLoader}>Annulla</Button>
                           <Button form="formVendorCode" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                           <Button form="formVendorCode" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.formLoader}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.vendor_codes.title')}
               buttons={[]}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

export default connect(mapStateToProps)(MarketplacesEdit);