import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Moment from 'moment'
import _ from 'lodash'

import { Table, Collapse, Select, Button, Space, notification, Descriptions, Row, Col, Typography } from 'antd'
import { InfoCircleOutlined, SearchOutlined, UploadOutlined, DoubleLeftOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/purchase_orders'

import OrdersStatusLabel from '../../common/components/OrdersStatusLabel'
import DropdownMenu from '../../common/components/DropdownMenu'
import TableFilters from '../../common/components/TableFilters'

import Loader from '../../common/PageElements/Loader'

class PurchaseOrderDetails extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      tableLoader: false,
      orderDetails: {
         amazon_warehouse: {}
      },
      orderLines: [],
      componentLanguage: this.props.authState.defaultLanguage,
      orderStatusList: [],
      orderStatusLoader: false,

      itemsPaginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },

      filters: [],
      filtersValues: {},
      appliedFilters: [],
   };

   componentDidMount() {
      const itemId = this.props.match.params.id
      Promise.all([
         this.getItem(itemId, this.state.itemsPaginationParams, this.state.filtersValues),
         this.getComplements()
      ]).then(() => {
         this.setState({
            action: 'edit',
            itemId: itemId,
            pageLoader: false
         });
      });
   }

   getItem = (id, paginationParams, filters) => {
      this.setState({ tableLoader: true })
      return storeActions.getItem(id, paginationParams, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }
               this.setState({
                  orderLines: response.data.order_lines,
                  orderDetails: response.data,
                  fullListIds: response.full_list_ids,
                  itemsPaginationParams: {
                     current_page: response.data.order_lines.current_page,
                     per_page: response.data.order_lines.per_page,
                     total: response.data.order_lines.total
                  },
   
                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,
                  tableLoader:false,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getOrderComplements()
         .then(response => {
            console.log(response);
            try {
               let list = []
               _.forEach(response.statusesList, function (value) {
                  list.push({ id: value.id, name: value.t_name })
               })
               this.setState({
                  complementsResponse: response,
                  orderStatusList: list,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   submitFilters = (values) => {
      // console.log("Selected filters", values)
      Object.keys(values).forEach(function (key) {
         if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values })
      this.getItem(this.state.itemId, this.state.itemsPaginationParams, values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {} })
      this.getItem(this.state.itemId, this.state.itemsPaginationParams, {});
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      // console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
      }
      this.getItem(this.state.itemId, paginationParams, this.state.filtersValues);
   };

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title } = Typography
      const { Option } = Select
      const { Panel } = Collapse
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.itemsPaginationParams.per_page,
            total: this.state.itemsPaginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' prodotti' : ' prodotto')
         },
         onChange: this.handleTableChange
      }
      const order_lines_table_columns = [
         {
            title: 'ASIN',
            width: 120,
            key: 'buyer_id',
            fixed: 'left',
            render: (record) => (
               record.buyer_id
            ),
            sorter: (a, b) => a.buyer_id.localeCompare(b.buyer_id)
         },
         {
            title: 'ID esterno',
            width: 120,
            key: 'vendor_id',
            fixed: 'left',
            render: (record) => (
               record.vendor_id
            ),
            sorter: (a, b) => a.vendor_id.localeCompare(b.vendor_id),
         },
         // {
         //    title: 'Modello',
         //    width:150,
         //    key: 'item_code',
         //    fixed: 'left',
         //    render: (record) => (
         //       record.item_code
         //    ),
         //    sorter: (a, b) => a.item_code.localeCompare(b.item_code),
         //    ellipsis: true,
         // },
         {
            title: 'Titolo',
            key: 'title',
            render: (record) => (
               record.product_title
            ),
            sorter: (a, b) => a.product_title.localeCompare(b.product_title),
            ellipsis: true,
         },
         {
            title: 'Brand',
            key: 'brand',
            width: 200,
            render: (record) => (
               record.brand_name
            ),
            sorter: (a, b) => a.brand_name.localeCompare(b.brand_name),
         },
         // {
         //    title: 'Tipo di finestra',
         //    width:150,
         //    key: 'shipment_type',
         //    fixed: 'left',
         //    render: (record) => (
         //       record.shipment_type
         //    ),
         //    sorter: (a, b) => a.shipment_type.localeCompare(b.shipment_type),
         //    ellipsis: true,
         // },
         {
            title: 'Data prevista',
            key: 'required_shipment_date',
            width: 100,
            render: (record) => (
               record.required_shipment_date && Moment(record.required_shipment_date).year() !== '1970' ? Moment(record.required_shipment_date).format('L') : ''
            ),
            sorter: (a, b) => a.required_shipment_date.localeCompare(b.required_shipment_date),
         },
         {
            title: 'Quantità richiesta',
            key: 'quantity_requested',
            width: 75,
            render: (record) => (
               record.quantity_requested
            ),
            sorter: (a, b) => a.quantity_requested - b.quantity_requested,
         },
         {
            title: 'Quantità accettata',
            key: 'quantity_accepted',
            width: 75,
            render: (record) => (
               record.quantity_accepted
            ),
            sorter: (a, b) => a.quantity_accepted - b.quantity_accepted,
         },
         {
            title: 'Quantità ricevuta',
            key: 'quantity_received',
            width: 75,
            render: (record) => (
               record.quantity_received
            ),
            sorter: (a, b) => a.quantity_received - b.quantity_received,
         },
         {
            title: 'Quantità rimanente',
            key: 'quantity_remaining',
            width: 75,
            render: (record) => (
               record.quantity_remaining
            ),
            sorter: (a, b) => a.quantity_remaining - b.quantity_remaining,
         },
         {
            title: 'Costo unitario',
            key: 'price_unit',
            width: 75,
            render: (record) => (
               record.price_unit + ' €'
            ),
            sorter: (a, b) => a.price_unit - b.price_unit,
         },
         // {
         //    title: 'Costo totale',
         //    key: 'price_total',
         //    fixed: 'left',
         //    render: (record) => (
         //       record.price_total + ' €'
         //    ),
         //    sorter: (a, b) => a.price_total-b.price_total,
         // },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  {/* <Popconfirm
                     placement="topRight"
                     title="Cancelli?"
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button className="link"><i className="far fa-trash-alt"></i></Button>
                  </Popconfirm> */}
               </Space>),
         },
      ];
      const order_lines_table = <Table {...tableLayout} columns={order_lines_table_columns} dataSource={this.state.orderLines.data} />;

      const order_data = (
         <Descriptions title={"Ordine d'acquisto: " + this.state.orderDetails.purchase_order_number} size='small' column={1} bordered>
            <Descriptions.Item label="Stato ordine">{this.state.orderDetails.order_status_name}</Descriptions.Item>
            <Descriptions.Item label="Fornitore">{this.state.orderDetails.vendor}</Descriptions.Item>
            <Descriptions.Item label="Spedire a">{this.state.orderDetails.amazon_warehouse ? this.state.orderDetails.amazon_warehouse.fc_code + ' - ' + this.state.orderDetails.amazon_warehouse.address : 'Sconosciuto'}</Descriptions.Item>
            <Descriptions.Item label="Ordinato il">{Moment(this.state.orderDetails.order_date).format('L')}</Descriptions.Item>
            <Descriptions.Item label="Finestra di spedizione">{Moment(this.state.orderDetails.shipment_window_start).format('L') + " - " + Moment(this.state.orderDetails.shipment_window_end).format('L')}</Descriptions.Item>
         </Descriptions>
      );

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      const order_wrapper = (
         <div>
            {order_data}

            {pageFilters}
            <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
               <div className="col-sm-6"></div>
               <div className="col-sm-6">
                  <div className="float-right d-none d-md-block">
                     <Space>
                        <OrdersStatusLabel
                           value={this.state.orderDetails.po_status_id}
                           title={this.state.orderDetails.order_status_name}
                        />
                        <DropdownMenu
                           type='primary'
                           title={languageManager.getMessage(this.props.commonState, 'common.components.order_status_button.title')}
                           action={this.orderStatusHandler}
                           values={this.state.orderStatusList}
                           loading={this.state.orderStatusLoader}
                        />
                     </Space>
                  </div>
               </div>
            </div>
            {order_lines_table}
         </div>
      )

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.purchase_orders.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Link to={{ pathname: "/purchase_order_lines/import/" + this.props.match.params.id }} >
                           <Button
                              type='primary'
                              icon={<UploadOutlined />}
                           >Importa linee ordine da CSV</Button>
                        </Link>
                        <Select
                           showSearch
                           className="width-70px-I"
                           placeholder="Seleziona la lingua"
                           defaultValue={this.state.componentLanguage}
                           optionFilterProp="children"
                           onChange={this.setDefaultLanguageHandler}
                        >
                           {
                              this.props.authState.userData.languages.map((lang) => {
                                 return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                              })
                           }
                        </Select>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               {this.state.pageLoader ? <Loader /> : order_wrapper}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/purchase_orders/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                  </Col>
               </Row>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }
      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(PurchaseOrderDetails);