const API_CONFIG = {   
   appUrls: {
      loginUrl: 'auth/login',
      getUserDataUrl: 'auth/user',
      logout: 'auth/logout',

      users: {
         editPassword: 'auth/edit_password'
      },

      files: {
         fileImport: 'files/import'
      },

      edi: {
         readFile: 'edi/read_edi_file'
      },

      dashboard: {
         getOrderData: 'dashboard/get_order_data',
         get_order_totals_details:'dashboard/get_order_totals_details',
         get_orders_chart_data:'dashboard/get_orders_chart_data',
         get_orders_stats_data:'dashboard/get_orders_stats_data',
         get_products_stats_data:'dashboard/get_products_stats_data',
      },

      categories: {
         getList: 'categories/list',
         getItem: 'categories/getCategory',
         createOrUpdate: 'categories/save',
         deleteItem: 'categories/delete',
         toggleValue: 'categories/toggle_value',
         getTree: 'categories/getTree',
         rebuildTree: 'categories/rebuildTree',

         getAmazonData: 'categories/get_amazon_data',
         getAmazonBestSellers: 'categories/get_amazon_best_sellers',
      },

      brands: {
         getList: 'brands/list',
         getItem: 'brands/get',
         createOrUpdate: 'brands/save',
         deleteItem: 'brands/delete',
         toggleValue: 'brands/toggle_value'
      },

      seasons: {
         getList: 'seasons/list',
         getItem: 'seasons/get',
         createOrUpdate: 'seasons/save',
         deleteItem: 'seasons/delete'
      },

      catalogues: {
         getList: 'catalogues/list',
         getItem: 'catalogues/get',
         createOrUpdate: 'catalogues/save',
         deleteItem: 'catalogues/delete',
         toggleValue: 'catalogues/toggle_value'
      },

      products: {
         getList: 'products/list',
         getListExtended: 'products/list_extended',
         getItem: 'products/get',
         createOrUpdate: 'products/save',
         deleteItem: 'products/delete',
         toggleValue: 'products/toggle_value',

         importCSVProducts: 'products/import_products_from_csv',
         exportCSV: 'products/export_csv',

         getAmazonAnalysis: 'products/get_amazon_analysis',

         setProductAvailable: 'products/set_product_available',
      },

      searchProducts: {
         searchProductInCatalogue: 'search_products/search_product_in_catalogue',
         searchProductInCatalogueByEan: 'search_products/search_product_in_catalogue_by_ean',
         searchProductInCatalogueByMpn: 'search_products/search_product_in_catalogue_by_mpn',
         searchProductInCatalogueByTitle: 'search_products/search_product_in_catalogue_by_title',
         lookupAmazonDataByMpnOrAsinOrEanOrTitle: 'search_products/lookup_amazon_data_by_mpn_asin_ean_title',
         lookupAmazonDataByMpnOrEanOrTitle: 'search_products/lookup_amazon_data_by_mpn_ean_title',
         getLookupResults: 'search_products/get_lookup_results',
      },

      productsAmazonData: {
         getItem: 'products_amazon_data/get',
      },

      purchase_orders: {
         getList: 'purchase_orders/list',
         getListExtended: 'purchase_orders/list_extended',
         getYearList: 'purchase_orders/list_years',
         getItem: 'purchase_orders/get',
         deleteItem: 'purchase_orders/delete',
         toggleValue: 'purchase_orders/toggle_value',
         importFromCsv: 'purchase_orders/import',
         importEdiFromAmazon: 'purchase_orders/import_edi_from_amazon',

         getExpiringList: 'purchase_orders/list_expiring',
         getExpiringItem: 'purchase_orders/get_expiring',

         exportList: 'purchase_orders/export_order_list',
         exportOrdersForRIA: 'purchase_orders/export_for_ria',
         exportForPOPivot: 'purchase_orders/export_for_po_pivot',
         exportForRIAWeeklyPivot: 'purchase_orders/export_for_ria_weekly_pivot',
         
         updateOrderStatus: 'purchase_orders/update_order_status',
      },

      purchase_order_lines: {
         getList: 'purchase_order_lines/list',
         getItem: 'purchase_order_lines/get',
         createOrUpdate: 'purchase_order_lines/save',
         deleteItem: 'purchase_order_lines/delete',
         toggleValue: 'purchase_order_lines/toggle_value',
         importFromCsv: 'purchase_order_lines/import',
      },

      order_statuses: {
         getList: 'order_statuses/list',
         getItem: 'order_statuses/get',
      },

      closing_orders: {
         getListExtended: 'closing_purchase_orders/list_extended',
         getItem: 'closing_purchase_orders/get',
         updateOrderStatus: 'closing_purchase_orders/update_status',
         deleteItem: 'closing_purchase_orders/delete',
         deleteItems: 'closing_purchase_orders/delete_items',
         exportOrdersToCsv: 'closing_purchase_orders/export_orders_to_csv',
         exportLinesToCsv: 'closing_purchase_orders/export_lines_to_csv',
         sendUpdates: 'closing_purchase_orders/send_updates',
         sendMultipleUpdates: 'closing_purchase_orders/send_multiple_updates',
      },

      closing_order_statuses: {
         getList: 'closing_order_statuses/list',
         getItem: 'closing_order_statuses/get',
      },

      vendor_orders: {
         createOrUpdate: 'vendor_orders/save',
         getList: 'vendor_orders/list',
         getListExtended: 'vendor_orders/list_extended',
         getYearList: 'vendor_orders/list_years',
         getItem: 'vendor_orders/get',
         deleteItem: 'vendor_orders/delete',
         importFromCsv: 'vendor_orders/import',
      },

      vendor_order_lines: {
         getList: 'vendor_order_lines/list',
         getItem: 'vendor_order_lines/get',
         createOrUpdate: 'vendor_order_lines/save',
         deleteItem: 'vendor_order_lines/delete',
         importFromCsv: 'vendor_order_lines/import',
      },

      specifics: {
         getList: 'specifics/list',
         getItem: 'specifics/get',
         createOrUpdate: 'specifics/save',
         deleteItem: 'specifics/delete',
         toggleValue: 'specifics/toggle_value'
      },

      specificValues: {
         getList: 'specific_values/list',
         getListByParent: 'specific_values/list_by_parent',
         getItem: 'specific_values/get',
         createOrUpdate: 'specific_values/save',
         deleteItem: 'specific_values/delete',
         toggleValue: 'specific_values/toggle_value'
      },

      variations: {
         getList: 'variations/list',
         getItem: 'variations/get',
         createOrUpdate: 'variations/save',
         deleteItem: 'variations/delete',
         toggleValue: 'variations/toggle_value'
      },

      variationValues: {
         getList: 'variation_values/list',
         getListByParent: 'variation_values/list_by_parent',
         getItem: 'variation_values/get',
         createOrUpdate: 'variation_values/save',
         deleteItem: 'variation_values/delete',
         toggleValue: 'variation_values/toggle_value'
      },

      marketplacesCountries: {
         getList: 'marketplaces_countries/list',
      },

      marketplacesLanguages: {
         getList: 'marketplaces_languages/list',
      },

      marketplacesCurrencies: {
         getList: 'marketplaces_currencies/list',
      },

      marketplaces: {
         getList: 'marketplaces/list',
         getActiveList: 'marketplaces/list_active',
         getBasesList: 'marketplaces/list_bases',
         getItem: 'marketplaces/get',
         getConfig: 'marketplaces/get_config',
         setConfig: 'marketplaces/set_config',
         connectionTest: 'marketplaces/connection_test',
         createOrUpdate: 'marketplaces/save',
         deleteItem: 'marketplaces/delete',
         toggleActive: 'marketplaces/toggle_active'
      },

      vendorCodes: {
         getList: 'vendor_codes/list',
         getListExtended: 'vendor_codes/list_extended',
         getParentList: 'vendor_codes/list_parent',
         getItem: 'vendor_codes/get',
         createOrUpdate: 'vendor_codes/save',
         toggleActive: 'vendor_codes/toggle_active',
         deleteItem: 'vendor_codes/delete',
      },

      priceRules: {
         getList: 'price_rules/list',
         getListByMarketplace: 'price_rules/list_by_marketplace',
         getListForMarketplace: 'price_rules/list_for_marketplace',
         getItem: 'price_rules/get',
         createOrUpdate: 'price_rules/save',
         deleteItem: 'price_rules/delete',
         toggleActive: 'price_rules/toggle_active'
      },

      taxRates: {
         getList: 'tax_rates/list',
         getItem: 'tax_rates/get',
         createOrUpdate: 'tax_rates/save',
         deleteItem: 'tax_rates/delete',
         toggleActive: 'tax_rates/toggle_active'
      },

      chargebacks: {
         importFile: 'chargebacks/import_file',
      },

      dataProcessing: {
         processGroupSizes: 'data_processing/process_group_sizes',
         searchProductsByAsin: 'data_processing/search_products_by_asin',
         searchProductsByCode: 'data_processing/search_products_by_code',
         searchAsinsInOrders: 'data_processing/search_asins_in_orders',
      },

      getEbayOfficialTimeUrl: 'api/connectors/ebay/getEbayOfficialTime',

      getTrafficDataUrl: 'api/connectors/ebay/getTrafficData',
      getSellingDataUrl: 'api/connectors/ebay/getSellingData',

      getTrafficSitesUrl: 'api/connectors/ebay/getTrafficSites',
      getTrafficTotalsUrl: 'api/connectors/ebay/getTrafficTotals',
      getTrafficTotalHitsUrl: 'api/connectors/ebay/getTrafficTotalHits',
      getTrafficCategoriesUrl: 'api/connectors/ebay/getTrafficCategories',
      getTrafficDatatableUrl: 'api/connectors/ebay/getTrafficDatatable',

      getCategoriesTotalsUrl: 'api/connectors/ebay/getCategoriesTotals',
      getCategoriesDatatableUrl: 'api/connectors/ebay/getCategoriesDatatable',
      getCategoriesDetailsTableUrl: 'api/connectors/ebay/getCategoriesDetailsTable',
      getSuggestedCategoryUrl: 'api/connectors/ebay/getSuggestedCategory',

      getPricingTotalsUrl: 'api/connectors/ebay/getPricingTotals',
      getPricingDatatableUrl: 'api/connectors/ebay/getPricingDatatable',
      getPricingDetailsTableUrl: 'api/connectors/ebay/getPricingDetailsTable',
      getCompetitorPricesUrl: 'api/connectors/ebay/getCompetitorPrices',

      getSellingTotalsUrl: 'api/connectors/ebay/getSellingTotals',
      getSellingDatatableUrl: 'api/connectors/ebay/getSellingDatatable',
      getSellingDetailsTableUrl: 'api/connectors/ebay/getSellingDetailsTable',
      getSellingNotSoldTableUrl: 'api/connectors/ebay/getSellingNotSoldTable',


      test: {
         importFile: 'test/import_file',
         updateOrderStatus: 'test/update_po_status',
         sendClosingPOUpdates: 'test/send_closing_po_updates',
      },
   }
};

export default API_CONFIG;
