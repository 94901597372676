import React from "react"
import { Redirect } from "react-router-dom"

// Pages Component

// Authentication related pages
import Login from "../components/authentication/PageLogin"
import Logout from "../components/authentication/Logout"
// import Register from "../pages/Authentication/Register"
// import ForgetPwd from "../pages/Authentication/FogetPassword"

import PasswordEdit from "../components/user/PasswordEdit"

import Dashboard from "../components/dashboard/Dashboard"

import BrandsList from "../components/catalogue/brands/BrandsList"
import BrandsEdit from "../components/catalogue/brands/BrandsEdit"

import SeasonsList from "../components/catalogue/seasons/SeasonsList"

import CataloguesList from "../components/catalogue/catalogues/CataloguesList"
import CataloguesEdit from "../components/catalogue/catalogues/CataloguesEdit"

import CategoriesList from "../components/catalogue/categories/CategoriesList"
import CategoriesOrder from "../components/catalogue/categories/CategoriesOrder"
import CategoriesEdit from "../components/catalogue/categories/CategoriesEdit"

import ProductsList from "../components/catalogue/products/ProductsList"
import ProductsImport from "../components/catalogue/products/ProductsImport"
import ProductsEdit from "../components/catalogue/products/ProductsEdit"

import SearchProducts from "../components/catalogue/search_products/SearchProducts"

import PurchaseOrdersList from "../components/orders/purchase_orders/PurchaseOrdersList"
import PurchaseOrdersImport from "../components/orders/purchase_orders/PurchaseOrdersImport"
import PurchaseOrdersEdit from "../components/orders/purchase_orders/PurchaseOrdersEdit"
import PurchaseOrderDetails from "../components/orders/purchase_orders/PurchaseOrderDetails"
import PurchaseOrderLinesImport from "../components/orders/purchase_orders/PurchaseOrderLinesImport"

import VendorOrdersList from "../components/orders/vendor_orders/VendorOrdersList"
import VendorOrderImport from "../components/orders/vendor_orders/VendorOrderImport"
import VendorOrdersEdit from "../components/orders/vendor_orders/VendorOrdersEdit"
import VendorOrderDetails from "../components/orders/vendor_orders/VendorOrderDetails"
import VendorOrderLinesImport from "../components/orders/vendor_orders/VendorOrderLinesImport"

import ExpiringOrdersList from "../components/orders/expiring_orders/ExpiringOrdersList"
import ExpiringOrdersEdit from "../components/orders/expiring_orders/ExpiringOrdersEdit"
import ExpiringOrderDetails from "../components/orders/expiring_orders/ExpiringOrderDetails"

import ClosingOrdersList from "../components/orders/closing_orders/ClosingOrdersList"
import ClosingOrderDetails from "../components/orders/closing_orders/ClosingOrderDetails"

import SpecificsList from "../components/catalogue/specifics/SpecificsList"
import SpecificsEdit from "../components/catalogue/specifics/SpecificsEdit"

import SpecificValuesList from "../components/catalogue/specifics/SpecificValuesList"
import SpecificValuesEdit from "../components/catalogue/specifics/SpecificValuesEdit"

import VariationsList from "../components/catalogue/variations/VariationsList"
import VariationsEdit from "../components/catalogue/variations/VariationsEdit"

import VariationValuesList from "../components/catalogue/variations/VariationValuesList"
import VariationValuesEdit from "../components/catalogue/variations/VariationValuesEdit"

import ChargebacksLink from "../components/chargebacks/ChargebacksLink"

import GroupSizes from "../components/data_processing/GroupSizes"
import SearchAsinsInCatalog from "../components/data_processing/SearchAsinsInCatalog"
import SearchCodesInCatalog from "../components/data_processing/SearchCodesInCatalog"
import SearchAsinsInOrders from "../components/data_processing/SearchAsinsInOrders"

// import Dashboard from "../pages/Dashboard"
import MarketplacesList from "../components/configuration/marketplace/MarketplacesList"
import MarketplacesEdit from "../components/configuration/marketplace/MarketplacesEdit"
import MarketplacesConfig from "../components/configuration/marketplace/MarketplacesConfig"

import VendorCodesList from "../components/configuration/vendor_codes/VendorCodesList"
import VendorCodesEdit from "../components/configuration/vendor_codes/VendorCodesEdit"

import PriceRulesList from "../components/configuration/priceRules/PriceRulesList"
import PriceRulesEdit from "../components/configuration/priceRules/PriceRulesEdit"

import TaxRatesList from "../components/configuration/taxes/TaxRatesList"
import TaxRatesEdit from "../components/configuration/taxes/TaxRatesEdit"

import AnalysisCategories from "../components/analysis/Categories"

import ReadEdiFile from "../components/edi/ReadEdiFile"

import TestImportFile from "../components/test/import/ImportFile"

const authProtectedRoutes = [
   // User
   { path: "/user/edit_password", component: PasswordEdit },

   // Dashboard
   { path: "/dashboard", component: Dashboard },

   // Brands
   { path: "/brands/list", component: BrandsList },
   { path: "/brands/edit/:id?", component: BrandsEdit },

   // Seasons
   { path: "/seasons/list", component: SeasonsList },

   // Catalogues
   { path: "/catalogues/list", component: CataloguesList },
   { path: "/catalogues/edit/:id?", component: CataloguesEdit },

   // Categories
   { path: "/categories/list/:parent?", component: CategoriesList },
   { path: "/categories/order", component: CategoriesOrder },
   { path: "/categories/edit/:id?", component: CategoriesEdit },

   // Products
   { path: "/products/list/:status?", component: ProductsList },
   { path: "/products/import/", component: ProductsImport },
   { path: "/products/edit/:id?", component: ProductsEdit },

   // Search products
   { path: "/search_products/", component: SearchProducts },

   // Purchase Orders
   { path: "/purchase_orders/list/:status?", component: PurchaseOrdersList },
   { path: "/purchase_orders/import/", component: PurchaseOrdersImport },
   { path: "/purchase_orders/edit/:id?", component: PurchaseOrdersEdit },
   { path: "/purchase_orders/details/:id", component: PurchaseOrderDetails },
   { path: "/purchase_order_lines/import/:id", component: PurchaseOrderLinesImport },

   // Expiring Orders
   { path: "/expiring_orders/list/", component: ExpiringOrdersList },
   { path: "/expiring_orders/edit/:id?", component: ExpiringOrdersEdit },
   { path: "/expiring_orders/details/:id", component: ExpiringOrderDetails },

   // Vendor Orders
   { path: "/vendor_orders/list/", component: VendorOrdersList },
   { path: "/vendor_orders/import/", component: VendorOrderImport },
   { path: "/vendor_orders/edit/:id?", component: VendorOrdersEdit },
   { path: "/vendor_orders/details/:id", component: VendorOrderDetails },
   { path: "/vendor_order_lines/import/:id", component: VendorOrderLinesImport },

   // Closing Orders
   { path: "/closing_orders/list/", component: ClosingOrdersList },
   { path: "/closing_orders/details/:id", component: ClosingOrderDetails },

   // Specifics
   { path: "/specifics/list", component: SpecificsList },
   { path: "/specifics/edit/:id?", component: SpecificsEdit },
   { path: "/specifics/values/list/:parent", component: SpecificValuesList },
   { path: "/specifics/values/edit/:parent/:id?", component: SpecificValuesEdit },

   // Variations
   { path: "/variations/list", component: VariationsList },
   { path: "/variations/edit/:id?", component: VariationsEdit },
   { path: "/variations/values/list/:parent", component: VariationValuesList },
   { path: "/variations/values/edit/:parent/:id?", component: VariationValuesEdit },

   // Chargebacks
   { path: "/chargebacks/link", component: ChargebacksLink },

   // Data processing
   { path: "/dataprocessing/group_sizes", component: GroupSizes },
   { path: "/dataprocessing/search_asins", component: SearchAsinsInCatalog },
   { path: "/dataprocessing/search_ean_upc", component: SearchCodesInCatalog },
   { path: "/dataprocessing/search_asins_in_orders", component: SearchAsinsInOrders },

   // Marketplace
   { path: "/marketplaces/list", component: MarketplacesList },
   { path: "/marketplaces/edit/:id?", component: MarketplacesEdit },
   { path: "/marketplaces/config/:id", component: MarketplacesConfig },

   // Vendor Codes
   { path: "/vendor_codes/list", component: VendorCodesList },
   { path: "/vendor_codes/edit/:id?", component: VendorCodesEdit },
   
   // Price Rules
   { path: "/price_rules/list/", component: PriceRulesList },
   { path: "/price_rules/edit/:id?", component: PriceRulesEdit },

   // Tax Rates
   { path: "/taxes/taxrates/list", component: TaxRatesList },
   { path: "/taxes/taxrates/edit/:id?", component: TaxRatesEdit },

   // Analysis
   { path: "/analysis/categories", component: AnalysisCategories },

   // Tests
   { path: "/test/import_file", component: TestImportFile },

   // EDI
   { path: "/edi/read", component: ReadEdiFile },

   { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
]

const publicRoutes = [
   { path: "/logout", component: Logout },
   { path: "/login", component: Login },
]

export { authProtectedRoutes, publicRoutes }