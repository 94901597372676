import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Modal, Button, notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/products';

import ProductReducedForm from '../components/ProductReducedForm'
import AmazonDataBox from './AmazonDataBox'

class AmazonDataModal extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        formLoader: false,
        formValues: {},
        productAmazonData: {},
        componentLanguage: this.props.authState.defaultLanguage
    }
    formRef = React.createRef();

    componentDidMount() {
        console.log('AmazonDataModal Component did mount')
        Promise.all([
            this.getProductAmazonData(this.props.productDataId),
        ]).then(() => {
            this.setState({
                componentLoader: false
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // console.log('Component did update', nextProps, this.state)
        if (_.isEqual(nextProps.visible, this.props.visible) && _.isEqual(nextState.productAmazonData, this.state.productAmazonData)) {
            return false
        }
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        // if (!_.isEqual(prevState.visible, this.props.visible)) {
        if (!_.isEqual(prevProps.productDataId, this.props.productDataId)) {
            this.setState({ componentLoader: true });
            Promise.all([
                this.getProductAmazonData(this.props.productDataId),
            ]).then(() => {
                this.setState({
                    componentLoader: false,
                });
            });
        }
        // }
    }

    getProductAmazonData = (productDataId) => {
        if (!_.isNil(productDataId)) {
            console.log(productDataId)
            return storeActions.getProductAmazonData(productDataId)
                .then(response => {
                    console.log(response);
                    try {
                        this.setState({
                            productAmazonData: response.data,
                        });
                    } catch (e) {
                        notification.open({
                            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                            description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                        })
                    }
                }).catch((e) => {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                });
        } else {
            return false
        }
    }

    createProductHandler = values => {
        values.id = null
        values.status_id = 1
        console.log(values)
        this.setState({ formLoader: true });
        storeActions.createOrUpdate(values)
            .then(response => {
                this.setState({ formLoader: false });
                try {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                        description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                } catch (e) {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                }

            }).catch((e) => {
                this.setState({ formLoader: false });
                notification.open({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
            });
    };

    onClose = () => {
        console.log('Close modal')
        this.state.onClose()
    }

    render() {
        return (
            <Modal
                visible={this.props.visible}
                title="Statistiche Amazon"
                width={1000}
                onCancel={this.onClose}
                footer={[
                    <Button key="back" onClick={this.onClose}>
                        Chiudi
                    </Button>,
                    <Button form="CreateProductReducedForm" type="primary" htmlType="submit" loading={this.state.formLoader}>
                        Aggiungi al catalogo
                    </Button>
                ]}
            >
                <ProductReducedForm
                    // productDataType={'amazon_product'}
                    submitForm={this.createProductHandler}
                    values={this.state.productAmazonData}
                />
                {/* <AmazonDataBox
                    productDataId={this.props.productDataId}
                /> */}
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(AmazonDataModal)