import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';

import languageManager from '../../../services/languageManager';
import { startLoading, endLoading } from '../../../shared/utility';
import * as storeActions from '../store/actions/dashboard';
import { OrdersStatsRangeEnum } from '../../../shared/enums'

import { Button, Space, notification, Row, Col, Divider, Typography, Card, Table } from 'antd';
import { InfoCircleOutlined, CloseCircleTwoTone, WarningTwoTone, ContactsOutlined } from '@ant-design/icons';

class OrdersStats extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        rangeChangeLoader: false,
        rangeLoaders: [],
        ordersChartData: [],
        ordersChartDataByDayAverage: [],
        orderValuesChartData: [],
        bestSellingItem: {},
        rangeSelection: [],
        rangeSelected: false,
    }

    componentDidMount() {
        Promise.all([
            this.getOrdersStatsData(),
            // this.getCountOrdersBoxData(),
            this.prepareRangeSelection(),
        ]).then(() => {
            this.setState({
                componentLoader: false,
                rangeChangeLoader: false,
            });
        });
    }

    getOrdersStatsData = (range = null) => {
        this.setState({ rangeLoaders: startLoading(this.state.rangeLoaders, range) });
        // asyncronous calls
        return storeActions.getOrdersStatsData(range)
            .then(response => {
                console.log(response);
                try {
                    // var newOrdersChartData = []
                    // var data = {}
                    // data.name = languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_chart.tooltip_title')
                    // data.data = response.data.orders_per_month
                    // newOrdersChartData.push(data)

                    // var newOrderValuesChartData = []
                    // data = {}
                    // data.name = languageManager.getMessage(this.props.commonState, 'component.dashboard.order_values_chart.tooltip_title')
                    // data.data = response.data.orders_value_per_month
                    // newOrderValuesChartData.push(data)

                    // var newOrdersChartDataByDayAverage = []
                    // var data = {}
                    // data.name = languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_by_day_chart.tooltip_title')
                    // data.data = response.data.orders_per_month_by_day_average
                    // newOrdersChartDataByDayAverage.push(data)

                    this.setState({
                        rangeLoaders: endLoading(this.state.rangeLoaders, range),
                        totalOrders: response.data.total_orders,
                        totalValue: response.data.total_value,
                        acceptedOrders: response.data.accepted_orders,
                        acceptedValue: response.data.accepted_value,
                        acceptedProducts: response.data.accepted_products,
                        refusedOrders: response.data.refused_orders,
                        refusedValue: response.data.refused_value,
                        waitingOrders: response.data.waiting_orders,
                        waitingValue: response.data.waiting_value,
                        ordersByVendorCode: response.data.orders_by_vendor_code,
                        ordersByMarketplace: response.data.orders_by_marketplace,
                    });
                } catch (e) {
                    this.setState({ rangeLoaders: endLoading(this.state.rangeLoaders, range) });
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                }

            }).catch((e) => {
                this.setState({ rangeLoaders: endLoading(this.state.rangeLoaders, range) });
                notification.open({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
                console.log(e);
            });
    }

    // getCountOrdersBoxData = () => {
    //     // asyncronous calls
    //     storeActions.getCountOrdersBoxData()
    //         .then(response => {
    //             console.log(response);
    //             try {
    //                 var newCountOrdersBoxData = []
    //                 var data = {}
    //                 data.name = languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_chart.title')
    //                 data.data = response.data.orders_per_month
    //                 newOrdersChartData.push(data)
    //                 // var newOrdersValueChartData = {}
    //                 // newOrdersValueChartData.name = languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_chart.title')
    //                 // newOrdersValueChartData.data = response.data.orders_value_per_month
    //                 this.setState({
    //                     countOrdersBoxData: newCountOrdersBoxData,
    //                     // ordersValueChartData: newOrdersValueChartData,
    //                 });
    //             } catch (e) {
    //                 notification.open({
    //                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
    //                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
    //                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
    //                 })
    //             }

    //         }).catch((e) => {
    //             notification.open({
    //                 message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
    //                 description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
    //                 icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
    //             })
    //             console.log(e);
    //         });
    // }

    prepareRangeSelection = () => {
        var ranges = []
        ranges.push({ value: OrdersStatsRangeEnum.DAILY, title: languageManager.getMessage(this.props.commonState, 'common.ranges.daily') })
        ranges.push({ value: OrdersStatsRangeEnum.WEEKLY, title: languageManager.getMessage(this.props.commonState, 'common.ranges.weekly') })
        ranges.push({ value: OrdersStatsRangeEnum.MONTHLY, title: languageManager.getMessage(this.props.commonState, 'common.ranges.monthly') })
        this.setState({ rangeSelection: ranges, rangeSelected: OrdersStatsRangeEnum.DAILY })
        return true
    }

    rangeSelectedChangeHandler = (range) => {
        this.setState({ rangeChangeLoader: true })
        Promise.all([
            this.getOrdersStatsData(range),
        ]).then(() => {
            console.log('Range change end')
            this.setState({
                rangeChangeLoader: false,
                rangeSelected: range
            });
        });
    }

    render() {
        const { Title } = Typography;
        const ordersByMarketplaceColumns = [
            {
                title: 'Marketplace',
                key: 'marketplace',
                render: (record) => (
                    record.marketplace.name
                ),
            },
            {
                title: 'Ordini',
                dataIndex: 'total_orders',
                key: 'total_orders',
                sorter: (a, b) => a.total_orders - b.total_orders,
            },
            {
                title: 'Valore',
                key: 'total_value',
                render: (record) => (
                    record.total_value + ' €'
                ),
                sorter: (a, b) => a.total_value - b.total_value,
            },
        ];
        const ordersByVendorCodeColumns = [
            {
                title: 'Vendor Code',
                key: 'vendor_code',
                render: (record) => (
                    record.vendor_code.code
                ),
            },
            {
                title: 'Ordini',
                dataIndex: 'total_orders',
                key: 'total_orders',
                sorter: (a, b) => a.total_orders - b.total_orders,
            },
            {
                title: 'Valore',
                key: 'total_value',
                render: (record) => (
                    record.total_value + ' €'
                ),
                sorter: (a, b) => a.total_value - b.total_value,
            },
        ];

        return (
            <section>
                <Row gutter={[8, 24]}>
                    <Col span={24}>
                        <Card loading={this.state.componentLoader || this.state.rangeChangeLoader}
                            title={
                                <Space direction='vertical' size='small'>
                                    <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_stats.title')}</Title>
                                </Space>
                            }
                            extra={
                                <Space size='small'>
                                    {
                                        this.state.rangeSelection.map((range) => {
                                            return (
                                                <Button
                                                    key={range.value}
                                                    type={range.value === this.state.rangeSelected ? 'primary' : 'default'}
                                                    className={range.value === this.state.rangeSelected ? 'cursor-default-I' : ''}
                                                    onClick={range.value === this.state.rangeSelected ? false : () => this.rangeSelectedChangeHandler(range.value)}
                                                    disabled={this.state.rangeChangeLoader && !this.state.rangeLoaders[range.value]}
                                                    loading={this.state.rangeLoaders[range.value]}
                                                >
                                                    {range.title}
                                                </Button>
                                            )
                                        })
                                    }
                                </Space>
                            }
                            bordered={false}
                            className="card-dashboard-stats"
                        >
                            <Row gutter={[24, 24]}>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.total_orders.box_title')}</Title>
                                        <Title level={1}>{this.state.totalOrders}</Title>
                                    </Card >
                                </Col>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.accepted_orders.box_title')}</Title>
                                        <Title level={1}>{this.state.acceptedOrders}</Title>
                                    </Card >
                                </Col>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.refused_orders.box_title')}</Title>
                                        <Title level={1}>{this.state.refusedOrders}</Title>
                                    </Card >
                                </Col>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.waiting_orders.box_title')}</Title>
                                        <Title level={1} type="danger">{this.state.waitingOrders}</Title>
                                    </Card >
                                </Col>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.total_value.box_title')}</Title>
                                        <Title level={1}>{this.state.totalValue} €</Title>
                                    </Card >
                                </Col>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.accepted_value.box_title')}</Title>
                                        <Title level={1}>{this.state.acceptedValue} €</Title>
                                    </Card >
                                </Col>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.refused_value.box_title')}</Title>
                                        <Title level={1}>{this.state.refusedValue} €</Title>
                                    </Card >
                                </Col>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.waiting_value.box_title')}</Title>
                                        <Title level={1} type="danger">{this.state.waitingValue} €</Title>
                                    </Card >
                                </Col>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_by_marketplace.box_title')}</Title>
                                        <Table
                                            key='orders_by_marketplace'
                                            dataSource={this.state.ordersByMarketplace}
                                            columns={ordersByMarketplaceColumns}
                                            size='small'
                                            pagination={false}
                                            className="table-in-card"
                                        />
                                    </Card >
                                </Col>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_by_vendor_code.box_title')}</Title>
                                        <Table
                                            key='orders_by_vendor_code'
                                            dataSource={this.state.ordersByVendorCode}
                                            columns={ordersByVendorCodeColumns}
                                            size='small'
                                            pagination={false}
                                            className="table-in-card"
                                        />
                                    </Card >
                                </Col>
                                <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.accepted_products.box_title')}</Title>
                                        <Title level={1}>{this.state.acceptedProducts}</Title>
                                    </Card >
                                </Col>
                                {/* <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>Prodotto più venduto</Title>
                                        <Title level={1}>{this.state.bestSellingItem.name}</Title>
                                        <Title level={4}>{this.state.bestSellingItem.cnt ? this.state.bestSellingItem.cnt + ' ordini' : ''}</Title>
                                    </Card >
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(OrdersStats)