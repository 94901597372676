import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class AnalysisApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getCategoriesComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.categories.getTree),
         ])
            .then(Axios.spread((categoriesTree) => {
               const response = {
                  categoriesTree: categoriesTree,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static getCategoryAmazonData(categoryId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.categories.getAmazonData, {
            category_id: categoryId,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getCategoryAmazonBestSellers(categoryId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.categories.getAmazonBestSellers, {
            category_id: categoryId,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default AnalysisApi;