import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Table, Select, Button, Space, notification, Popconfirm } from 'antd';
import { InfoCircleOutlined, PlusSquareOutlined, LeftCircleOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/taxes';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class TaxRatesList extends Component {
   state = {
      loader: true,
      itemList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage
   };

   componentDidMount() {
      console.log(this.state);

      // asyncronous calls
      storeActions.getList()
         .then(response => {
            console.log(response);
            try {

               this.setState({ itemList: response.data, loader: false });

               // const updatedItemList = updateObject(this.state.itemList, response);
               // this.setState({itemList: updatedItemList});

               //  const loadedData = response.data;

               //  const newState = {...this.state};
               //  newState.categoriestotals = loadedData;
               //  this.setState(newState);
               //  // this.props.showMessage({message: 'Categories totals loaded'});

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   editItemHandler = (itemId) => {
      this.props.history.push('/taxes/taxrates/edit/' + itemId);
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               // let list = [...this.state.itemList];
               // list = list.filter(item => item.id !== itemId);

               this.setState({ itemList: response.data, loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            break;
      }
   }

   // createItemHandler = () => {
   //    this.props.history.push('/taxes/taxrates/edit/');
   // }

   toggleActiveHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.toggleActive(itemId)
         .then(response => {
            this.setState({ loader: false });
            console.log(response);
            try {
               // this.setState({ itemData: response.data });
               console.log(this.state);
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   }

   // closeActionHandler = () => {
   //    alert("ciao");
   // }

   render() {
      if (!this.props.authState.userData.languages) return '';
      const { Option } = Select;
      const table_columns = [
         { title: 'Nome', width: 100, dataIndex: 't_name', key: 'name', fixed: 'left' },
         { title: 'Percentuale', width: 100, dataIndex: 'percentage', key: 'percentage', fixed: 'left' },
         {
            title: 'Attiva', width: 100, dataIndex: 'active', key: 'percentage', fixed: 'center',
            render: (text, record) => (
               <span>
                  <Button className="link" onClick={() => this.toggleActiveHandler(record.id)}>{record.active ? <CheckOutlined twoToneColor="green" /> : <CloseOutlined twoToneColor="#red" />}</Button>
               </span>),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button className="link" onClick={() => this.editItemHandler(record.id)} title={text}><i className="far fa-edit"></i></Button>
                  <Popconfirm
                     placement="topRight"
                     title="Cancelli?"
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button><i className="far fa-trash-alt"></i></Button>
                  </Popconfirm>
               </Space>),
         },
      ];

      const tableLayout = {
         bordered: true
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.taxRates.title')}
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              size='large'
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => this.props.history.push('/taxes/taxrates/edit/')}
                           >Crea tassa</Button>
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} scroll={{ x: 1000 }} />}
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  icon={<LeftCircleOutlined />}
                  onClick={() => { this.props.history.goBack() }}
               >Indietro</Button>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }
      const buttons = [];
      buttons.push(helpButton);

      // const editForm = (
      //    <div className="card">
      //       <div className="card-header">Tasse</div>
      //       <div className="card-body">
      //          <TaxRatesForm action={this.state.action} itemData={this.state.itemData} closeAction={() => this.closeActionHandler()} />
      //       </div>
      //    </div>
      // );

      return (
         <div className="container-fluid">
            <PageHeader
               title='Tasse'
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(TaxRatesList);