import * as constants from '../../constants';
import { updateObject } from '../../shared/utility';
import initialState from './initialState';

/**
 * Changes the body attribute
 */
function changeBodyAttribute(attribute, value) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

function changeSidebarType(state, action) {
  const leftSideBarType = action.payload.sidebarType;
  const isMobile = action.payload.isMobile;
  switch (leftSideBarType) {
    case "compact":
      changeBodyAttribute("data-sidebar-size", "small");
      manageBodyClass("sidebar-enable", "remove");
      manageBodyClass("vertical-collpsed", "remove");
      break;
    case "icon":
      changeBodyAttribute("data-keep-enlarged", "true");
      manageBodyClass("vertical-collpsed", "add");
      break;
    case "condensed":
      manageBodyClass("sidebar-enable", "add");
      if (!isMobile) manageBodyClass("vertical-collpsed", "add");
      break;
    default:
      changeBodyAttribute("data-sidebar-size", "");
      manageBodyClass("sidebar-enable", "remove");
      if (!isMobile) manageBodyClass("vertical-collpsed", "remove");
      break;
  }
  
  return updateObject( state, {leftSideBarType: leftSideBarType} );
}

const layoutReducer = (state = initialState.layoutState, action) => {
  switch (action.type) {
    case constants.LAYOUT.CHANGE_SIDEBAR_TYPE: return changeSidebarType( state, action );
    // case actionTypes.ADD_INGREDIENT: return addIngredient( state, action );
    default:
      return state;
  }
};

export default layoutReducer;