import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'antd';

import '../../styles/object/splashScreen.css';


class SplashScreen extends React.Component {
    render() {
        if (this.props.commonState.cfgLoading > 0) {
            return <div className='splash-screen'>
                <Row type='flex' justify='center' align='middle' className='splash-screen-icon'>
                    <Col>

                    </Col>
                </Row>
                <Row type='flex' justify='center' align='middle' className='splash-screen-message'>
                    <Col>
                        <span>Loading ...</span>
                    </Col>
                </Row>
            </div >;
        }
        else
            return this.props.children;
    }
}

SplashScreen.propTypes = {
    commonState: PropTypes.object.isRequired
};


export default SplashScreen;
