import VendorOrdersApi from '../../../../../api/VendorOrdersApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList(year = null) {
   return VendorOrdersApi.getList(year)
   .then(response => {
      // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getListExtended(year = null, params = []) {
   return VendorOrdersApi.getListExtended(year, params)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getVendorOrdersComplements() {
   return VendorOrdersApi.getVendorOrdersComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if(el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId) {
   return VendorOrdersApi.getItem(itemId)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function createOrUpdate(itemData) {
   console.log(itemData);
   return VendorOrdersApi.createOrUpdate(itemData)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function toggleValue(itemId, value, language = 'it') {
   return VendorOrdersApi.toggleValue({id: itemId, value: value, language: language})
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false) {
   return VendorOrdersApi.deleteItem(itemId, return_list)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function importVO(formData) {
   console.log(formData);
   return VendorOrdersApi.importVO(formData)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error();
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function importVOLines(formData) {
   console.log(formData);
   return VendorOrdersApi.importVOLines(formData)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error();
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}