import React from 'react';
import { render } from 'react-dom';
import 'babel-polyfill';
import 'url-search-params-polyfill';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import initialState from './store/reducers/initialState';
import ScrollToTop from './components/hoc/ScrollToTop';

const store = configureStore(initialState);

render(
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop>
                <Route path="/" component={App} />
            </ScrollToTop>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
