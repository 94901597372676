import React, { Component } from 'react'
import _ from 'lodash'
import { Tree } from 'antd'

class CategoryTree extends Component {
   state = {
      ...this.props,
   }

   shouldComponentUpdate = (nextProps, nextState) => {
      if (
         _.isEqual(nextProps.categoriesTree, this.props.categoriesTree)
         && _.isEqual(nextState, this.state)
      ) {
         return false
      }
      return true
   }

   // componentDidUpdate = (prevProps, prevState) => {
   //    if (!_.isEqual(prevState.categoriesTree, this.props.categoriesTree)) {
   //       this.setState({ ...this.props })
   //       console.log('set component state')
   //    }
   //    console.log(this.state.initialValue)
   // }

   onSelect = value => {
      console.log('onSelect ', value);
      this.setState({ initialValue: value })
      this.state.onSelect(value)
   };

   render() {
      console.log('Select category', this.state)
      const tProps = {
         treeData: this.state.categoriesTree,
         defaultExpandAll: true,
         selectedKeys: this.state.initialValue,
         onSelect: this.onSelect,
         style: {
            width: '100%',
         },
      }
      return (
         <Tree {...tProps} />
      );
   }
}

export default CategoryTree;