import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Table, Button, Space, notification, Popconfirm, Collapse } from 'antd';
import { InfoCircleOutlined, PlusSquareOutlined, EditTwoTone } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { CheckOutlined, CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { sortableContainer, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/priceRules';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class PriceRulesList extends Component {
   state = {
      loader: true,
      itemList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage,
      priceRulesList: [],
      marketplacesList: [],
   };

   componentDidMount() {
      console.log(this.state);

      // asyncronous calls
      storeActions.getInitList()
         .then(response => {
            console.log(response);
            try {
               // _.forEach(response.marketplacesList, (el, i) => {
               //    if (_.isNil(response.priceRulesList[el.id])) {
               //       response.priceRulesList[el.id] = [];
               //    }
               // });
               this.setState({
                  response: response,
                  priceRulesList: response.priceRulesList,
                  marketplacesList: response.marketplacesList,
                  loader: false
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.deleteItem(itemId, true, 'list_by_marketplace')
         .then(response => {
            console.log(response);
            try {
               // let list = [...this.state.itemList];
               // list = list.filter(item => item.id !== itemId);

               this.setState({ priceRulesList: response.data, loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            return;
      }
   }

   // createItemHandler = () => {
   //    this.props.history.push('/taxes/taxrates/edit/');
   // }

   toggleActiveHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.toggleActive(itemId)
         .then(response => {
            this.setState({ loader: false });
            console.log(response);
            try {
               // this.setState({ itemData: response.data });
               console.log(this.state);
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   }

   onSortEnd = ({ oldIndex, newIndex }) => {
      console.log(oldIndex, newIndex);
      const { dataSource } = this.state.priceRulesList;
      if (oldIndex !== newIndex) {
         const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
         console.log('Sorted items: ', newData);
         this.setState({ dataSource: newData });
      }
   };

   panelsCallback = (key) => {
      console.log(key);
   }

   render() {
      const { Panel } = Collapse;

      const DragHandle = sortableHandle(() => (
         <MenuOutlined style={{ cursor: 'pointer', color: '#999' }} />
      ));

      // const SortableItem = sortableElement(props => <tr {...props} />);
      const SortableContainer = sortableContainer(props => <tbody {...props} />);
      // const DragableBodyRow = ({ index, className, style, ...restProps }) => (
      //    <SortableItem index={restProps['data-row-key', 'data-row-marketplaceid']} {...restProps} />
      // );

      const DraggableContainer = props => (
         <SortableContainer
            useDragHandle
            helperClass="row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
         />
      );

      const table_columns = [
         {
            title: 'Ordina', dataIndex: 'order_column', width: 30, className: 'drag-visible', fixed: 'center',
            render: (text, record) => <DragHandle marketplace_id={record.marketplace_id} />,
         },
         { title: 'Regola', width: 100, dataIndex: 'name', key: 'name', className: 'drag-visible', fixed: 'left' },
         {
            title: 'Variazioni', width: 100, dataIndex: 'variations', key: 'variations', className: 'drag-visible', fixed: 'left',
            render: (text, record) => (
               <div>
                  { record.variation_percentage ? <span>{record.variation_percentage}%</span> : '' }
                  <br />
                  { record.variation_amount ? <span>{record.variation_amount}€</span> : '' }
               </div>
            )
         },
         {
            title: 'Categoria', width: 100, key: 'category', className: 'drag-visible', fixed: 'left',
            render: (text, record) => (
               !_.isNil(record.category) ? record.category.name[this.state.componentLanguage] : ''
            )
         },
         {
            title: 'Brand', width: 100, key: 'brand', className: 'drag-visible', fixed: 'left',
            render: (text, record) => (
               !_.isNil(record.brand) ? record.brand.name : ''
            )
         },
         {
            title: 'Attivo', width: 100, dataIndex: 'active', key: 'percentage', fixed: 'center',
            render: (text, record) => (
               <span>
                  <Button className="link" onClick={() => this.toggleActiveHandler(record.id)}>{record.active ? <CheckOutlined twoToneColor="green" /> : <CloseOutlined twoToneColor="#red" />}</Button>
               </span>),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button className="link" onClick={() => this.props.history.push('/price_rules/edit/' + (record.id)) }><EditTwoTone /></Button>
                  <Popconfirm
                     placement="topRight"
                     title="Cancelli?"
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button className="link"><i className="far fa-trash-alt"></i></Button>
                  </Popconfirm>
               </Space>),
         },
      ];

      const tableLayout = {
         bordered: true
      }

      const Panels = () => {
         console.log(this.state);
         return (
            <Collapse defaultActiveKey={this.state.marketplacesList[1].id} onChange={() => this.panelsCallback}>
               {
                  this.state.marketplacesList.map((marketplace) => {
                     return (
                        <Panel header={marketplace.name} key={marketplace.id}>
                           <Table
                              {...tableLayout}
                              columns={table_columns}
                              dataSource={this.state.priceRulesList[marketplace.id]}
                              rowKey="order_column"
                              rowMarketplaceid={marketplace.id}
                              components={{
                                 body: {
                                    wrapper: DraggableContainer,
                                    // row: DragableBodyRow,
                                 },
                              }}
                           />
                        </Panel>
                     )
                  })
               }
            </Collapse>
         );
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.marketplaces.title')}
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              size='large'
                              type='primary'
                              icon={<PlusSquareOutlined />}
                              onClick={() => this.props.history.push('/price_rules/edit/')}
                           >
                              Aggiungi regola di prezzo
                           </Button>
                        </Space>
                     </div>
                  </div>
               </div></div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : <Panels />}
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      // const editForm = (
      //    <div className="card">
      //       <div className="card-header">Tasse</div>
      //       <div className="card-body">
      //          <TaxRatesForm action={this.state.action} itemData={this.state.itemData} closeAction={() => this.closeActionHandler()} />
      //       </div>
      //    </div>
      // );

      return (
         <div className="container-fluid">
            <PageHeader
               title='Marketplace'
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(PriceRulesList);