import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';

import GenericError from './GenericError';
import Unauthorized from './Unauthorized';


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });   // Display fallback UI
    }

    render() {
        if (this.state.hasError) {
            return <GenericError commonState={this.props.commonState} messageKey='error.genericError'/>;
        } 
        else if (this.props.commonState.errorCode > 0) {

            if (this.props.commonState.errorCode === 401 || this.props.commonState.errorCode === 403)
                return <Unauthorized code={_.toString(this.props.commonState.errorCode)} commonState={this.props.commonState}/>;
            else
                return <GenericError commonState={this.props.commonState} messageKey='loading.error.content'/>;
        }
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    commonState: PropTypes.object
};


export default ErrorBoundary;
