import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'

import API_BASE from '../../../api/apiBase'
import API_CONFIG from '../../../api/apiConfig'

import { Divider, Tooltip, Popconfirm, Modal, Alert, List, Card, Table, Form, Radio, Input, InputNumber, Select, TreeSelect, Switch, DatePicker, Button, Space, notification, Tabs, Typography, Upload, message, Row, Col, Image } from 'antd'
import { InfoCircleOutlined, InboxOutlined, LoadingOutlined, PlusOutlined, EditOutlined, DeleteOutlined, LineChartOutlined, DoubleLeftOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/products'

import Loader from '../../common/PageElements/Loader'
import { startLoading, endLoading, download } from '../../../shared/utility'
import { ProductsAvailabilityEnum } from '../../../shared/enums'

import SelectCategory from '../categories/widget/SelectCategory'
import CreateCategoryDrawer from '../categories/widget/CreateCategoryDrawer'

import CreateVariationDrawer from './widget/CreateVariationDrawer'
import CreateStockDrawer from './widget/CreateStockDrawer'

// import DragSortingUpload from './store/utilities/dragSorting.js'
import SpecificsSelect from './store/utilities/specificsSelect'
import TabPrices from './store/utilities/tabPrices'


function getBase64(img, callback) {
   const reader = new FileReader();
   reader.addEventListener('load', () => callback(reader.result));
   reader.readAsDataURL(img);
}

// function beforeUpload(file) {
//    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
//    if (!isJpgOrPng) {
//       message.error('You can only upload JPG/PNG file!');
//    }
//    const isLt2M = file.size / 1024 / 1024 < 2;
//    if (!isLt2M) {
//       message.error('Image must smaller than 2MB!');
//    }
//    return isJpgOrPng && isLt2M;
// }

class ProductsEdit extends Component {

   state = {
      action: undefined,
      itemId: undefined,
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      loaderAddFiles: false,
      loadersFiles: false,
      loaderOrderSave: false,
      loaderImageDelete: false,
      loaderAddImages: false,
      loaderAddImageFromUrl: false,
      loadersStocks: [],
      loadersVariations: [],
      loadersVariationsAnalysis: [],
      loadersVariationsAvailability: [],
      loadersVariationImage: [],
      formValues: {
         categories: []
      },
      componentLanguage: this.props.authState.defaultLanguage,
      categories_tree: [],
      tax_rates: [],
      marketplaces: [],
      tags: [],
      specifics: [],
      file_types: [],
      actual_file_type_id: null,
      fileUploadList: [],
      fileUploadWarnings: [],
      imageUploadList: [],
      imageUploadUrl: '',
      imageUploadWarnings: [],
      price_management_date_range: false,
      image: "https://www.ocalab.it/wp-content/uploads/2019/10/img-pack-3-300x300.png",
      imageUrl: "",
      uploadLoading: false,

      showAnalysisModal: false,
      productAnalysis: {},
   };

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   setForm = (response, lang = this.state.componentLanguage) => {
      let tag_ids = response.tag_ids
      _.forEach(tag_ids, (tag_id, i) => {
         tag_ids[i] = tag_id.toString()
      });

      const values = {
         parent_id: response.parent_id,
         parent_name: response.parent ? response.parent.name[lang] : 'Prodotto padre',
         product_type: response.is_parent ? 'variable' : 'simple',
         price_on_variation: response.price_on_variation ? true : false,
         price_on_request: response.price_on_request ? true : false,
         sku: response.sku,
         mpn: response.mpn,
         asin: response.asin,
         ean: response.ean,
         upc: response.upc,
         gtin: response.gtin,
         taric: response.taric,
         amazon_url: response.amazon_url,
         title: response.translations[0].title,
         brand_id: response.brand_id,
         category_ids: response.category_ids,
         categories: response.categories,
         main_category_id: response.main_category_id,
         // intro: response.intro[lang],
         description: response.translations[0].description,
         file_list: response.file_list,
         gallery: response.gallery,
         manage_quantity: response.manage_quantity ? true : false,
         always_available: response.always_available ? true : false,
         use_stocks: response.use_stocks ? true : false,
         stocks: response.stocks,
         quantity: response.actual_quantity,
         price_whs: response.translations[0].price_whs,
         price_retail: response.translations[0].price_retail,
         price_net_amazon: response.translations[0].price_net_amazon,
         price_net_amazon_ria: response.translations[0].price_net_amazon_ria,
         tax_rate_id: response.translations[0].tax_rate_id,
         weight: response.weight,
         length: response.length,
         height: response.height,
         width: response.width,
         tags: tag_ids,
         specifics: response.specific_values,
         is_parent: response.is_parent,
         variations: response.variations,
         marketplace_prices: response.marketplace_prices,
         marketplace_published: response.marketplace_published,
         // is_visible: response.visible[lang],
         id: response.id,
         language: lang,
         image: response.image
      }
      return values;
   }

   getItem = (id) => {
      return storeActions.getItem(id)
         .then(response => {
            console.log(response);
            try {
               const updatedValues = this.setForm(response.data);
               // console.log(updatedValues)
               this.setState({ response: response.data, formValues: updatedValues });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getProductComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  response: response,
                  categories_tree: response.categoriesTree,
                  tax_rates: response.taxRatesList,
                  marketplaces: response.marketplacesList,
                  specifics: response.specificsList,
               });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id
         Promise.all([
            this.getItem(itemId),
            this.getComplements()
         ]).then(() => {
            this.setState({
               action: 'edit',
               itemId: itemId,
               pageLoader: false
            });
         });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage,
            categories: [],
            gallery: [],
            product_type: 'simple',
         }
         Promise.all([
            this.getComplements()
         ]).then(() => {
            this.setState({
               formValues: formValues,
               action: 'insert',
               pageLoader: false
            });
         });
      }
   }

   confirmHandler = (onConfirm, itemId, productId = null) => {
      switch (onConfirm) {
         case 'deleteFile':
            if (itemId) {
               this.handleDeleteFile(itemId);
            }
            break;
         case 'deleteStock':
            if (productId, itemId) {
               this.deleteStockHandler(productId, itemId);
            }
            break;
         case 'deleteVariation':
            if (itemId) {
               this.deleteVariationHandler(itemId);
            }
            break;
         default:
            break;
      }
   }

   handleResetForm = () => {
      this.setState({ formLoader: true })
      if (this.state.itemId) {
         Promise.all([
            this.getItem(this.state.itemId)
         ]).then(() => {
            this.setState({ formLoader: false });
         });
      }
      else {
         this.setState({ formValues: { categories: [] }, formLoader: false });
      }
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   handleProductTypeChange = (e) => {
      console.log(e.target.value)
      let newValues = { ...this.state.formValues }
      switch (e.target.value) {
         case 'simple':
            newValues.is_parent = false
            break
         case 'variable':
            newValues.is_parent = true
            newValues.variations = _.isNil(newValues.variations) ? [] : newValues.variations
            break
      }
      this.setState({ formValues: newValues })
   }
   handlePriceTypeChange = (e) => {
      console.log(e.target.value)
      let newValues = { ...this.state.formValues }
      newValues.price_on_variation = e.target.value
      this.setState({ formValues: newValues })
   }
   handlePriceOnRequestChange = (e) => {
      console.log(e.target.value)
      let newValues = { ...this.state.formValues }
      newValues.price_on_request = e.target.value
      this.setState({ formValues: newValues })
   }

   // onParentChange = value => {
   //    console.log(value);
   //    this.setState({ value });
   // };
   onCreateCategoryFinish = values => {
      console.log("Create category", values);
   }
   onCreateCategoryFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   onFinish = values => {
      console.log(this.state.formValues.specifics);
      console.log(this.state);
      values.specifics = this.state.formValues.specifics
      values.categories = this.state.formValues.category_ids
      console.log(values);
      this.setState({ formLoader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.goBack();
               }
               const updatedValues = this.setForm(response.data);
               this.setState({ response: response.data, itemId: response.data.id, action: 'edit', formValues: updatedValues, formLoader: false });

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ formLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   onVisibleSwitchChange = (checked) => {
      let newFormValues = { ...this.state.formValues }
      newFormValues.is_visible = checked;
      this.setState({ formValues: newFormValues });
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   onCategoriesTreeChange = value => {
      console.log('onChange ', value);
      this.setState({ value });
   };

   handleImagesOrderSave = images => {
      console.log('imagesOrderSaveHandler', images)
      this.setState({ loaderOrderSave: true });
      storeActions.saveImagesOrder(this.props.match.params.id, images)
         .then(response => {
            console.log(response);
            try {
               let newFormValues = { ...this.state.formValues }
               newFormValues.gallery = response.data;
               this.setState({ formValues: newFormValues, loaderOrderSave: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loaderOrderSave: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ loaderOrderSave: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   };

   //PRICE MANAGEMENT
   dateRangeRadioChange = e => {
      console.log('radio checked', e.target.value);
      this.setState({
         price_management_date_range: e.target.value,
      });
   };
   // setPriceOperator = e => {
   //    console.log('Select operator', e.target.value);
   //    this.setState({
   //       price_management_operator: e.target.value,
   //    });
   //    calculateFinalPrice();
   // };
   // setPriceVariation = e => {
   //    console.log('Select variation', e.target.value);
   //    this.setState({
   //       price_management_variation: e.target.value,
   //    });
   //    calculateFinalPrice();
   // };
   // calculateFinalPrice = () => {
   //    if(this.state.price_management_operator && this.state.price_management_variation) {
   //       let base = 10;

   //    }
   // }

   handleUploadChange = info => {
      if (info.file.status === 'uploading') {
         this.setState({ loading: true });
         return;
      }
      if (info.file.status === 'done') {
         // Get this url from response in real world.
         getBase64(info.file.originFileObj, imageUrl =>
            this.setState({
               imageUrl,
               loading: false,
            }),
         );
      }
   };

   handleSaveSpecific = (index, specific) => {
      console.log('save specific', index, specific)
      let newFormValues = { ...this.state.formValues }
      let specifics = this.state.formValues.specifics
      specifics[index] = specific
      newFormValues.specifics = specifics;
      console.log(specifics)
      this.setState({ formValues: newFormValues });
   }

   handleDeleteSpecific = (index) => {
      console.log('delete specific', index)
      let newFormValues = { ...this.state.formValues }
      let specifics = this.state.formValues.specifics
      specifics.splice(index, 1)
      newFormValues.specifics = specifics;
      console.log(specifics)
      this.setState({ formValues: newFormValues });
   }

   addSpecific = () => {
      let newFormValues = { ...this.state.formValues }
      let specifics = this.state.formValues.specifics ? this.state.formValues.specifics : []
      specifics.push(null)
      newFormValues.specifics = specifics;
      this.setState({ formValues: newFormValues });
   }

   handleSelectCategories = (value) => {
      console.log('select categories', value)
      let newFormValues = { ...this.state.formValues }
      newFormValues.category_ids = value;
      this.setState({ formValues: newFormValues });
   }

   handleSaveCustomPrice = (marketplace_id, variation_operator, variation_amount, start_date, end_date) => {
      console.log('handleSaveCustomPrice', marketplace_id, variation_operator, variation_amount, start_date, end_date)
      this.setState({ loaderOrderSave: true });
      storeActions.saveCustomPrice(this.props.match.params.id, marketplace_id, variation_operator, variation_amount, start_date, end_date)
         .then(response => {
            console.log(response);
            try {
               let newFormValues = { ...this.state.formValues }
               newFormValues.marketplace_prices = response.data;
               this.setState({ formValues: newFormValues, loaderOrderSave: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loaderOrderSave: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ loaderOrderSave: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   showCreateCategoryDrawer = () => {
      this.setState({ showCreateCategoryDrawer: true })
   }
   hideCreateCategoryDrawer = () => {
      this.setState({ showCreateCategoryDrawer: false })
   }
   handleCreateCategory = (categories_tree) => {
      console.log('Categoria creata correttamente')
      this.setState({ categories_tree: categories_tree, showCreateCategoryDrawer: false })
   }

   editVariationHandler = item => {
      this.setState({ createVariationDrawerItem: item, showCreateVariationDrawer: true })
   }
   deleteVariationHandler = itemId => {
      console.log('deleteVariationHandler', itemId)
      this.setState({ loadersVariations: startLoading(this.state.loadersVariations, itemId) });
      storeActions.deleteProductVariation(itemId, true)
         .then(response => {
            console.log(response);
            try {
               let newFormValues = { ...this.state.formValues }
               newFormValues.variations = response.data;
               this.setState({ formValues: newFormValues, loadersVariations: endLoading(this.state.loadersVariations, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loadersVariations: endLoading(this.state.loadersVariations, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ loadersVariations: endLoading(this.state.loadersVariations, itemId) });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   showCreateVariationDrawer = () => {
      if (this.state.itemId) {
         this.setState({ createVariationDrawerItem: null, showCreateVariationDrawer: true })
      } else {
         notification.open({
            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
            description: languageManager.getMessage(this.props.commonState, 'notification.warning.saveProductBeforeVariation'),
            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
         })
      }
   }
   hideCreateVariationDrawer = () => {
      this.setState({ createVariationDrawerItem: null, showCreateVariationDrawer: false })
   }
   handleCreateVariation = (variations_list) => {
      console.log('Variante creata correttamente')
      var newFormValues = { ...this.state.formValues }
      newFormValues.variations = variations_list
      this.setState({ formValues: newFormValues, createVariationDrawerItem: null, showCreateVariationDrawer: false })
   }

   saveVariationImageHandler = (e) => {
      var itemId = e.target.variationId
      console.log('saveVariationImageHandler', itemId)
      this.setState({ loadersVariationImage: startLoading(this.state.loadersVariationImage, itemId) });
      storeActions.saveVariationImage(itemId, e.target.value)
         .then(response => {
            console.log(response);
            try {
               var newFormValues = { ...this.state.formValues }
               _.forEach(newFormValues.variations, (variation, index) => {
                  if (itemId === variation.id) {
                     variation.images = response.data
                     return false;
                  }
               })
               this.setState({ formValues: newFormValues, loadersVariationImage: endLoading(this.state.loadersVariationImage, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loadersVariationImage: endLoading(this.state.loadersVariationImage, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ loadersVariationImage: endLoading(this.state.loadersVariationImage, itemId) });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   deleteVariationImageHandler = (itemId) => {
      console.log('deleteVariationImageHandler', itemId)
      this.setState({ loadersVariationImage: startLoading(this.state.loadersVariationImage, itemId) });
      storeActions.deleteVariationImage(itemId)
         .then(response => {
            console.log(response);
            try {
               var newFormValues = { ...this.state.formValues }
               _.forEach(newFormValues.variations, (variation, index) => {
                  if (itemId === variation.id) {
                     variation.images = response.data
                     return false;
                  }
               })
               this.setState({ formValues: newFormValues, loadersVariationImage: endLoading(this.state.loadersVariationImage, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               this.setState({ loadersVariationImage: endLoading(this.state.loadersVariationImage, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            this.setState({ loadersVariationImage: endLoading(this.state.loadersVariationImage, itemId) });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   manageQuantityHandler = (checked) => {
      console.log(checked)
      var newFormValues = { ...this.state.formValues }
      newFormValues.manage_quantity = checked
      console.log(checked, newFormValues)
      this.setState({ formValues: newFormValues });
   }
   useStocksHandler = (checked) => {
      var newFormValues = { ...this.state.formValues }
      newFormValues.use_stocks = checked
      this.setState({ formValues: newFormValues });
   }

   hideAnalysisModal = () => {
      this.setState({ showAnalysisModal: false, productAnalysis: {} })
   }
   getAnalysis = (itemId) => {
      this.setState({ loadersVariationsAnalysis: startLoading(this.state.loadersVariationsAnalysis, itemId) });
      storeActions.getProductAnalysis(itemId)
         .then(response => {
            console.log(response);
            this.setState({ loadersVariationsAnalysis: endLoading(this.state.loadersVariationsAnalysis, itemId) });
            try {
               this.setState({ showAnalysisModal: true, productAnalysis: response.data.product_data[0] });
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.downloadOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
            this.setState({ loadersVariationsAnalysis: endLoading(this.state.loadersVariationsAnalysis, itemId) });
         });
   }

   sendAvailability = (itemId) => {
      this.setState({ loadersVariationsAvailability: startLoading(this.state.loadersVariationsAvailability, itemId) });
      storeActions.setProductAvailable(itemId)
         .then(response => {
            console.log(response);
            this.setState({ loadersVariationsAvailability: endLoading(this.state.loadersVariationsAvailability, itemId) });
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.uploadOk'),
            })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
            this.setState({ loadersVariationsAvailability: endLoading(this.state.loadersVariationsAvailability, itemId) });
         });
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title, Text } = Typography

      const mainLayout = {
         labelCol: { span: 6 },
         wrapperCol: { span: 18 },
         layout: "horizontal",
      };
      const sideLayout = {
         labelCol: { span: 10 },
         wrapperCol: { span: 14 },
         layout: "horizontal",
      };
      const { TextArea } = Input
      const { Option } = Select
      const { TabPane } = Tabs
      const { RangePicker } = DatePicker

      const scope = this;
      const { Dragger } = Upload;

      const stocksTableColumns = [
         {
            title: 'Quantità', key: 'quantity',
            fixed: 'left',
            width: 100,
            render: (record) => (
               record.quantity
            ),
         },
         {
            title: 'Data di scadenza', key: 'expiry_date',
            fixed: 'left',
            width: 150,
            render: (record) => (
               record.expiry_date ? Moment(record.expiry_date).format('L') : ''
            ),
         },
         {
            title: 'Lotto', key: 'batch',
            width: 300,
            render: (record) => (
               record.batch
            ),
         },
         {
            title: 'Data di acquisto', key: 'purchase_date',
            width: 150,
            render: (record) => (
               record.purchase_date ? Moment(record.purchase_date).format('L') : ''
            ),
         },
         {
            title: 'Fornitore', key: 'supplier',
            render: (record) => (
               record.supplier ? record.supplier.name : null
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <Button type="primary" size='small'
                        disabled={this.state.loadersStocks[record.id]}
                        icon={<EditOutlined />}
                        onClick={() => this.editStockHandler(record.product_id, record)}
                     />
                     {/* <Dropdown.Button type="primary" onClick={() => this.editItemHandler(record.id)} overlay={actionsMenu(record.id)}><EditOutlined /></Dropdown.Button> */}
                  </Tooltip>
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteStock', record.id, record.product_id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size='small'
                        loading={this.state.loadersStocks[record.id]}
                        icon={<DeleteOutlined />}
                     />
                  </Popconfirm>
               </Space>),
         },

      ];
      const stocksTableLayout = {
         bordered: true,
         ellipsis: true,
         pagination: false,
         size: "small",
         className: 'margin-0-I'
      }
      const stockTable = <Table key='stocks_table' {...stocksTableLayout} columns={stocksTableColumns} dataSource={this.state.formValues.stocks} />
      const tabWarehouse = !this.state.formValues.is_parent ? (
         <TabPane tab="Magazzino" key="warehouse_tab">
            <Row gutter={[8, 8]}>
               <Col span={24}>
                  <Space>
                     <Button
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={() => this.showCreateStockDrawer(this.state.itemId)}
                     >
                        Aggiungi quantità
                     </Button>
                  </Space>
               </Col>
            </Row>
            <Row>
               <Col span={24}>
                  {stockTable}
               </Col>
            </Row>
         </TabPane>
      ) : '';

      const variationsTableColumns = [
         {
            title: 'Variante',
            key: 'variation',
            fixed: 'left',
            width: 150,
            render: (record) => (
               <Space direction='vertical' size='small'>
                  {
                     record.variation_values.map((variation, index) => {
                        return (
                           <Text key={'variation_' + index}>{variation.variation.name[this.state.componentLanguage] + ': ' + variation.name[this.state.componentLanguage]}</Text>
                        )
                     })
                  }
               </Space>
            ),
         },
         {
            title: 'Titolo',
            key: 'title',
            render: (record) => (
               record.title_amazon
            ),
         },
         {
            title: 'ASIN',
            key: 'asin',
            width: 100,
            render: (record) => (
               record.asin
            ),
         },
         {
            title: 'EAN/UPC',
            key: 'ean_upc',
            width: 100,
            render: (record) => (
               !_.isNil(record.ean) ? record.ean : !_.isNil(record.upc) ? record.upc : null
            ),
         },
         {
            title: 'Stagione',
            key: 'season',
            width: 100,
            render: (record) => (
               !_.isNil(record.season) ? record.season.name : null
            ),
         },
         {
            title: 'Vendor code',
            key: 'vendor_code',
            width: 100,
            render: (record) => (
               <Space direction='vertical' size='small'>
                  {
                     record.vendor_codes.map((vendorCode, index) => {
                        return (
                           <Text key={'vendor_code_' + index}>{vendorCode.code}</Text>
                        )
                     })
                  }
               </Space>
            ),
         },
         {
            title: 'Disponibilità',
            key: 'availability',
            width: 100,
            render: (record) => {
               switch(record.availability) {
                  case ProductsAvailabilityEnum.OBSOLETE:
                     return 'Obsoleto'
                  break
                  case ProductsAvailabilityEnum.IN_STOCK:
                     return 'Disponibile'
                  break
                  case ProductsAvailabilityEnum.TEMP_NOT_AVAILABLE:
                     return 'Non disponibile'
                  break
                  default:
                     return null
                  break
               }
            },
         },
         // {
         //    title: 'Quantità',
         //    key: 'quantity',
         //    width: 100,
         //    render: (record) => (
         //       record.manage_quantity ?
         //          record.actual_quantity :
         //          record.always_available ? 'Disponibile' : 'Non disponibile'
         //    ),
         // },
         {
            title: 'WHS (i.e.)', key: 'price_whs', width: 120,
            render: (record) => (
               this.state.formValues.price_on_variation ? (
                  !_.isNil(record.price_whs) ? parseFloat(record.price_whs).toFixed(2) + '€' : ''
               ) : 'Prezzo su padre'
            ),
            sorter: (a, b) => {
               a = a.price_whs || 0
               b = b.price_whs || 0
               return a - b
            }
         },
         {
            title: 'Retail (i.c.)', key: 'price_retail', width: 100,
            render: (record) => (
               this.state.formValues.price_on_variation ? (
                  !_.isNil(record.price_retail) ? parseFloat(record.price_retail).toFixed(2) + '€' : ''
               ) : 'Prezzo su padre'
            ),
            sorter: (a, b) => {
               a = a.price_retail || 0
               b = b.price_retail || 0
               return a - b
            }
         },
         {
            title: 'Net Amz', key: 'price_net_amazon', width: 100,
            render: (record) => (
               this.state.formValues.price_on_variation ? (
                  record.price_on_request ? 'Su richiesta' : !_.isNil(record.price_net_amazon) ? parseFloat(record.price_net_amazon).toFixed(2) + '€' : ''
               ) : 'Prezzo su padre'
            ),
            sorter: (a, b) => {
               a = a.price_net_amazon || 0
               b = b.price_net_amazon || 0
               return a - b
            }
         },
         {
            title: 'Net Amz RIA', key: 'price_net_amazon_ria', width: 100,
            render: (record) => (
               this.state.formValues.price_on_variation ? (
                  record.price_on_request ? 'Su richiesta' : !_.isNil(record.price_net_amazon_ria) ? parseFloat(record.price_net_amazon_ria).toFixed(2) + '€' : ''
               ) : 'Prezzo su padre'
            ),
            sorter: (a, b) => {
               a = a.price_net_amazon_ria || 0
               b = b.price_net_amazon_ria || 0
               return a - b
            }
         },
         {
            title: 'Net Amz RIA UK', key: 'price_net_amazon_ria_uk', width: 100,
            render: (record) => (
               this.state.formValues.price_on_variation ? (
                  record.price_on_request ? 'Su richiesta' : !_.isNil(record.price_net_amazon_ria_uk) ? parseFloat(record.price_net_amazon_ria_uk).toFixed(2) + '€' : ''
               ) : 'Prezzo su padre'
            ),
            sorter: (a, b) => {
               a = a.price_net_amazon_ria_uk || 0
               b = b.price_net_amazon_ria_uk || 0
               return a - b
            }
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button size="small"
                     icon={<LineChartOutlined />}
                     onClick={() => this.getAnalysis(record.id)}
                     loading={this.state.loadersVariationsAnalysis[record.id]}
                  />
                  <Button type="success" size="small"
                     icon={<PlusOutlined />}
                     onClick={() => this.sendAvailability(record.id)}
                     loading={this.state.loadersVariationsAvailability[record.id]}
                  />
                  <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <Button type="primary" size='small'
                        disabled={this.state.loadersVariations[record.id]}
                        icon={<EditOutlined />}
                        onClick={() => this.editVariationHandler(record)}
                     />
                     {/* <Dropdown.Button type="primary" onClick={() => this.editItemHandler(record.id)} overlay={actionsMenu(record.id)}><EditOutlined /></Dropdown.Button> */}
                  </Tooltip>
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteVariation', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Button type="danger" size='small'
                        loading={this.state.loadersVariations[record.id]}
                        icon={<DeleteOutlined />}
                     />
                  </Popconfirm>
               </Space>),
         },

      ];
      const variationsTableLayout = {
         bordered: true,
         ellipsis: true,
         pagination: false,
         size: "small",
      }
      const variationTable = (
         <Table key='variations_table'
            {...variationsTableLayout}
            columns={variationsTableColumns}
            dataSource={this.state.formValues.variations}
            expandable={{
               expandedRowRender: record => (
                  <div className="card-container">
                     <Tabs type='card' size="large">
                        <TabPane tab="Identificatori" key='identifiers'>
                           {
                              !_.isNil(record.product_identifiers) ? (
                              record.product_identifiers.map((identifier, index) => {
                                 return (
                                    <div key={'identifier_' + index}>
                                       <Text>{identifier.identifier_name}: {identifier.identifier_value}</Text>
                                    </div>
                                 )
                              })
                              ) : null
                           }
                        </TabPane>
                        {
                           this.state.formValues.use_stocks ? (
                              <TabPane tab="Magazzino" key='warehouse'>
                                 <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                       <Space>
                                          <Button
                                             type='primary'
                                             icon={<PlusOutlined />}
                                             onClick={() => this.showCreateStockDrawer(record.id)}
                                          >
                                             Aggiungi quantità
                                          </Button>
                                       </Space>
                                    </Col>
                                 </Row>
                                 <Row>
                                    <Col span={24}>
                                       <Table key='stocks_table_' {...stocksTableLayout} columns={stocksTableColumns} dataSource={record.stocks} />
                                    </Col>
                                 </Row>
                              </TabPane>
                           ) : ''
                        }
                        <TabPane tab="Specifiche" key="specifications">
                           {
                              record.specific_values.map((spec, index) => {
                                 return (
                                    <div className="row" key={'specific_value_' + index}>
                                       <div className="col-sm-6">
                                          <Input value={spec.specific.name[this.state.componentLanguage]} />
                                       </div>
                                       <div className="col-sm-6">
                                          <Input value={spec.name[this.state.componentLanguage]} />
                                       </div>
                                    </div>
                                 )
                              })
                           }
                        </TabPane>
                        <TabPane tab="Bullet point" key='bullet_point'>
                        </TabPane>
                        <TabPane tab="Dati esterni" key='imported_data'>
                        </TabPane>
                        <TabPane tab="Altri dati" key='additional_data'>
                        </TabPane>
                        <TabPane tab="Immagini" key='images'>
                           <Space direction='horizontal'>
                              <Title level={5}>Seleziona immagine per la variante</Title>
                              {
                                 this.state.loadersVariationImage[record.id] ?
                                    <Button danger type="text" loading={true}>Salvataggio in corso</Button>
                                    : ''
                              }
                           </Space>
                           <Row gutter={[8, 8]}>
                              <Col span={24}>
                                 <Radio.Group
                                    defaultValue={record.gallery && !_.isEmpty(record.gallery) ? record.gallery[0].id : false}
                                    // value={record.gallery && !_.isEmpty(record.gallery) ? record.gallery[0].id : false}
                                    onChange={this.saveVariationImageHandler} >
                                    {
                                       this.state.formValues.gallery.map((image, index) => {
                                          return (
                                             <Radio
                                                key={'variation_images_' + image.id}
                                                variationId={record.id}
                                                value={image.id} >
                                                <Image width={150} src={image.thumb_1000} />
                                             </Radio>
                                          )
                                       })
                                    }
                                 </Radio.Group>
                              </Col>
                           </Row>
                           <Space direction='horizontal'>
                              {/* <Button
                                 type='danger'
                                 size='small'
                                 icon={<DeleteOutlined />}
                                 onClick={() => this.deleteVariationImageHandler(record.id)}
                              >Cancella selezione</Button> */}
                              {/* <Button
                                 type='primary'
                                 size='small'
                                 icon={<SaveOutlined />}
                              >Salva selezione</Button> */}
                           </Space>
                        </TabPane>
                     </Tabs>
                  </div>
               ),
               // rowExpandable: record => record.name !== 'Not Expandable',
            }} />
      )
      const tabVariations = this.state.formValues.is_parent ? (
         <TabPane tab="Varianti" key="variations_tab">
            <Row gutter={[8, 8]}>
               <Col span={24}>
                  <Space>
                     <Button
                        type='primary'
                        icon={<PlusOutlined />}
                        onClick={this.showCreateVariationDrawer}
                     >
                        Crea nuova variante
                     </Button>
                     <Button
                        type='primary'
                        icon={<PlusOutlined />}
                        disabled={true}
                     >
                        Crea varianti multiple
                     </Button>
                  </Space>
               </Col>
            </Row>
            <Row>
               <Col span={24}>
                  {variationTable}
               </Col>
            </Row>
            <Modal title="Analisi prodotto" visible={this.state.showAnalysisModal} onCancel={this.hideAnalysisModal} width={1000}>
               <Card bordered={false}>
                  <pre>{JSON.stringify(this.state.productAnalysis, null, 2)}</pre>
               </Card>
            </Modal>
         </TabPane>
      ) : '';

      const specificsTableColumns = [
         {
            title: 'Specifica',
            key: 'specific',
            fixed: 'left',
            render: (record) => (
               record.specific.name[this.state.componentLanguage]
            ),
            sorter: (a, b) => a.specific.name[this.state.componentLanguage].localeCompare(b.specific.name[this.state.componentLanguage]),
         },
         {
            title: 'Valore', key: 'specific_value', fixed: 'left',
            render: (record) => (
               record.name[this.state.componentLanguage]
            ),
            sorter: (a, b) => a.name[this.state.componentLanguage].localeCompare(b.name[this.state.componentLanguage]),
         },

      ];
      const specificsTableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         pagination: false,
      }
      const specificsTable = <Table key='specifics_table' {...specificsTableLayout} columns={specificsTableColumns} dataSource={this.state.formValues.specifics} />
      const tabSpecifics = (
         <TabPane tab="Specifiche" key="specifics">
            {/* <div className="row">
               <div className="col-sm-12">
                  {specificsTable}
               </div>
            </div> */}
            <div>
               {
                  !_.isEmpty(this.state.formValues.specifics) ? (
                     this.state.formValues.specifics.map((spec, index) => (
                        <div key={'specifics_' + index} className='margin-bottom-5px-I'>
                           <SpecificsSelect
                              key={index}
                              index={index}
                              specificsList={this.state.specifics}
                              currentSpec={spec}
                              currentSpecificId={spec ? spec.id : null}
                              updateSpecific={(index, value) => this.handleSaveSpecific(index, value)}
                              deleteSpecific={(index) => this.handleDeleteSpecific(index)}
                           />
                        </div>
                     ))
                  ) : ''
               }
               <Button type="primary" onClick={this.addSpecific}>Aggiungi specifica</Button>
               {/* <SpecificsSelect
                  index='new_spec'
                  specificsList={this.state.specifics}
                  saveSpecific={(values) => this.handleSaveSpecific(values)}
                  deleteSpecific={(values) => this.handleDeleteSpecific(values)}
               /> */}
            </div>


            {/* <div>
               {
                  this.state.formValues.specifics.map((spec, index) => (
                     <SpecificsForm
                        key={index}
                        index={index}
                        specificsList={this.state.specifics}
                        currentSpecific={spec}
                        saveSpecific={(values) => this.handleSaveSpecific(values, spec)}
                        deleteSpecific={(values) => this.handleDeleteSpecific(values, spec)}
                     />
                  ))
               }
               <SpecificsForm
                  index='new_spec'
                  specificsList={this.state.specifics}
                  saveSpecific={(values) => this.handleSaveSpecific(values)}
                  deleteSpecific={(values) => this.handleDeleteSpecific(values)}
               />
            </div> */}
         </TabPane>
      )

      const pricesTableColumns = [
         {
            title: 'Marketplace',
            key: 'marketplace',
            fixed: 'left',
            render: (record) => (
               record.name
            ),
            sorter: (a, b) => a.name.localeCompare(b.name),
         },
         {
            title: 'Prezzo base', key: 'base_price', fixed: 'left',
            render: (record) => (
               record.prices.standard_price
            ),
            sorter: (a, b) => a.prices.standard_price - b.prices.standard_price,
         },
         {
            title: 'Prezzo personalizzato', key: 'current_price', fixed: 'left',
            render: (record) => (
               record.prices.sale_price
            ),
            sorter: (a, b) => a.prices.sale_price - b.prices.sale_price,
         },

      ];
      const pricesTableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         pagination: false,
      }
      const pricesTable = <Table {...pricesTableLayout} columns={pricesTableColumns} dataSource={this.state.formValues.marketplace_prices} />

      const radioStyle = {
         display: 'block',
         height: '30px',
         lineHeight: '30px',
      };

      const singlePrice = (
         <Card>
            <Radio.Group onChange={this.dateRangeRadioChange}>
               <Radio style={radioStyle} value={'always'}>Sempre valido</Radio>
               <Radio style={radioStyle} value={'range'}>Range di date</Radio>
            </Radio.Group>
            {this.state.price_management_date_range === 'range' ? ([<br />, <RangePicker />]) : ''}

            <Input.Group compact>
               <Select onChange={this.setPriceOperator}>
                  <Option value="=">=</Option>
                  <Option value="€">€</Option>
                  <Option value="%">%</Option>
               </Select>
               <InputNumber onChange={this.setPriceVariation} />
            </Input.Group>
            <Input placeholder="Prezzo finale" value={this.calculateFinalPrice} />
         </Card>
      );

      const { uploadLoading, imageUrl } = this.state;

      const uploadButton = (
         <div>
            {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
         </div>
      );

      const normFile = e => {
         console.log('Upload event:', e);
         if (Array.isArray(e)) {
            return e;
         }
         return e && e.fileList;
      };

      const imageUploadProps = {
         name: 'file',
         multiple: true,
         fileList: this.state.imageUploadList,
         // disabled: this.state.draggerDisable,
         action: API_BASE.url + API_CONFIG.appUrls.files.fileImport,
         onChange(info) {
            // scope.setState({ draggerDisable: info.fileList.length });
            console.log("CHANGE: ", info);
            const { status } = info.file;
            if (status !== 'uploading') {
               console.log(info.file, info.fileList);
               scope.setState({ imageUploadList: info.fileList });
            }
            if (status === 'done') {
               message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
               message.error(`${info.file.name} file upload failed.`);
            }
         },
      };

      let form = (
         <Card bordered={false} bodyStyle={{ padding: 0 }}
         // loading={this.state.formLoader}
         >
            <Form
               {...mainLayout}
               id="createProduct"
               name="basic"
               initialValues={this.state.formValues}
               onFinish={this.onFinish}
               onFinishFailed={this.onFinishFailed}
            >
               <Row gutter={32}>
                  <Col span={14}>
                     <Form.Item label="Tipo di prodotto" name="product_type"
                        rules={[{ required: true, message: 'Scegli il tipo di prodotto' }]}>
                        <Radio.Group
                           onChange={this.handleProductTypeChange}>
                           <Radio.Button value="simple">Semplice</Radio.Button>
                           <Radio.Button value="variable">Con varianti</Radio.Button>
                        </Radio.Group>
                     </Form.Item>
                     <Form.Item
                        label="Nome"
                        name="title"
                        rules={[{ required: true, message: 'Inserisci il nome del prodotto' }]}
                     >
                        <Input />
                     </Form.Item>
                     <Form.Item
                        label="MPN"
                        name="mpn"
                        rules={[{ message: 'Inserisci il codice MPN' }]}
                     >
                        <Input />
                     </Form.Item>
                     {/* <Form.Item
                        label="ASIN"
                        name="asin"
                        rules={[{ message: 'Inserisci il codice ASIN' }]}
                     >
                        <Input />
                     </Form.Item> */}
                     <Form.Item
                        label="TARIC"
                        name="taric"
                     >
                        <Input />
                     </Form.Item>

                     {
                        !this.state.formValues.is_parent ?
                           [
                              <Form.Item
                                 label="EAN"
                                 name="ean"
                                 key='ean'
                              >
                                 <Input />
                              </Form.Item>,
                              <Form.Item
                                 label="UPC"
                                 name="upc"
                                 key='upc'
                              >
                                 <Input />
                              </Form.Item>,
                              <Form.Item
                                 label="GTIN"
                                 name="gtin"
                                 key='gtin'
                              >
                                 <Input />
                              </Form.Item>,
                           ] : ''
                     }

                     <Form.Item
                        label="Categoria"
                        rules={[]}
                     >
                        <Row gutter={8}>
                           <Col span={14}>
                              <SelectCategory
                                 categoriesTree={this.state.categories_tree}
                                 initialValue={this.state.formValues.category_ids}
                                 onChange={(values) => this.handleSelectCategories(values)}
                              />
                           </Col>
                           <Col span={10}>
                              <Button
                                 type='primary' style={{ width: '100%' }}
                                 icon={<PlusOutlined />}
                                 onClick={this.showCreateCategoryDrawer}
                              >
                                 Nuova categoria
                              </Button>
                              <CreateCategoryDrawer
                                 visible={this.state.showCreateCategoryDrawer}
                                 onClose={this.hideCreateCategoryDrawer}
                                 onSubmit={(categories_tree) => this.handleCreateCategory(categories_tree)}
                              />
                           </Col>
                        </Row>

                     </Form.Item>

                     <Form.Item
                        label="Categoria principale"
                        name="main_category_id"
                        rules={[]}
                     >

                        <Select
                           showSearch
                           placeholder="Seleziona la categoria principale"
                           optionFilterProp="children"
                        >
                           {
                              this.state.formValues.categories.map((category) => {
                                 return <Option key={category.id} value={category.id}>{category.name[this.state.componentLanguage]}</Option>
                              })
                           }
                        </Select>
                     </Form.Item>
                     <Form.Item
                        label="URL Amazon"
                        name="amazon_url"
                     >
                        <Input />
                     </Form.Item>
                  </Col>
                  <Col span={10}>
                     {/* <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.products.quantity')}</Title> */}
                     <Form.Item
                        {...sideLayout}
                        label="Gestisci quantità"
                        name="manage_quantity"
                        valuePropName='checked'
                     >
                        <Switch
                           disabled={true}
                           onChange={this.manageQuantityHandler} />
                     </Form.Item>
                     {/* {
                        this.state.formValues.manage_quantity ? (
                           [
                              <Form.Item
                                 {...sideLayout}
                                 label="Gestisci lotti"
                                 name="use_stocks"
                                 valuePropName='checked'
                              >
                                 <Switch
                                    onChange={this.useStocksHandler} />
                              </Form.Item>,
                              <Form.Item
                                 {...sideLayout}
                                 label="Quantità disponibile"
                                 name="quantity"
                                 rules={[]}
                              >
                                 <InputNumber step={1}
                                    // disabled={this.state.formValues.is_parent}
                                    disabled={this.state.formValues.is_parent || this.state.formValues.use_stocks} />
                              </Form.Item>
                           ]
                        ) : (
                           <Form.Item
                              {...sideLayout}
                              label="Disponibilità"
                              name="always_available"
                           >
                              <Radio.Group disabled={true}>
                                 <Radio.Button value={true}>Disponibile</Radio.Button>
                                 <Radio.Button value={false}>Non disponibile</Radio.Button>
                              </Radio.Group>
                           </Form.Item>
                        )
                     } */}

                     {/* <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.products.price')}</Title> */}
                     {/* <Form.Item
                           label="Prezzo di acquisto tasse escluse"
                           name="price_buy_no_vat"
                           rules={[]}
                        >
                           <InputNumber
                              size="large"
                              // style={"width= '100%'"}
                              formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\€\s?|(,*)/g, '')}
                              onChange={this.onPriceSellNoVatChange}
                           />
                        </Form.Item>
                        <Form.Item
                           label="Prezzo di listino tasse escluse"
                           name="price_sell_no_vat"
                           rules={[]}
                        >
                           <InputNumber
                              size="large"
                              // style={"width= '100%'"}
                              formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\€\s?|(,*)/g, '')}
                              onChange={this.onPriceSellNoVatChange}
                           />
                        </Form.Item> */}
                     {/* <Divider />
                     <Form.Item
                        {...sideLayout}
                        label="Regime IVA"
                        name="tax_rate_id"
                        rules={[]}
                     >
                        <Select
                           showSearch
                           // className="width-70px-I"
                           placeholder="Seleziona il regime IVA"
                           optionFilterProp="children"
                        >
                           {
                              this.state.tax_rates.map((tax) => {
                                 return <Option key={tax.id} value={tax.id}>{tax.name[this.state.componentLanguage]}</Option>
                              })
                           }
                        </Select>
                     </Form.Item> */}
                     {
                        this.state.formValues.is_parent ? (
                           <Form.Item
                              {...sideLayout}
                              label="Tipo di prezzo"
                              name="price_on_variation">
                              <Radio.Group
                                 onChange={this.handlePriceTypeChange}>
                                 <Radio.Button value={false}>Su prodotto padre</Radio.Button>
                                 <Radio.Button value={true}>Su varianti</Radio.Button>
                              </Radio.Group>
                           </Form.Item>
                        ) : null
                     }
                     {
                        !this.state.formValues.is_parent || !this.state.formValues.price_on_variation ? [
                           <Form.Item
                              {...sideLayout}
                              label="Prezzo su richiesta"
                              key="price_on_request"
                              name="price_on_request">
                              <Radio.Group
                                 disabled={true}
                                 onChange={this.handlePriceOnRequestChange}>
                                 <Radio.Button value={false}>Prezzo indicato</Radio.Button>
                                 <Radio.Button value={true}>Prezzo su richiesta</Radio.Button>
                              </Radio.Group>
                           </Form.Item>,
                           !this.state.formValues.price_on_request ? ([
                              <Form.Item
                                 key='price_whs'
                                 {...sideLayout}
                                 label="Prezzo WHS (tasse escluse)"
                                 name="price_whs"
                                 rules={[]}
                              >
                                 <InputNumber
                                    decimalSeparator=','
                                    precision={2}
                                    onChange={this.onPriceRetailChange}
                                 />
                              </Form.Item>,
                              <Form.Item
                                 key='price_retail'
                                 {...sideLayout}
                                 label="Prezzo retail (tasse incluse)"
                                 name="price_retail"
                                 rules={[]}
                              >
                                 <InputNumber
                                    decimalSeparator=','
                                    precision={2}
                                    onChange={this.onPriceBuyChange}
                                 />
                              </Form.Item>,
                              <Form.Item
                                 key='price_net_amazon'
                                 {...sideLayout}
                                 label="Prezzo Amazon (tasse escluse)"
                                 name="price_net_amazon"
                                 rules={[]}
                              >
                                 <InputNumber
                                    decimalSeparator=','
                                    precision={2}
                                    onChange={this.onPriceBuyChange}
                                 />
                              </Form.Item>,
                              <Form.Item
                                 key='price_net_amazon_ria'
                                 {...sideLayout}
                                 label="Prezzo Amazon RIA (tasse escluse)"
                                 name="price_net_amazon_ria"
                                 rules={[]}
                              >
                                 <InputNumber
                                    decimalSeparator=','
                                    precision={2}
                                    onChange={this.onPriceBuyChange}
                                 />
                              </Form.Item>
                           ]) : null
                        ] : null
                     }
                     {
                        !this.state.formValues.is_parent ? [
                           <Divider key='shipment_divider' />,
                           <Form.Item
                              key='weight'
                              {...sideLayout}
                              label="Peso (KG)"
                              name="weight"
                              rules={[]}
                           >
                              <InputNumber
                                 decimalSeparator=','
                                 precision={2}
                              />
                           </Form.Item>,
                           <Form.Item
                              key='length'
                              {...sideLayout}
                              label="Lunghezza (m)"
                              name="length"
                              rules={[]}
                           >
                              <InputNumber
                                 decimalSeparator=','
                                 precision={6}
                              />
                           </Form.Item>,
                           <Form.Item
                              key='height'
                              {...sideLayout}
                              label="Altezza (m)"
                              name="height"
                              rules={[]}
                           >
                              <InputNumber
                                 decimalSeparator=','
                                 precision={6}
                              />
                           </Form.Item>,
                           <Form.Item
                              key='width'
                              {...sideLayout}
                              label="Profondità (m)"
                              name="width"
                              rules={[]}
                           >
                              <InputNumber
                                 decimalSeparator=','
                                 precision={6}
                              />
                           </Form.Item>
                        ] : null
                     }
                  </Col>
               </Row>
               <Row>
                  <Col span={24}>
                     <Tabs size="large">
                        {tabVariations}
                        {
                           this.state.formValues.use_stocks ? tabWarehouse : ''
                        }
                        <TabPane tab="Descrizione" key="1">
                           {/* <Form.Item
                           {...tabMainLayout}
                           label="Intro"
                           name="intro"
                           rules={[{ message: 'Testo di introduzione' }]}
                        >
                           <TextArea
                              rows={2}
                           />
                        </Form.Item> */}
                           <Form.Item
                              label="Descrizione"
                              labelCol={{ span: 3 }}
                              wrapperCol={{ span: 20 }}
                              name="description"
                              rules={[{ message: 'Inserisci la descrizione' }]}
                           >
                              <TextArea
                                 rows={10}
                              />
                           </Form.Item>

                        </TabPane>
                        {tabSpecifics}
                        {/* {
                           !this.state.formValues.is_parent || !this.state.formValues.price_on_variation ? (
                              <TabPane tab="Prezzi" key="2">
                                 {
                                    !_.isEmpty(this.state.formValues.marketplace_prices) ? (
                                       <Row gutter={16}>
                                          {this.state.formValues.marketplace_prices.map((marketplace, index) => {
                                             return (
                                                <Col key={'prices_' + index} span={8}>
                                                   <TabPrices
                                                      index={index}
                                                      marketplace={marketplace}
                                                      list_price={this.state.formValues.price_sell}
                                                      savePrice={(variation_operator, variation_amount, start_date, end_date) => this.handleSaveCustomPrice(marketplace.id, variation_operator, variation_amount, start_date, end_date)}
                                                   />
                                                </Col>
                                             )
                                          })}
                                       </Row>
                                    ) : ''
                                 }
                              </TabPane>
                           ) : ''
                        }
                        <TabPane tab="Marketplace" key="3">
                           {
                              !_.isEmpty(this.state.formValues.marketplace_published) ? (
                                 <Row gutter={16}>
                                    {this.state.formValues.marketplace_published.map((marketplace, index) => {
                                       return (
                                          <Col key={'published_' + index} span={8}>

                                             <Card title={marketplace.name}>
                                                <Form.Item
                                                   label="Pubblicato"
                                                   name={"published_" + marketplace.id}
                                                   initialValue={marketplace.published}
                                                   valuePropName='checked'
                                                >
                                                   <Switch
                                                      defaultChecked={marketplace.published}
                                                   />
                                                </Form.Item>
                                             </Card>
                                          </Col>
                                       )
                                    })}
                                 </Row>
                              ) : ''
                           }
                        </TabPane> */}
                        <TabPane tab="Immagini" key="4">
                           <Row gutter={[16, 16]}>
                              <Col span={6}>
                                 <Title level={4}>Galleria immagini</Title>
                                 {/* <DragSortingUpload
                                    images={this.state.formValues.gallery}
                                    imagesOrderSave={(images) => this.handleImagesOrderSave(images)}
                                    loaderOrderSave={this.state.loaderOrderSave}
                                    imageDelete={(imageId) => this.handleImageDelete(imageId)}
                                    loaderImageDelete={this.state.loaderImageDelete}
                                 /> */}
                              </Col>
                              <Col span={12} offset={6}>
                                 <Title level={4}>Aggiungi immagini</Title>
                                 {
                                    this.state.imageUploadWarnings.length ?
                                       <Alert
                                          message="Attenzione"
                                          description={<List
                                             size="small"
                                             dataSource={this.state.imageUploadWarnings}
                                             renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                                          />}
                                          type="warning"
                                          showIcon
                                          closable
                                       /> : ''
                                 }
                                 <div>
                                    <Dragger {...imageUploadProps}>
                                       <p className="ant-upload-drag-icon">
                                          <InboxOutlined />
                                       </p>
                                       <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                       <p className="ant-upload-hint">
                                          Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                          band files
                                       </p>
                                    </Dragger>
                                 </div>
                                 <Button
                                    type="primary"
                                    disabled={!this.state.imageUploadList.length}
                                    loading={this.state.loaderAddImages}
                                    onClick={() => this.handleAddImages()}
                                 >Aggiungi</Button>
                                 {/* <Title level={4}>Aggiungi immagine da URL</Title>
                              <Input addonBefore={<LinkOutlined />} value={this.state.imageUploadUrl} onChange={this.handleImageUploadUrl} />
                              <Button
                                 type="primary"
                                 disabled={!this.state.imageUploadUrl.length}
                                 loading={this.state.loaderAddImageFromUrl}
                                 onClick={() => this.handleAddImageFromUrl()}
                              >Aggiungi</Button> */}
                              </Col>
                           </Row>
                        </TabPane>
                        {/* {tabAnalysis} */}
                     </Tabs>
                  </Col>
               </Row>

               <Form.Item label="id" name="id" noStyle><Input type="text" type="hidden" /></Form.Item>
               <Form.Item label="parent_id" name="parent_id" noStyle><Input type="text" type="hidden" /></Form.Item>
               <Form.Item label="language" name="language" noStyle><Input type="text" type="hidden" /></Form.Item>


            </Form >

            <CreateStockDrawer
               visible={this.state.showCreateStockDrawer}
               productId={this.state.stockDrawerProductId}
               item={this.state.createStockDrawerItem}
               onClose={this.hideCreateStockDrawer}
               onSubmit={(product_id, stocks_list) => this.handleCreateStock(product_id, stocks_list)} />
            <CreateVariationDrawer
               visible={this.state.showCreateVariationDrawer}
               itemId={this.state.itemId}
               item={this.state.createVariationDrawerItem}
               parentValues={this.state.formValues}
               onClose={this.hideCreateVariationDrawer}
               onSubmit={(variations_list) => this.handleCreateVariation(variations_list)} />
         </Card>

      );


      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.products.edit')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space size='small'>
                        <Select
                           showSearch
                           className="width-70px-I"
                           placeholder="Seleziona la lingua"
                           defaultValue={this.state.componentLanguage}
                           optionFilterProp="children"
                           onChange={this.setDefaultLanguageHandler}
                        >
                           {
                              this.props.authState.userData.languages.map((lang) => {
                                 return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                              })
                           }
                        </Select>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               {form}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/products/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Button onClick={this.handleResetForm} disabled={this.state.formLoader}>Annulla</Button>
                        <Button form="createProduct" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                        <Button form="createProduct" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.formLoader}>Salva e chiudi</Button>
                     </Space>
                  </Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};


// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(ProductsEdit);