import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class DataProcessingApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static processGroupSizes(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.dataProcessing.processGroupSizes, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static searchProductsByAsin(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.dataProcessing.searchProductsByAsin, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static searchAsinsInOrders(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.dataProcessing.searchAsinsInOrders, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static searchProductsByCode(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.dataProcessing.searchProductsByCode,
            { ...formData },
            { responseType: 'arraybuffer' }
         )
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default DataProcessingApi;