import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Input, InputNumber, Select, TreeSelect, Switch, DatePicker, Button, Space, notification, Tabs, Typography } from 'antd';
import { InfoCircleOutlined, CheckOutlined, CloseOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/purchase_orders';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class PurchaseOrdersEdit extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      loader: true,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage,
      tax_rates: [],
      marketplaces: []
   };

   // static getDerivedStateFromProps(props, state) {
   //    if (props.action === 'edit') {
   //       const formValues = {
   //          id: props.itemData.id,
   //          name: props.itemData.name.it,
   //          percentage: props.itemData.percentage,
   //          language: 'it'
   //       }
   //       return updateObject(state, { formValues: formValues });
   //       // state.initial_values.name = props.itemData.t_name;
   //       // state.initial_values.percentage = props.itemData.percentage;
   //    }

   //    return state;
   // }

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         parent_id: response.parent_id,
         parent_name: response.parent ? response.parent.name[lang] : 'Categoria di primo livello',
         internal_code: response.internal_code,
         name: response.name[lang],
         intro: response.intro[lang],
         description: response.description[lang],
         is_visible: response.visible[lang],
         id: response.id,
         language: lang
      }
      return values;
   }

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id;
         this.setState({ action: 'edit' });
         // asyncronous calls
         storeActions.getItem(itemId)
            .then(response => {
               console.log(response);
               try {
                  const updatedValues = this.setForm(response.data);
                  this.setState({ response: response.data, formValues: updatedValues, loader: false });

                  // const updatedItemList = updateObject(this.state.itemList, response);
                  // this.setState({itemList: updatedItemList});

                  //  const loadedData = response.data;

                  //  const newState = {...this.state};
                  //  newState.categoriestotals = loadedData;
                  //  this.setState(newState);
                  //  // this.props.showMessage({message: 'Categories totals loaded'});

               } catch (e) {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               }

            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
      else {
         this.setState({ loader: false });
      }

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   // onParentChange = value => {
   //    console.log(value);
   //    this.setState({ value });
   // };
   onCreateCategoryFinish = values => {
      console.log("Create category", values);
   }
   onCreateCategoryFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   onFinish = values => {
      console.log(values);
      // this.setState({ loader: true });
      // storeActions.createOrUpdate(values)
      //    .then(response => {
      //       console.log(response);
      //       try {
      //          if (this.state.saveAndClose) {
      //             this.props.history.goBack();
      //          }
      //          const updatedValues = this.setForm(response.data);
      //          this.setState({ response: response.data, formValues: updatedValues, loader: false });

      //          notification.open({
      //             message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
      //             description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
      //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //          })
      //       } catch (e) {

      //       }

      //    }).catch((e) => {
      //       this.setState({ loader: false });
      //       notification.open({
      //          message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
      //          description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
      //          icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      //       })
      //    });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   onVisibleSwitchChange = (checked) => {
      let newFormValues = { ...this.state.formValues }
      newFormValues.is_visible = checked;
      this.setState({ formValues: newFormValues });
   }

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   onCategoriesTreeChange = value => {
      console.log('onChange ', value);
      this.setState({ value });
   };

   onPriceSellNoVatChange = value => {
      console.log('onPriceSellNoVatChange ', value);
   };

   render() {
      if (!this.props.authState.userData.languages) return '';
      const mainLayout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
         layout: "vertical",
      };
      const tabMainLayout = {
         labelCol: { span: 6 },
         wrapperCol: { span: 20 },
      };
      const tabSideLayout = {
         labelCol: { span: 4 },
         wrapperCol: { span: 22 },
      };
      const { Title } = Typography;
      const { TextArea } = Input;
      const { Option } = Select;
      const { TabPane } = Tabs;
      const { SHOW_PARENT } = TreeSelect;
      const { RangePicker } = DatePicker;

      const categories_tree = {
         treeData: this.state.categories_tree,
         value: this.state.value,
         onChange: this.onCategoriesTreeChange,
         treeCheckable: true,
         showCheckedStrategy: SHOW_PARENT,
         placeholder: 'Please select',
         style: {
            width: '100%',
         },
         size: "large"
      };

      // const categories_tree = (
      //    <TreeSelect
      //       style={{ width: '100%' }}
      //       // value={this.state.value}
      //       dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      //       treeData={this.state.categories_tree}
      //       allowClear
      //       placeholder="Categoria di primo livello"
      //    // onChange={this.onChange}
      //    />)

      let form = (
         <Form
            {...mainLayout}
            id="createProduct"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Name"
               name="name"
               rules={[{ required: true, message: 'Inserisci il nome del prodotto' }]}
            >
               <Input size="large" />
            </Form.Item>
            <Form.Item
               label="Codice"
               name="internal_code"
               rules={[{ required: true, message: 'Inserisci il codice' }]}
            >
               <Input size="large" />
            </Form.Item>

            <Tabs type="card" size="large">
               <TabPane tab="Impostazioni di base" key="1">
                  <div className="row">
                     <div className="col-sm-8">
                        <Form.Item
                           {...tabMainLayout}
                           label="Intro"
                           name="intro"
                           rules={[{ message: 'Testo di introduzione' }]}
                        >
                           <TextArea
                              rows={2}
                           />
                        </Form.Item>

                        <Form.Item
                           {...tabMainLayout}
                           label="Descrizione"
                           name="description"
                           rules={[{ message: 'Inserisci la descrizione' }]}
                        >
                           <TextArea
                              rows={4}
                           />
                        </Form.Item>
                     </div>
                     <div className="col-sm-4">
                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.products.quantity')}</Title>
                        <Form.Item
                           name="quantity"
                           rules={[]}
                        >
                           <InputNumber step={1} />
                        </Form.Item>

                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.products.price')}</Title>
                        <Form.Item
                           label="Prezzo tasse escluse"
                           name="price_sell_no_tax"
                           rules={[]}
                        >
                           <InputNumber
                              size="large"
                              // style={"width= '100%'"}
                              formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/€\s?|(,*)/g, '')}
                              onChange={this.onPriceSellNoVatChange}
                           />
                        </Form.Item>
                        <Form.Item
                           label="Prezzo tasse incluse"
                           name="price_sell_tax"
                           rules={[]}
                        >
                           <InputNumber
                              size="large"
                              // style={"width= '100%'"}
                              formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/€\s?|(,*)/g, '')}
                              onChange={this.onPriceSellNoVatChange}
                           />
                        </Form.Item>
                        <Form.Item
                           label="Regime IVA"
                           name="tax_rate_id"
                           rules={[]}
                        >

                           <Select
                              showSearch
                              size="large"
                              // className="width-70px-I"
                              placeholder="Seleziona il regime IVA"
                              defaultValue={this.state.formValues.tax_rate_id}
                              optionFilterProp="children"
                              onChange={this.setTaxRateHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.state.tax_rates.map((tax) => {
                                    return <Option key={tax.id} value={tax.id}>{tax.name[this.state.componentLanguage]}</Option>
                                 })
                              }
                           </Select>

                        </Form.Item>

                        <Title level={4}>Seleziona la categoria</Title>
                        <Form.Item
                           {...tabSideLayout}
                           name="categories"
                        >
                           <TreeSelect {...categories_tree} />
                        </Form.Item>
                     </div>
                  </div>


               </TabPane>
               <TabPane tab="Prezzi" key="2">
                  <div className="row">
                     <div className="col-sm-6">

                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.products.sell_price')}</Title>
                        <Form.Item
                           label="Prezzo tasse escluse"
                           name="price_sell_no_tax"
                           rules={[]}
                        >
                           <InputNumber
                              size="large"
                              // style={"width= '100%'"}
                              formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/€\s?|(,*)/g, '')}
                              onChange={this.onPriceSellNoVatChange}
                           />
                        </Form.Item>
                        <Form.Item
                           label="Prezzo tasse incluse"
                           name="price_sell_tax"
                           rules={[]}
                        >
                           <InputNumber
                              size="large"
                              // style={"width= '100%'"}
                              formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/€\s?|(,*)/g, '')}
                              onChange={this.onPriceSellNoVatChange}
                           />
                        </Form.Item>
                        <Form.Item
                           label="Regime IVA"
                           name="tax_rate_id"
                           rules={[]}
                        >

                           <Select
                              showSearch
                              size="large"
                              // className="width-70px-I"
                              placeholder="Seleziona il regime IVA"
                              defaultValue={this.state.formValues.tax_rate_id}
                              optionFilterProp="children"
                              onChange={this.setTaxRateHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.state.tax_rates.map((tax) => {
                                    return <Option key={tax.id} value={tax.id}>{tax.name[this.state.componentLanguage]}</Option>
                                 })
                              }
                           </Select>

                        </Form.Item>

                     </div>
                     <div className="col-sm-6">

                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.products.buy_price')}</Title>
                        <Form.Item
                           label="Prezzo di acquisto (tasse escluse)"
                           name="price_buy_no_tax"
                           rules={[]}
                        >
                           <InputNumber
                              size="large"
                              // style={"width= '100%'"}
                              formatter={value => `€ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/€\s?|(,*)/g, '')}
                              onChange={this.onPriceBuyNoVatChange}
                           />
                        </Form.Item>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-sm-12">

                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.products.specific_prices')}</Title>

                     </div>
                  </div>
               </TabPane>
               <TabPane tab="Quantità" key="3">
                  <div className="row">
                     <div className="col-sm-12">

                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.products.quantity')}</Title>
                        <Form.Item
                           label="Quantità disponibile"
                           name="quantity"
                           rules={[]}
                        >
                           <InputNumber
                              size="large"
                              step={1}
                           />
                        </Form.Item>

                     </div>
                     <div className="col-sm-6">

                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.products.dedicated_quantity')}</Title>
                        <Form layout="inline">
                           <Form.Item
                              label="Marketplace"
                              name="marketplace_id"
                              rules={[]}
                           >
                              <Select
                                 showSearch
                                 size="large"
                                 // className="width-70px-I"
                                 placeholder="Seleziona il Marketplace"
                                 optionFilterProp="children"
                                 onChange={this.setMarketplaceHandler}
                              //  onFocus={onFocus}
                              //  onBlur={onBlur}
                              //  onSearch={onSearch}
                              //  filterOption={(input, option) =>
                              //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              //  }
                              >
                                 {
                                    this.state.marketplaces.map((marketplace) => {
                                       return <Option key={marketplace.id} value={marketplace.id}>{marketplace.name + '(' + marketplace.language + ')'}</Option>
                                    })
                                 }
                              </Select>
                           </Form.Item>
                        <Form.Item
                           label="Quantità dedicata"
                           name="dedicated_quantity"
                           rules={[]}
                        >
                           <InputNumber
                              size="large"
                              step={1}
                           />
                        </Form.Item>
            <Form.Item
               label="Quantità riservata"
            >
               
         <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={this.state.formValues.active}
         />
            </Form.Item>
                        <Form.Item
                           label="Seleziona date"
                           name="dates"
                           rules={[]}
                        >
                           <RangePicker />
                        </Form.Item>
                        </Form>
                     </div>
                  </div>
               </TabPane>
            </Tabs>

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="parent_id" name="parent_id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>


         </Form>
      );


      // const specific_prices_columns = [
      //    {
      //    title: 'Nazione',
      //    dataIndex: 'country',
      //    key: 'country'
      //    },
      //    {
      //       title: 'Ecommerce',
      //       dataIndex: 
      //    }
      // ]


      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.products.title')}
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">

                     <Space>
                        <Button
                           size='large'
                           icon={<LeftCircleOutlined />}
                           onClick={() => { this.props.history.goBack() }}
                        >Indietro</Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="createProduct" htmlType="reset">Annulla</Button>
                           <Button form="createProduct" type="primary" htmlType="submit">Salva</Button>
                           <Button form="createProduct" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );
      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);




      console.log(this.state);
      // this.props.action === 'edit' ? (
      //    initial_values = {
      //       name: this.props.itemData.t_name,
      //       percentage: this.props.itemData.percentage
      //    }) : null;

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.products.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(PurchaseOrdersEdit);