import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import { Card, Typography, Descriptions, Space, notification } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/products';

class AmazonDataBox extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        componentLanguage: this.props.authState.defaultLanguage,
        productAmazonData: {}
    }

    componentDidMount() {
        console.log(this.props)
        Promise.all([
            this.getProductAmazonData(this.props.productDataId),
        ]).then(() => {
            this.setState({
                componentLoader: false,
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (
            _.isEqual(nextProps.productDataId, this.props.productDataId)
            && _.isEqual(nextState, this.state)
        ) {
            return false
        }
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!_.isEqual(prevProps.productDataId, this.props.productDataId)) {
            this.setState({ componentLoader: true });
            Promise.all([
                this.getProductAmazonData(this.props.productDataId),
            ]).then(() => {
                this.setState({
                    componentLoader: false,
                });
            });
        }
    }

    getProductAmazonData = (productDataId) => {
        if (!_.isNil(productDataId)) {
            console.log(productDataId)
            return storeActions.getProductAmazonData(productDataId)
                .then(response => {
                    console.log(response);
                    try {
                        this.setState({
                            productAmazonData: response.data,
                        });
                    } catch (e) {
                        notification.open({
                            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                            description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                        })
                    }
                }).catch((e) => {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                });
        } else {
            return false
        }
    }

    render() {
        const { Title } = Typography;
        const amazonData = this.state.productAmazonData
        return (
            <Card loading={this.state.componentLoader || this.state.dataLoader}
                title={
                    <Space direction='vertical' size='small'>
                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.analysis.products.amazon_data.title')}</Title>
                    </Space>
                }
                bordered={true}
                className="card-analysis-data"
            >
                <Descriptions
                    bordered
                    size='small'
                    column={1}
                >
                    <Descriptions.Item label="ASIN">{amazonData.asin}</Descriptions.Item>
                    <Descriptions.Item label="MPN">{amazonData.mpn}</Descriptions.Item>
                    <Descriptions.Item label="Nome">{amazonData.title}</Descriptions.Item>
                    <Descriptions.Item label="Brand">{amazonData.brand}</Descriptions.Item>
                    {/* <Descriptions.Item label="Produttore">{amazonData.manufacurer}</Descriptions.Item> */}
                    <Descriptions.Item label="Descrizione">{amazonData.description}</Descriptions.Item>
                    <Descriptions.Item label="Prezzo di listino">{amazonData.list_price}</Descriptions.Item>
                    <Descriptions.Item label="Prezzo Amazon">{amazonData.amazon_price}</Descriptions.Item>
                    <Descriptions.Item label="Prezzo Marketplace">{amazonData.marketplace_price}</Descriptions.Item>
                    <Descriptions.Item label="Prezzo di listino (media 30gg)">{amazonData.avg30_list_price}</Descriptions.Item>
                    <Descriptions.Item label="Prezzo Amazon (media 30gg)">{amazonData.avg30_amazon_price}</Descriptions.Item>
                    <Descriptions.Item label="Prezzo Marketplace (media 30gg)">{amazonData.avg30_marketplace_price}</Descriptions.Item>
                    <Descriptions.Item label="Prezzo di listino (media 180gg)">{amazonData.avg180_list_price}</Descriptions.Item>
                    <Descriptions.Item label="Prezzo Amazon (media 180gg)">{amazonData.avg180_amazon_price}</Descriptions.Item>
                    <Descriptions.Item label="Prezzo Marketplace (media 180gg)">{amazonData.avg180_marketplace_price}</Descriptions.Item>
                    <Descriptions.Item label="Prezzo BuyBox">{amazonData.buybox_price}</Descriptions.Item>
                </Descriptions>
            </Card>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(AmazonDataBox)