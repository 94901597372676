import React from 'react';

const Footer = () => {

   return (
      <React.Fragment>
         <footer className="footer">
            <div className="row">
               <div className="col-12">
                  Made with <i className="mdi mdi-heart text-danger"></i> by NZT Solutions srl © {new Date().getFullYear()}
               </div>
            </div>
         </footer>
      </React.Fragment>
   );
}

export default Footer;