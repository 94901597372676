import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Drawer, Form, Input, Button, Space, notification, TreeSelect } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../../services/languageManager';
import * as storeActions from '../store/actions/categories';

class CreateCategoryDrawer extends Component {
   state = {
      ...this.props,
      componentLoader: true,
      formLoader: false,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   }
   formRef = React.createRef();

   componentDidMount() {
      Promise.all([
         this.getCategoriesTree()
      ]).then(() => {
         this.setState({
            componentLoader: false
         });
      });
   }

   componentDidUpdate = (prevProps, prevState) => {
      if (!_.isEqual(prevState.visible, this.props.visible)) {
         this.setState({ ...this.props })
      }
   }

   getCategoriesTree = () => {
      return storeActions.getTree()
         .then(response => {
            console.log(response);
            try {
               this.setState({ categories_tree: response.data });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   onFinish = values => {
      this.setState({ formLoader: true });
      values.visible = true;
      storeActions.createOrUpdate(values)
         .then(response => {
            try {
               Promise.all([
                  this.getCategoriesTree()
               ]).then(() => {
                  this.setState({
                     formLoader: false,
                  });
                  this.formRef.current.resetFields()
                  this.state.onSubmit(this.state.categories_tree)
               });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onClose = () => {
      // this.formRef.current.resetFields()
      this.state.onClose()
   }

   render() {
      console.log('Create category drawer', this.state)

      return (
         <Drawer
            title="Crea una nuova categoria"
            width={500}
            onClose={this.onClose}
            visible={this.state.visible}
            drawerStyle={{ paddingTop: 70 }}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
               <div className='float-right'>
                  <Space>
                     <Button onClick={this.onClose} disabled={this.state.formLoader}>Chiudi</Button>
                     <Button form="createCategoryDrawer" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                  </Space>
               </div>
            }
         >
            <Form layout="vertical"
               ref={this.formRef}
               id="createCategoryDrawer"
               initialValues={this.state.formValues}
               onFinish={this.onFinish}
               onFinishFailed={this.onFinishFailed}>
               <Form.Item
                  label="Categoria padre"
                  name="parent_id"
                  extra="Seleziona la categoria"
                  rules={[]}
               >
                  <TreeSelect
                     style={{ width: '100%' }}
                     dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                     treeData={this.state.categories_tree}
                     allowClear
                     placeholder="Categoria di primo livello"
                  />
               </Form.Item>
               <Form.Item
                  label="Nome"
                  name="name"
                  rules={[{ required: true, message: 'Inserisci il nome' }]}
               >
                  <Input />
               </Form.Item>
               <Form.Item label="language" name="language" initialValue={this.state.componentLanguage} noStyle><Input type="text" type="hidden" /></Form.Item>
            </Form>
         </Drawer>
      )
   }
}

function mapStateToProps(state) {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
}

export default connect(mapStateToProps)(CreateCategoryDrawer)