import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Input, Select, Switch, Button, Space, notification } from 'antd';
import { InfoCircleOutlined, CheckOutlined, CloseOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/catalogues';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class CataloguesEdit extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      loader: true,
      formValues: {},
      brandsList: [],
      componentLanguage: this.props.authState.defaultLanguage
   };

   componentDidMount() {
      storeActions.getCataloguesComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  response: response,
                  brandsList: response.brandsList,
               });

            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id;
         this.setState({ action: 'edit' });
         // asyncronous calls
         storeActions.getItem(itemId)
            .then(response => {
               console.log(response);
               try {
                  const updatedValues = {
                     code: response.data.code,
                     name: response.data.name,
                     description: response.data.description,
                     year: response.data.year,
                     season: response.data.season,
                     status: response.data.status,
                     id: response.data.id,
                     language: this.state.componentLanguage
                  }
                  this.setState({ formValues: updatedValues, loader: false });
               } catch (e) {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               }

            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage
         }
         this.setState({ formValues: formValues, action: 'insert', loader: false });
      }

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   onFinish = values => {
      this.setState({ loader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            this.setState({ loader: false });
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.push('/catalogues/list');
               }

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   // onReset = () => {
   //   form.resetFields();
   // };

   render() {
      if (!this.props.authState.userData.languages) return '';
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };
      const { TextArea } = Input;
      const { Option } = Select;

      let form = (
         <Form
            {...layout}
            id="createCatalogue"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Codice"
               name="code"
               rules={[{ required: true, message: 'Inserisci il codice' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Name"
               name="name"
               rules={[{ required: true, message: 'Inserisci il nome' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Brand"
               name="brand_id"
               rules={[{ required: true }]}
            >
               <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Seleziona il brand"
                  defaultValue={this.state.formValues.brand_id}
                  optionFilterProp="children"
               >
                  {
                     this.state.brandsList.map((brand) => {
                        return <Option key={brand.id} value={brand.id}>{brand.name}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            <Form.Item
               label="Anno"
               name="year"
               rules={[{ message: 'Inserisci l\'anno' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Stagione"
               name="season"
               rules={[{ message: 'Inserisci la stagione' }]}
            >
               <Input />
            </Form.Item>

            <Form.Item
               label="Status (Aperto/Chiuso)"
            >

               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={this.state.formValues.status}
               />
            </Form.Item>

            <Form.Item
               label="Descrizione"
               name="description"
               rules={[{ message: 'Inserisci la descrizione' }]}
            >
               <TextArea
                  rows={4}
               />
            </Form.Item>

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>

         </Form>
      );


      const pageContent = (
         <div className="card">
            <div className="card-header">{languageManager.getMessage(this.props.commonState, 'component.catalogues.title')}</div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           icon={<LeftCircleOutlined />}
                           onClick={() => { this.props.history.goBack() }}
                        >Indietro</Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="createCatalogue" htmlType="button" onClick={this.onReset}>Annulla</Button>
                           <Button form="createCatalogue" type="primary" htmlType="submit">Salva</Button>
                           <Button form="createCatalogue" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }
      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.catalogues.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(CataloguesEdit
);