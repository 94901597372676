import React, { Component } from 'react';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';

import Moment from 'moment';

import { Table, Select, Input, Button, Space, notification, Popconfirm } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/vendor_orders';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class VendorOrdersList extends Component {
   state = {
      loader: true,
      itemList: [],
      yearsList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage,
      searchYear: Moment().year(),
      poPaginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      }
   };

   getList = (searchYear, params) => {
      // asyncronous calls
      storeActions.getListExtended(searchYear, params)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data.data,
                  searchYear: searchYear,
                  poPaginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },
                  loader: false
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      storeActions.getVendorOrdersComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  complementsResponse: response,
                  yearsList: response.yearsList,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });

      this.getList(this.state.searchYear, this.state.poPaginationParams);
   }

   componentDidUpdate(prevProps, prevState) {
      // debugger;
      if (this.state.searchYear !== prevState.searchYear) {
         console.log('Component update');
         this.setState({ loader: true });
         this.getList(this.state.searchYear, this.state.poPaginationParams);
      }
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loader: true });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               this.getList(this.state.searchYear, this.state.poPaginationParams);
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            return;
      }
   }

   getColumnSearchProps = (dataIndex, title) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  this.searchInput = node;
               }}
               placeholder={`Cerca ${title}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
               >
                  Search
            </Button>
               <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                  Reset
            </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase().trim()) : '',
      onFilterDropdownVisibleChange: visible => {
         if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
         }
      },
      render: (text, record) =>
         this.state.searchedColumn === dataIndex ? (
            <Button className="link" onClick={() => this.props.history.push('/vendor_orders/details/' + record.id)}>
               <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[this.state.searchText.trim()]}
                  autoEscape
                  textToHighlight={record[dataIndex] ? record[dataIndex] : ''}
               />
            </Button>
         ) : (
               <Button className="link" onClick={() => this.props.history.push('/vendor_orders/details/' + record.id)}>
                  {record[dataIndex].toString()}
               </Button>
            ),
   });
   handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      this.setState({
         searchText: selectedKeys[0],
         searchedColumn: dataIndex,
      });
   };
   handleReset = clearFilters => {
      clearFilters();
      this.setState({ searchText: '' });
   };

   setSearchYear = (year) => {
      this.setState({ searchYear: year });
   }

   listPaginationChangeHandler = (page, pageSize) => {
      let listParams = {
         current_page: page,
         per_page: pageSize,
      }
      this.getList(this.state.searchYear, listParams);
   }

   render() {
      if (!this.props.authState.userData.languages) return '';
      console.log(this.state);
      const { Option } = Select;
      const table_columns = [
         // {
         //    title: 'Ordine',
         //    width: 150,
         //    key: 'purchase_order_number',
         //    fixed: 'left',
         //    sorter: (a, b) => a.purchase_order_number.localeCompare(b.purchase_order_number),
         //    ...this.getColumnSearchProps('purchase_order_number', 'ordine'),
         // },
         {
            title: 'Nome ordine',
            width: 150,
            key: 'name',
            fixed: 'left',
            sorter: (a, b) => a.name.localeCompare(b.name),
            ...this.getColumnSearchProps('name', 'name'),
         },
         {
            title: 'Data ordine',
            width: 120,
            key: 'order_date',
            fixed: 'left',
            render: (record) => (
               Moment(record.order_date).format('L')
            ),
            sorter: (a, b) => a.order_date.localeCompare(b.order_date),
         },
         {
            title: 'Brand',
            width: 250,
            key: 'brand',
            fixed: 'left',
            render: (record) => (
               record.brand ? record.brand.name : ''
            ),
            sorter: (a, b) => a.brand.name.localeCompare(b.brand.name),
            ellipsis: true,
         },
         {
            title: 'Articoli ordinati',
            width: 150,
            dataIndex: 'order_lines_count',
            key: 'order_lines_count',
            fixed: 'left',
            sorter: (a, b) => a.order_lines_count - b.order_lines_count,
         },
         {
            title: 'Quantità ordinate',
            width: 150,
            dataIndex: 'imported_quantity',
            key: 'imported_quantity',
            fixed: 'left',
            sorter: (a, b) => a.imported_quantity - b.imported_quantity,
         },
         // {
         //    title: 'Costo totale',
         //    width: 150,
         //    key: 'price_total',
         //    fixed: 'left',
         //    render: (record) => (
         //       record.price_total + ' €'
         //    ),
         //    sorter: (a, b) => a.price_total - b.price_total,
         // },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button className="link" onClick={() => this.props.history.push('/vendor_orders/details/' + record.id)} title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <i className="far fa-edit"></i>
                  </Button>
                  <Popconfirm
                     placement="topRight"
                     title="Cancelli?"
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText="Yes"
                     cancelText="No"
                  >
                     <Button className="link"><i className="far fa-trash-alt"></i></Button>
                  </Popconfirm>
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         pagination: {
            pageSize: this.state.poPaginationParams.per_page,
            onChange: this.listPaginationChangeHandler,
            total: this.state.poPaginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' ordini' : ' ordine')
         }
      }

      const yearSelect = (
         <Select
            showSearch
            size="large"
            placeholder="Seleziona l'anno"
            defaultValue={this.state.searchYear}
            optionFilterProp="children"
            onChange={this.setSearchYear}
         //  onFocus={onFocus}
         //  onBlur={onBlur}
         //  onSearch={onSearch}
         //  filterOption={(input, option) =>
         //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
         //  }
         >
            {
               this.state.yearsList.map((year) => {
                  return <Option key={year.year} value={year.year}>{year.year}</Option>
               })
            }
         </Select>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     {languageManager.getMessage(this.props.commonState, 'component.vendor_orders.title')}
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           <Button
                              size='large'
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/vendor_orders/edit/')}
                           >
                              Aggiungi ordine venditore
                           </Button>
                           <Button
                              size='large'
                              type='primary'
                              icon={<UploadOutlined />}
                              onClick={() => this.props.history.push('/vendor_orders/import/')}
                           >
                              Importa ordine venditore
                           </Button>
                           <Select
                              showSearch
                              size="large"
                              className="width-70px-I"
                              placeholder="Seleziona la lingua"
                              defaultValue={this.state.componentLanguage}
                              optionFilterProp="children"
                              onChange={this.setDefaultLanguageHandler}
                           //  onFocus={onFocus}
                           //  onBlur={onBlur}
                           //  onSearch={onSearch}
                           //  filterOption={(input, option) =>
                           //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                           //  }
                           >
                              {
                                 this.props.authState.userData.languages.map((lang) => {
                                    return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                 })
                              }
                           </Select>
                        </Space>
                     </div>
                  </div>
               </div>
               <div className="card-body">
                  {yearSelect}
                  {this.state.loader ? <Loader /> : <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} />}
               </div>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      // const editForm = (
      //    <div className="card">
      //       <div className="card-header">Tasse</div>
      //       <div className="card-body">
      //          <TaxRatesForm action={this.state.action} itemData={this.state.itemData} closeAction={() => this.closeActionHandler()} />
      //       </div>
      //    </div>
      // );

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.vendor_orders.title')}
               buttons={buttons}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(VendorOrdersList);