export enum OrdersStatsRangeEnum {
   DAILY,
   WEEKLY,
   MONTHLY, 
}

export enum OrderStatusEnum {
   NULL,
   WAITING_ACCEPTANCE,
   ACCEPTED_TOCHECK,
   UPDATED_TOCHECK,
   PROCESSING,
   REFUSED_TOCHECK,
   CLOSED,
   REFUSED,
   UNKNOWN
}

export enum ClosingOrderStatusEnum {
   NULL,
   TOSEND,
   TOCHECK,
   SENT,
}

export enum OrderStatusActionEnum {
   ACCEPT,
   ACCEPT_PARTIAL,
   REFUSE,
   PROCESS,
}

export enum ProductsImportFileCSVType {
   NULL,
   QUANTITIES,
   PRICES,
   IMAGES,
}
export enum ProductsAvailabilityEnum {
   OBSOLETE,
   IN_STOCK,
   TEMP_NOT_AVAILABLE,
}

export enum OrdersImportFileCSVType {
   NULL0,
   TRACKING,
}