import React, { Component } from 'react'
import { connect } from 'react-redux'

import languageManager from '../../services/languageManager'
import Loader from '../common/PageElements/Loader'

import { notification, Row, Col, Card, Typography, Select, Button, Space } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import * as storeActions from './store/actions/analysis'
import CategoryTree from '../catalogue/categories/widget/CategoryTree'
import CategoryAmazonDataBox from './widget/CategoryAmazonDataBox'
import CategoryAmazonBestSellersBox from './widget/CategoryAmazonBestSellersBox'

class Categories extends Component {
   state = {
      pageInit: true,
      pageLoader: true,
      componentLanguage: this.props.authState.defaultLanguage,

      categories_tree: [],
      actual_category: null,
   }

   getComplements = () => {
      // asyncronous calls
      return storeActions.getCategoriesComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  categories_tree: response.categoriesTree,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         // this.getOrdersChartData()
         this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   // getCategoryAnalysis = (categoryId) => {
   //    // asyncronous calls
   //    return storeActions.getCategoryAnalysis(categoryId)
   //       .then(response => {
   //          console.log(response);
   //          try {
   //             this.setState({
   //                categoryAmazonData: response.categoryAmazonData,
   //             });
   //          } catch (e) {
   //             notification.open({
   //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //                description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
   //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //             })
   //          }

   //       }).catch((e) => {
   //          notification.open({
   //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //             description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
   //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //          })
   //          console.log(e);
   //       });
   // }

   handleSelectCategory = (value) => {
      console.log(value)
      this.setState({ actual_category: value[0] })
      // this.setState({analysisLoader: true})
      // Promise.all([
      //    this.getCategoryAnalysis(value[0])
      // ]).then(() => {
      //    this.setState({
      //       analysisLoader: false,
      //       actual_category: value[0]
      //    });
      // });
   }



   render() {
      if (this.state.pageLoader) return <Loader />;
      const { Title } = Typography
      const { Option } = Select;
      const pageContent = (
         <div className="card">
            <div className="card-header">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.analysis.categories.title')}</Title>
                  </div>
                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">
                        <Space>
                           {
                              this.props.authState.userData.languages ? (
                                 <Select
                                    showSearch
                                    className="width-70px-I"
                                    placeholder="Seleziona la lingua"
                                    defaultValue={this.state.componentLanguage.toUpperCase()}
                                    optionFilterProp="children"
                                    onChange={this.setDefaultLanguageHandler}
                                 >
                                    {
                                       this.props.authState.userData.languages.map((lang) => {
                                          return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                                       })
                                    }
                                 </Select>
                              ) : ''
                           }
                        </Space>
                     </div>
                  </div>
               </div>
            </div>
            <div className="card-body">
               <Row gutter={[16, 16]}>
                  <Col span={6}>
                     <Card
                        title={
                           <Space direction='vertical' size='small'>
                              <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.categories.title')}</Title>
                           </Space>
                        }
                        size='small'
                        bordered={true}
                        className="card-analysis-data"
                     >
                        <CategoryTree
                           categoriesTree={this.state.categories_tree}
                           initialValue={this.state.actual_category}
                           onSelect={(value) => this.handleSelectCategory(value)}
                        />
                     </Card>
                  </Col>
                  <Col span={18}>
                     {
                        this.state.actual_category ? (
                           [
                              <CategoryAmazonDataBox
                                 categoryId={this.state.actual_category}
                              />,
                              <CategoryAmazonBestSellersBox
                                 categoryId={this.state.actual_category}
                              />,
                           ]
                        ) : 'Nessuna categoria selezionata'
                     }

                  </Col>
               </Row>
            </div>
            <div className="card-footer">
               <Button
                  size='large'
                  onClick={() => { this.props.history.goBack() }}
               >
                  <i className="mdi mdi-arrow-left-drop-circle-outline mr-1"></i>
                  {languageManager.getMessage(this.props.commonState, 'common.back')}
               </Button>
            </div>
         </div>
      )

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      )
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(Categories);