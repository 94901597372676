import Axios from 'axios';
import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';

class MarketplacesApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList() {
      console.log('Marketplaces GetList');

      return new Promise((resolve, reject) => {
         // fetchFunctions.get(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList, null, token)
         //    .then(response => {
         //       console.log(response);
         //       resolve(response);
         //    })
         //    .catch(error => {
         //       reject(error);
         //    });


         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getList)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
         // console.log(data);
         // resolve(data);
         // console.log(data);
         // data['success'] = true;
         // data['userId'] = 1;
         // data['token'] = 'xxxxxxxxx';
         // setTimeout(() => resolve(data), 500);


         // axios.post(API_BASE.url + API_CONFIG.appUrls.loginUrl, userData)
         // .then(response => {
         //     console.log(response);
         //     // if(response.data.data.id) {
         //     //     console.log(response.data.data);
         //     //     let user = {
         //     //         uuid    : response.data.data.id,
         //     //         role    : "admin",
         //     //         data    : {
         //     //             'displayName': response.data.data.firstname + ' ' + response.data.data.lastname,
         //     //             'photoURL'   : 'assets/images/avatars/Abbott.jpg',
         //     //             'email'      : response.data.data.email,
         //     //             settings     : {
         //     //                 layout          : {
         //     //                     style : 'layout1',
         //     //                     config: {
         //     //                         footer : {
         //     //                             display : true,
         //     //                             style   : 'fixed',
         //     //                             position: 'below'
         //     //                         }
         //     //                     }
         //     //                 }
         //     //             },
         //     //             data         : response.data.data,
         //     //             shortcuts    : []
         //     //         }
         //     //     };
         //     //     resolve(user);
         //     // }
         //     // else {
         //     //     this.logout();
         //     //     reject('Failed to login with token.');
         //     // }
         // })
         // .catch(error => {
         //     // this.logout();
         //     reject('Failed to login with token.');
         // });


      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getListForListedProducts() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getListForListedProducts)
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {

      return new Promise((resolve, reject) => {
         // fetchFunctions.get(API_BASE.url + API_CONFIG.appUrls.marketplaces.getItem, {id: itemId}, token)
         //    .then(response => {
         //       console.log(response);
         //       resolve(response);
         //    })
         //    .catch(error => {
         //       reject(error);
         //    });


         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getItem, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getConfig(itemId) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getConfig, {
            id: itemId
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static setConfig(itemId, itemData) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.setConfig, {
            id: itemId,
            config: JSON.stringify(itemData)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static connectionTest(marketplaceId, test) {

      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.connectionTest, {
            marketplace_id: marketplaceId,
            action: test,
         })
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getMarketplaceComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplacesCountries.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplacesLanguages.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplacesCurrencies.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.getBasesList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.vendorCodes.getList),
         ])
            // .then(responseArr => {
            //    console.log(responseArr);
            //    resolve(responseArr);
            // })
            .then(Axios.spread((countriesList, languagesList, currenciesList, marketplacesBaseList, vendorCodesList) => {
               const response = {
                  countriesList: countriesList,
                  languagesList: languagesList,
                  currenciesList: currenciesList,
                  marketplacesBaseList: marketplacesBaseList,
                  vendorCodesList: vendorCodesList,
               }
               resolve(response);
             }))
            .catch(error => {
               reject(error);
            });
      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData, ) {
      return new Promise((resolve, reject) => {
         // fetchFunctions.get(API_BASE.url + API_CONFIG.appUrls.marketplaces.getItem, {id: itemId}, token)
         //    .then(response => {
         //       console.log(response);
         //       resolve(response);
         //    })
         //    .catch(error => {
         //       reject(error);
         //    });


         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.deleteItem, {
            id: itemId,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static toggleActive(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplaces.toggleActive, {
            id: itemId,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });

      });
   }
}

export default MarketplacesApi;