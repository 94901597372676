import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class TaxRatesApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getList() {

      return new Promise((resolve, reject) => {
         // fetchFunctions.get(API_BASE.url + API_CONFIG.appUrls.taxRates.getList, null, token)
         //    .then(response => {
         //       console.log(response);
         //       resolve(response);
         //    })
         //    .catch(error => {
         //       reject(error);
         //    });


         Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.getList)
         .then(response => {
            console.log(response);
            resolve(response);
         })
         .catch(error => {
            reject(error);
         });
         // console.log(data);
         // resolve(data);
         // console.log(data);
         // data['success'] = true;
         // data['userId'] = 1;
         // data['token'] = 'xxxxxxxxx';
         // setTimeout(() => resolve(data), 500);


         // axios.post(API_BASE.url + API_CONFIG.appUrls.loginUrl, userData)
         // .then(response => {
         //     console.log(response);
         //     // if(response.data.data.id) {
         //     //     console.log(response.data.data);
         //     //     let user = {
         //     //         uuid    : response.data.data.id,
         //     //         role    : "admin",
         //     //         data    : {
         //     //             'displayName': response.data.data.firstname + ' ' + response.data.data.lastname,
         //     //             'photoURL'   : 'assets/images/avatars/Abbott.jpg',
         //     //             'email'      : response.data.data.email,
         //     //             settings     : {
         //     //                 layout          : {
         //     //                     style : 'layout1',
         //     //                     config: {
         //     //                         footer : {
         //     //                             display : true,
         //     //                             style   : 'fixed',
         //     //                             position: 'below'
         //     //                         }
         //     //                     }
         //     //                 }
         //     //             },
         //     //             data         : response.data.data,
         //     //             shortcuts    : []
         //     //         }
         //     //     };
         //     //     resolve(user);
         //     // }
         //     // else {
         //     //     this.logout();
         //     //     reject('Failed to login with token.');
         //     // }
         // })
         // .catch(error => {
         //     // this.logout();
         //     reject('Failed to login with token.');
         // });


      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static getItem(itemId) {

      return new Promise((resolve, reject) => {
         // fetchFunctions.get(API_BASE.url + API_CONFIG.appUrls.taxRates.getItem, {id: itemId}, token)
         //    .then(response => {
         //       console.log(response);
         //       resolve(response);
         //    })
         //    .catch(error => {
         //       reject(error);
         //    });


         Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.getItem, {
            id: itemId
         })
         .then(response => {
            console.log(response);
            resolve(response);
         })
         .catch(error => {
            reject(error);
         });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         // fetchFunctions.get(API_BASE.url + API_CONFIG.appUrls.taxRates.getItem, {id: itemId}, token)
         //    .then(response => {
         //       console.log(response);
         //       resolve(response);
         //    })
         //    .catch(error => {
         //       reject(error);
         //    });


         Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.createOrUpdate, {
            ...itemData
         })
         .then(response => {
            console.log(response);
            resolve(response);
         })
         .catch(error => {
            reject(error);
         });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static deleteItem(itemId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.deleteItem, {
            id: itemId,
            return_list: return_list
         })
         .then(response => {
            console.log(response);
            resolve(response);
         })
         .catch(error => {
            reject(error);
         });

      });
   }

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static toggleActive(itemData,) {
      return new Promise((resolve, reject) => {
         // fetchFunctions.get(API_BASE.url + API_CONFIG.appUrls.taxRates.getItem, {id: itemId}, token)
         //    .then(response => {
         //       console.log(response);
         //       resolve(response);
         //    })
         //    .catch(error => {
         //       reject(error);
         //    });


         Axios.post(API_BASE.url + API_CONFIG.appUrls.taxRates.toggleActive, {
            ...itemData
         })
         .then(response => {
            console.log(response);
            resolve(response);
         })
         .catch(error => {
            reject(error);
         });

      });
   }
}

export default TaxRatesApi;