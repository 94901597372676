import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Table, Drawer, Form, Input, InputNumber, Button, Space, notification, Select, Switch } from 'antd'
import { InfoCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import languageManager from '../../../../services/languageManager'
import * as storeActions from '../store/actions/searchproducts'

import Loader from '../../../common/PageElements/Loader'
import AmazonDataModal from '../../products/widget/AmazonDataModal'

class LookupResultsDrawer extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        dataLoader: false,
        productsList: [],
        showProductAmazonDataModal: false,
        selectedProductAmazonDataId: null,
        componentLanguage: this.props.authState.defaultLanguage
    }

    componentDidMount() {
        Promise.all([
            this.getLookupResults(this.props.item),
        ]).then(() => {
            this.setState({
                componentLoader: false
            });
        });
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // console.log('Component should update', nextProps, this.props, nextState, this.state)
        if (
            _.isEqual(nextProps.visible, this.props.visible)
            && _.isEqual(nextProps.item, this.props.item)
            && _.isEqual(nextState, this.state)
        ) {
            console.log('DO NOT UPDATE')
            return false
        }
        console.log('DO UPDATE')
        return true
    }

    componentDidUpdate = (prevProps, prevState) => {
        console.log(this.props.item)
        if (!_.isEqual(prevProps.visible, this.props.visible)) {
            if (_.isNil(this.props.item)) {
                this.setState({ productsList: [] });
            } else {
                // this.setState({ item: this.props.item });
                this.setState({ dataLoader: true });
                Promise.all([
                    this.getLookupResults(this.props.item),
                ]).then(() => {
                    this.setState({
                        dataLoader: false
                    });
                });
            }
        }
    }

    getLookupResults = (item) => {
        console.log('getLookupResults', item)
        if(_.isNil(item)) return false
        return storeActions.getLookupResults(item.type, item.data)
            .then(response => {
                console.log(response);
                try {
                    this.setState({
                        productsList: response.data,
                    });
                } catch (e) {
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                    if (this.props.commonState.debug) console.log(e);
                }

            }).catch((e) => {
                notification.open({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
                if (this.props.commonState.debug) console.log(e);
            });
    }

    showProductAmazonData = (productId) => {
        console.log('showProductAmazonData: ' + productId)
        if (productId) {
            this.setState({
                selectedProductAmazonDataId: productId,
                showProductAmazonDataModal: true,
            })
        }
    }
    hideProductAmazonDataModal = () => {
        this.setState({
            selectedProductAmazonDataId: null,
            showProductAmazonDataModal: false,
        })
    }

    render() {
        const lookupProductsColumns = [
            {
                title: 'EAN',
                key: 'ean',
                width: 100,
                fixed: 'left',
                render: (record) => (
                    record.ean
                ),
            }, {
                title: 'ASIN',
                key: 'asin',
                width: 100,
                render: (record) => (
                    record.asin
                ),
            },
            {
                title: 'Prodotto',
                key: 'title',
                render: (record) => (
                    <a onClick={() => this.showProductAmazonData(record.product_id)}>{record.title}</a>
                ),
                sorter: (a, b) => a.title - b.title,
            },
        ];

        return (
            <Drawer
                title={'Risultati ricerca'}
                width={800}
                onClose={this.props.onClose}
                visible={this.props.visible}
                drawerStyle={{ paddingTop: 70 }}
                bodyStyle={{ paddingBottom: 80 }}
                footer={
                    <div className='float-right'>
                        <Space>
                            <Button onClick={this.props.onClose} disabled={this.state.saveLoader}>Chiudi</Button>
                            {/* <Button form="listRuleDrawer" type="primary" htmlType="submit" loading={this.state.saveLoader}>Salva</Button> */}
                        </Space>
                    </div>
                }
            >
                {
                    this.state.componentLoader ? <Loader /> : (
                        <Table
                            key='lookup_results'
                            size='small'
                            loading={this.state.dataLoader}
                            columns={lookupProductsColumns}
                            dataSource={this.state.productsList}
                            bordered={true}
                            pagination={false}
                        ></Table>
                    )
                }
                <AmazonDataModal
                    visible={this.state.showProductAmazonDataModal}
                    productDataId={this.state.selectedProductAmazonDataId}
                    onClose={() => this.hideProductAmazonDataModal()}
                />
            </Drawer>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(LookupResultsDrawer)