import _ from 'lodash';

class languageManager {

    static getMessage(commonState, id, defaultValue) {

        if (_.isNil(commonState.messages))
            console.error('Common State not contains messages!');

        try {
            const message = commonState.messages[id];
            if(!_.isNil(message))
                return message;

            return defaultValue ? defaultValue : id;
        } catch (ex) {
            return defaultValue ? defaultValue : id;
        }
    }

    static getAvailableLanguages(commonState) {
        return ['it-IT', 'en-US'];
    }

    static getDefaultLanguage() {
        return {};
    }
}

export default languageManager;
