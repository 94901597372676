import React from 'react';
import { TreeSelect } from 'antd';

const SelectCategorySingle = (props) => {
   console.log(props);
   return (
      <TreeSelect
         style={{ width: '100%' }}
         // value={this.state.value}
         dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
         treeData={props.categories_tree}
         allowClear
         placeholder={props.placeholder}
      // onChange={this.onChange}
      />
   );
}

export default SelectCategorySingle;