import DataProcessingApi from '../../../../api/DataProcessingApi';
// import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function processGroupSizes(formData) {
   return DataProcessingApi.processGroupSizes(formData)
      .then(response => {
         console.log(response);
         let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = formData.export_file_name;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}

export function searchProductsByAsin(formData) {
   return DataProcessingApi.searchProductsByAsin(formData)
      .then(response => {
         console.log(response);
         let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = formData.export_file_name;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}

export function searchAsinsInOrders(formData) {
   return DataProcessingApi.searchAsinsInOrders(formData)
      .then(response => {
         console.log(response);
         let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = formData.export_file_name;
         link.click();
      })
      .catch(error => {
         throw error;
      });
}

export function searchProductsByCode(filename, formData) {
   return DataProcessingApi.searchProductsByCode(formData)
      .then(response => {
         console.log(response);
         let blob = new Blob([response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
         return true;
      })
      .catch(error => {
         throw error;
      });
}