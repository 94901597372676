import DashboardApi from '../../../../api/DashboardApi';

export function getOrderData(startDate, endDate) {
   return DashboardApi.getOrderData(startDate, endDate)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error('Errore nel retrieve dei dati di getOrderData.');
      })
      .catch(error => {
         throw error;
      });
}

export function getChannelData(startDate, endDate) {
   return DashboardApi.getChannelData(startDate, endDate)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            return response.data;
         }
         else
            throw new Error('Errore nel retrieve dei dati di getChannelData.');
      })
      .catch(error => {
         throw error;
      });
}

export function getProductData(startDate, endDate) {
   return DashboardApi.getProductData(startDate, endDate)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            return response.data;
         }
         else
            throw new Error('Errore nel retrieve dei dati di getProductData.');
      })
      .catch(error => {
         throw error;
      });
}

export function getOrderTotals() {
   return DashboardApi.getOrderTotals()
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error('Errore nel retrieve dei dati di getOrderTotals.');
      })
      .catch(error => {
         throw error;
      });
}

export function getOrderTotalsDetails() {
   return DashboardApi.getOrderTotalsDetails()
      .then(response => {
         if (response.data.success === true) {
            // return response;
            return response.data;
         }
         else
            throw new Error('Errore nel retrieve dei dati di getOrderTotalsDetails.');
      })
      .catch(error => {
         throw error;
      });
}

export function getOrdersChartData(year) {
   return DashboardApi.getOrdersChartData(year)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getOrdersStatsData(range) {
   return DashboardApi.getOrdersStatsData(range)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function getProductsStatsData(range) {
   return DashboardApi.getProductsStatsData(range)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}