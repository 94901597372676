import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class ChargebacksApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static importChargebacks(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.chargebacks.importFile, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default ChargebacksApi;