import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';

import languageManager from '../../../services/languageManager';
import { startLoading, endLoading } from '../../../shared/utility';
import * as storeActions from '../store/actions/dashboard';

import { Button, Space, notification, Row, Col, Divider, Typography, Card, Layout } from 'antd';
import { InfoCircleOutlined, CloseCircleTwoTone, WarningTwoTone, ContactsOutlined } from '@ant-design/icons';

class OrdersPerMonth extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        yearChangeLoader: false,
        yearsLoader: [],
        ordersChartData: [],
        ordersChartDataByDayAverage: [],
        orderValuesChartData: [],
        bestSellingItem: {},
        yearsSelection: [new Date().getFullYear()],
        selectedYear: new Date().getFullYear(),
    }

    componentDidMount() {
        Promise.all([
            this.getOrdersChartData(),
            // this.getCountOrdersBoxData(),
            this.prepareYearSelection(),
        ]).then(() => {
            this.setState({
                componentLoader: false,
                yearChangeLoader: false,
            });
        });
    }

    getOrdersChartData = (year = null) => {
        this.setState({ yearsLoader: startLoading(this.state.yearsLoader, year) });
        // asyncronous calls
        return storeActions.getOrdersChartData(year)
            .then(response => {
                console.log(response);
                try {
                    var newOrdersChartData = []
                    var data = {}
                    data.name = languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_chart.tooltip_title')
                    data.data = response.data.orders_per_month
                    newOrdersChartData.push(data)

                    var newOrderValuesChartData = []
                    data = {}
                    data.name = languageManager.getMessage(this.props.commonState, 'component.dashboard.order_values_chart.tooltip_title')
                    data.data = response.data.orders_value_per_month
                    newOrderValuesChartData.push(data)

                    var newOrdersChartDataByDayAverage = []
                    var data = {}
                    data.name = languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_by_day_chart.tooltip_title')
                    data.data = response.data.orders_per_month_by_day_average
                    newOrdersChartDataByDayAverage.push(data)

                    this.setState({
                        yearsLoader: endLoading(this.state.yearsLoader, year),
                        ordersChartData: newOrdersChartData,
                        ordersChartDataByDayAverage: newOrdersChartDataByDayAverage,
                        orderValuesChartData: newOrderValuesChartData,
                        countOrders: response.data.count_orders,
                        ordersPerDay: response.data.orders_per_day,
                        totalOrdersValue: response.data.total_orders_value,
                        bestSellingItem: response.data.best_selling_item,
                    });
                } catch (e) {
                    this.setState({ yearsLoader: endLoading(this.state.yearsLoader, year) });
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                }

            }).catch((e) => {
                this.setState({ yearsLoader: endLoading(this.state.yearsLoader, year) });
                notification.open({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
                console.log(e);
            });
    }

    // getCountOrdersBoxData = () => {
    //     // asyncronous calls
    //     storeActions.getCountOrdersBoxData()
    //         .then(response => {
    //             console.log(response);
    //             try {
    //                 var newCountOrdersBoxData = []
    //                 var data = {}
    //                 data.name = languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_chart.title')
    //                 data.data = response.data.orders_per_month
    //                 newOrdersChartData.push(data)
    //                 // var newOrdersValueChartData = {}
    //                 // newOrdersValueChartData.name = languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_chart.title')
    //                 // newOrdersValueChartData.data = response.data.orders_value_per_month
    //                 this.setState({
    //                     countOrdersBoxData: newCountOrdersBoxData,
    //                     // ordersValueChartData: newOrdersValueChartData,
    //                 });
    //             } catch (e) {
    //                 notification.open({
    //                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
    //                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
    //                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
    //                 })
    //             }

    //         }).catch((e) => {
    //             notification.open({
    //                 message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
    //                 description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
    //                 icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
    //             })
    //             console.log(e);
    //         });
    // }

    prepareYearSelection = () => {
        var current_year = new Date().getFullYear()
        var first_year = current_year - 2
        var years = []
        for (var i = first_year; i <= current_year; i++) {
            years.push(i)
        }
        this.setState({ yearsSelection: years })
        return true
    }

    selectedYearChangeHandler = (year) => {
        this.setState({ yearChangeLoader: true })
        Promise.all([
            this.getOrdersChartData(year)
        ]).then(() => {
            console.log('Year change end')
            this.setState({
                yearChangeLoader: false,
                selectedYear: year
            });
        });
    }

    render() {
        const { Title } = Typography;
        const ordersChartDataOptions = {
            chart: {
                type: 'area',
                height: '250px',
                background: 'transparent',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            theme: {
                mode: 'dark'
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                tooltip: {
                    enabled: false
                },
                axisBorder: {
                    show: false
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                }
            },
            markers: {
                colors: ['#22d3ee'],
                size: 3,
                strokeWidth: 1.5,
                strokeOpacity: 1,
                strokeDashArray: 0,
                fillOpacity: 1,
                shape: 'circle',
                radius: 2,
                hover: {
                    size: 5
                }
            },
            fill: {
                colors: ['#22d3ee'],
                type: 'solid',
                opacity: 0.7,
            },
            grid: {
                show: true,
                borderColor: 'rgba(255,255,255,0.3)',
                strokeDashArray: 3,
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                padding: {
                    top: 0,
                    right: 5,
                    bottom: 0,
                    left: 5
                }
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                width: 1.5,
                dashArray: 0
            }
        }
        const countOrdersBoxChartOptions = {
            chart: {
                type: 'area',
                height: '100px',
                sparkline: {
                    enabled: true
                }
            },
            fill: {
                type: 'solid',
                opacity: 0.7
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            },
            tooltip: {
                followCursor: true,
                theme: 'dark',
                fixed: {
                    enabled: false,
                    position: 'topRight',
                    offsetX: 0,
                    offsetY: 0,
                },
            }
        }
        const orderValuesChartDataOptions = {
            chart: {
                type: 'area',
                height: '250px',
                background: 'transparent',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            theme: {
                mode: 'dark'
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                tooltip: {
                    enabled: false
                },
                axisBorder: {
                    show: false
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                }
            },
            markers: {
                colors: ['#ffff00'],
                size: 3,
                strokeWidth: 1.5,
                strokeOpacity: 1,
                strokeDashArray: 0,
                fillOpacity: 1,
                shape: 'circle',
                radius: 2,
                hover: {
                    size: 5
                }
            },
            fill: {
                colors: ['#ffff00'],
                type: 'solid',
                opacity: 0.7,
            },
            grid: {
                show: true,
                borderColor: 'rgba(255,255,255,0.3)',
                strokeDashArray: 3,
                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true
                    }
                },
                padding: {
                    top: 0,
                    right: 5,
                    bottom: 0,
                    left: 5
                }
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                width: 1.5,
                dashArray: 0
            }
        }

        return (
            <section>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <Card loading={this.state.componentLoader}
                            title={
                                <Space direction='vertical' size='small'>
                                    <Title level={4} className='color-white-I'>{languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_chart.title')}</Title>
                                </Space>
                            }
                            extra={
                                <Space size='small'>
                                    {
                                        this.state.yearsSelection.map((year) => {
                                            return (
                                                <Button
                                                    key={year}
                                                    type={year === this.state.selectedYear ? 'primary' : 'default'}
                                                    className={year === this.state.selectedYear ? 'cursor-default-I' : ''}
                                                    onClick={year === this.state.selectedYear ? false : () => this.selectedYearChangeHandler(year)}
                                                    disabled={this.state.yearChangeLoader && !this.state.yearsLoader[year]}
                                                    loading={this.state.yearsLoader[year]}
                                                >
                                                    {year.toString()}
                                                </Button>
                                            )
                                        })
                                    }
                                </Space>
                            }
                            bordered={false}
                            style={{
                                background: '#333547',
                            }}
                            className="full-page-content text-white"
                        >
                            <ReactApexChart
                                options={ordersChartDataOptions}
                                series={this.state.ordersChartData}
                                type={ordersChartDataOptions.chart.type}
                                height={ordersChartDataOptions.chart.height}
                            />
                        </Card >
                    </Col>
                </Row>
                {/* <Row gutter={[24, 24]}>
                    <Col span={6} className='display-flex-I'>
                        <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                            <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_chart.box_title')}</Title>
                            <Title level={1}>{this.state.countOrders}</Title>
                            <div className='chart-in-card'>
                                <ReactApexChart
                                    options={countOrdersBoxChartOptions}
                                    series={this.state.ordersChartData}
                                    type={countOrdersBoxChartOptions.chart.type}
                                    height={countOrdersBoxChartOptions.chart.height}
                                />
                            </div>
                        </Card >
                    </Col>
                    <Col span={6} className='display-flex-I'>
                        <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                            <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.orders_by_day_chart.box_title')}</Title>
                            <Title level={1}>{this.state.ordersPerDay}</Title>
                            <div className='chart-in-card'>
                                <ReactApexChart
                                    options={countOrdersBoxChartOptions}
                                    series={this.state.ordersChartDataByDayAverage}
                                    type={countOrdersBoxChartOptions.chart.type}
                                    height={countOrdersBoxChartOptions.chart.height}
                                />
                            </div>
                        </Card >
                    </Col>
                    <Col span={6} className='display-flex-I'>
                        <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                            <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.order_values_chart.box_title')}</Title>
                            <Title level={1}>{this.state.totalOrdersValue} €</Title>
                            <div className='chart-in-card'>
                                <ReactApexChart
                                    options={countOrdersBoxChartOptions}
                                    series={this.state.orderValuesChartData}
                                    type={countOrdersBoxChartOptions.chart.type}
                                    height={countOrdersBoxChartOptions.chart.height}
                                />
                            </div>
                        </Card >
                    </Col>
                    <Col span={6} className='display-flex-I'>
                        <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                            <Title level={4}>Prodotto più venduto</Title>
                            <Title level={1}>{this.state.bestSellingItem.name}</Title>
                            <Title level={4}>{this.state.bestSellingItem.cnt ? this.state.bestSellingItem.cnt + ' ordini' : ''}</Title>
                        </Card >
                    </Col>
                </Row>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <Card loading={this.state.componentLoader}
                            title={
                                <Space direction='vertical' size='small'>
                                    <Title level={4} className='color-white-I'>{languageManager.getMessage(this.props.commonState, 'component.dashboard.order_values_chart.title')}</Title>
                                </Space>
                            }
                            extra={
                                <Space size='small'>
                                    {
                                        this.state.yearsSelection.map((year) => {
                                            return (
                                                <Button
                                                    key={year}
                                                    type={year === this.state.selectedYear ? 'primary' : 'default'}
                                                    className={year === this.state.selectedYear ? 'cursor-default-I' : ''}
                                                    onClick={year === this.state.selectedYear ? false : () => this.selectedYearChangeHandler(year)}
                                                    disabled={this.state.yearChangeLoader && !this.state.yearsLoader[year]}
                                                    loading={this.state.yearsLoader[year]}
                                                >
                                                    {year.toString()}
                                                </Button>
                                            )
                                        })
                                    }
                                </Space>
                            }
                            bordered={false}
                            style={{
                                background: '#333547',
                            }}
                            className="full-page-content text-white"
                        >
                            <ReactApexChart
                                options={orderValuesChartDataOptions}
                                series={this.state.orderValuesChartData}
                                type={orderValuesChartDataOptions.chart.type}
                                height={orderValuesChartDataOptions.chart.height}
                            />
                        </Card >
                    </Col>
                </Row> */}
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(OrdersPerMonth)