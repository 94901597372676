import React, { Component } from 'react';
import { connect } from 'react-redux';

import languageManager from '../../services/languageManager';
import Loader from '../common/PageElements/Loader';

import { notification, Row, Col } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import OrdersPerMonth from './charts/OrdersPerMonth'
import OrdersStats from './charts/OrdersStats'
import ProductsStats from './charts/ProductsStats'
import * as storeActions from './store/actions/dashboard';

class DashboardNew extends Component {
   state = {
      pageInit: true,
      pageLoader: true,
      componentLanguage: this.props.authState.defaultLanguage,

      best_selling_item: {},
      best_selling_item_per_value: {},
      totals: {},
      count_orders: 0,
      total_value: 0.00,
      avg_value: 0.00,

      lineData: {},
      valuesData: {},
   }

   getDashboardData = (startDate = null, endDate = null) => {
      // asyncronous calls
      return storeActions.getOrderData(startDate, endDate)
         .then(response => {
            console.log(response);
            try {
               // let updatedFilters = []
               // for (let filter of response.list_filters) {
               //    if (filter.type === 'date') {
               //       if (filter.value) {
               //          filter.value = Moment(filter.value, 'YYYY-MM-DD');
               //       }
               //    }
               //    updatedFilters.push(filter);
               // }

               let newLineData = {
                  labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
                  datasets: [
                     {
                        backgroundColor: '#1ac6ff',
                        borderColor: '#61dafb',
                        data: response.data.orders_per_month,
                        fill: true,
                        pointBackgroundColor: '#33c2f8',
                        pointBorderColor: 'rgba(0, 0, 0, 0.87)',
                        pointHoverBackgroundColor: '#61dafb',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0.87)'
                     }
                  ]
               };

               let newValuesData = {
                  labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
                  datasets: [
                     {
                        backgroundColor: '#1ac6ff',
                        borderColor: '#61dafb',
                        data: response.data.order_values_per_month,
                        fill: true,
                        pointBackgroundColor: '#33c2f8',
                        pointBorderColor: 'rgba(0, 0, 0, 0.87)',
                        pointHoverBackgroundColor: '#61dafb',
                        pointHoverBorderColor: 'rgba(0, 0, 0, 0.87)'
                     }
                  ]
               };
               // let newLineData = this.state.lineData;
               // newLineData.datasets.data = response.data.orders_per_month;
               // console.log(newLineData);


               this.setState({
                  lineData: newLineData,
                  valuesData: newValuesData,
                  best_selling_item: response.data.best_selling_item,
                  best_selling_item_per_value: response.data.best_selling_item_per_value,
                  count_orders: response.data.count_orders,
                  total_value: response.data.total_value,
                  avg_value: response.data.avg_value,
                  loader: false
               });
               console.log(this.state);
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         // this.getOrdersChartData()
         // this.getDashboardData()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }



   render() {
      const pageContent = (
         this.state.pageLoader ? <Loader /> : (
            <Row>
               <Col span={24}>
                  <OrdersPerMonth
                  // componentLoader={this.state.pageLoader}
                  />
                  <OrdersStats />
                  <ProductsStats />
               </Col>
            </Row>
         )
      );

      return (
         pageContent
      )
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(DashboardNew);