import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import API_BASE from '../../../api/apiBase';
import API_CONFIG from '../../../api/apiConfig'

import { Select, Button, Space, notification, Row, Col, Typography, Alert, List } from 'antd';
import { Upload, message, Form, Input, Switch } from 'antd';
import { InboxOutlined, InfoCircleOutlined, DoubleLeftOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/products';
import * as brandsActions from '../brands/store/actions/brands';
import * as vendorCodesActions from '../../configuration/vendor_codes/store/actions/vendor_codes';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class ProductsImport extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      formValues: {},
      selectedFileType: null,
      brandsList: [],
      vendorCodesList: [],
      componentLanguage: this.props.authState.defaultLanguage,
      tax_rates: [],
      marketplaces: [],
      import_type: [
         {
            id: 1,
            name: 'File Prodotti per RIA'
         },
         {
            id: 2,
            name: 'Aggiornamento Immagini'
         },
         {
            id: 3,
            name: 'Aggiornamento Prezzi'
         },
         {
            id: 5,
            name: 'Aggiornamento Prezzi WHLS'
         },
         {
            id: 6,
            name: 'Aggiornamento Prezzi WHLS da MPN'
         },
         {
            id: 7,
            name: 'Aggiornamento Disponibilità da ASIN'
         },
         {
            id: 8,
            name: 'Associazione ASIN a Vendor Code'
         },
      ]
   };

   componentDidMount() {
      const formValues = {
         language: this.state.componentLanguage
      }
      Promise.all([
         this.getBrandsList(),
         this.getVendorCodesList()
      ]).then(() => {
         this.setState({
            formValues: formValues,
            pageLoader: false
         });
      });
   }

   getBrandsList = () => {
      return brandsActions.getList()
         .then(response => {
            console.log(response);
            this.setState({ brandsList: response.data });

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   getVendorCodesList = () => {
      return vendorCodesActions.getList()
         .then(response => {
            console.log(response);
            this.setState({ vendorCodesList: response.data });

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   setFileType = (value) => {
      console.log(value)
      this.setState({ selectedFileType: value })
   }

   onFinish = values => {
      console.log(values);
      this.setState({ formLoader: true });
      const data = {};
      data.language = values.language;
      data.file_path = values.fileImport[0].response.data.file_path;
      data.file_name = values.fileImport[0].response.data.file_name;
      data.file_type = values.file_type;
      data.overwrite_empty = values.overwrite_empty;
      data.brand_id = values.brand_id;
      data.vendor_code_id = values.vendor_code_id;
      if (!data.file_path || !data.file_name) {
         this.setState({ formLoader: false, draggerDisable: false });
         notification.open({
            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
            description: languageManager.getMessage(this.props.commonState, 'notification.error.dataNotValid'),
            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
         })
      }
      else {
         storeActions.importCSVProducts(data)
            .then(response => {
               console.log(response);
               try {
                  // this.props.history.goBack();
                  this.setState({ response: response.data, formLoader: false, draggerDisable: false });

                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                     description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               } catch (e) {
                  this.setState({ formLoader: false, draggerDisable: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               }

            }).catch((e) => {
               this.setState({ formLoader: false, draggerDisable: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            });
      }
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }


   render() {
      if (this.state.pageLoader) return <Loader />;
      const { Title } = Typography
      const { Option } = Select
      const { Dragger } = Upload
      const layout = {
         // layout: 'inline',
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };

      const scope = this
      const draggerProps = {
         name: 'file',
         multiple: false,
         disabled: this.state.draggerDisable,
         action: API_BASE.url + API_CONFIG.appUrls.files.fileImport,
         onChange(info) {
            scope.setState({ draggerDisable: info.fileList.length });
            console.log("CHANGE: ", info);
            const { status } = info.file;
            if (status !== 'uploading') {
               console.log(info.file, info.fileList);
            }
            if (status === 'done') {
               message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
               message.error(`${info.file.name} file upload failed.`);
            }
         },
      };

      const normFile = (e) => {
         if (Array.isArray(e)) {
            return e;
         }
         return e && e.fileList;
      };
      let form = (
         <Form
            {...layout}
            id="importFile"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               name="fileImport"
               rules={[{ required: true, message: 'Scegli un file' }]}
               valuePropName='fileList'
               getValueFromEvent={normFile}
               colon={false}
               wrapperCol={24}
            >
               <Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                     <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                     Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                     band files
                  </p>
               </Dragger>
            </Form.Item>
            {/* <Form.Item
               label="Brand"
               name="brand_id"
               rules={[{ required: true, message: 'Seleziona il brand' }]}
            >
               <Select
                  showSearch
                  size="medium"
                  // className="width-70px-I"
                  placeholder="Seleziona il brand"
                  optionFilterProp="children"
               >
                  {
                     this.state.brandsList.map((map) => {
                        return <Option key={map.id} value={map.id}>{map.name}</Option>
                     })
                  }
               </Select>
            </Form.Item> */}
            <Form.Item
               label="Sovrascrivi i campi vuoti"
               name="overwrite_empty"
            >
               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
               />
            </Form.Item>
            <Form.Item
               label="Tipo di file"
               name="file_type"
               rules={[{ required: true, message: 'Scegli il tipo di file' }]}
            >
               <Select
                  showSearch
                  size="medium"
                  // className="width-70px-I"
                  placeholder="Seleziona il tipo di file"
                  optionFilterProp="children"
                  onChange={this.setFileType}
               >
                  {
                     this.state.import_type.map((map) => {
                        return <Option key={map.id} value={map.id}>{map.name}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            {
               this.state.selectedFileType === 6 ? ([
                  <Form.Item
                     label="Brand"
                     name="brand_id"
                     key="brand_id"
                     rules={[{ required: true, message: 'Scegli il brand dei prodotti' }]}
                  >
                     <Select
                        showSearch
                        size="medium"
                        // className="width-70px-I"
                        placeholder="Seleziona il brand dei prodotti"
                        optionFilterProp="children"
                     >
                        {
                           this.state.brandsList.map((brand) => {
                              return <Option key={brand.id} value={brand.id}>{brand.name}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>,
               ]) : null
            }
            {
               this.state.selectedFileType === 8 ? ([
                  <Form.Item
                     label="Vendor Code"
                     name="vendor_code_id"
                     key="vendor_code_id"
                     rules={[{ required: true, message: 'Scegli il Vendor Code a cui associare i prodotti' }]}
                  >
                     <Select
                        showSearch
                        size="medium"
                        // className="width-70px-I"
                        placeholder="Seleziona il Vendor Code"
                        optionFilterProp="children"
                     >
                        {
                           this.state.vendorCodesList.map((code) => {
                              return <Option key={code.id} value={code.id}>{code.code}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>,
               ]) : null
            }
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>
         </Form>
      );


      // const specific_prices_columns = [
      //    {
      //    title: 'Nazione',
      //    dataIndex: 'country',
      //    key: 'country'
      //    },
      //    {
      //       title: 'Ecommerce',
      //       dataIndex: 
      //    }
      // ]


      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.products.import')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space size='small'>
                        <Select
                           showSearch
                           className="width-70px-I"
                           placeholder="Seleziona la lingua"
                           defaultValue={this.state.componentLanguage}
                           optionFilterProp="children"
                           onChange={this.setDefaultLanguageHandler}
                        >
                           {
                              this.props.authState.userData.languages.map((lang) => {
                                 return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                              })
                           }
                        </Select>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               {
                  this.state.response && this.state.response.successes ?
                     <Alert
                        closable
                        message="Importazione completata"
                        description={<List
                           size="small"
                           dataSource={this.state.response.successes}
                           renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                        />}
                        type="success"
                        showIcon
                     />
                     : ''
               }
               {
                  this.state.response && this.state.response.warnings && this.state.response.warnings.length ?
                     <Alert
                        closable
                        message="Attenzione"
                        description={<List
                           size="small"
                           dataSource={this.state.response.warnings}
                           renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                        />}
                        type="warning"
                        showIcon
                     />
                     : ''
               }
               {
                  this.state.response && this.state.response.details ?
                     <Alert
                        closable
                        message="Dettagli importazione"
                        type="info"
                        description={<List
                           size="small"
                           dataSource={this.state.response.details}
                           renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                        />}
                        showIcon
                     />
                     : ''
               }
               {form}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/products/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Button form="importFile" htmlType="reset" disabled={this.state.formLoader}>Annulla</Button>
                        <Button form="importFile" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                        <Button form="importFile" type="primary" htmlType="submit" loading={this.state.formLoader} onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                     </Space>
                  </Col>
               </Row>
            </div>
         </div>
      );
      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);




      console.log(this.state);
      // this.props.action === 'edit' ? (
      //    initial_values = {
      //       name: this.props.itemData.t_name,
      //       percentage: this.props.itemData.percentage
      //    }) : null;

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(ProductsImport);