import React from 'react'
import { connect } from 'react-redux'
// import _ from 'lodash';

import { ClosingOrderStatusEnum } from '../../../shared/enums.ts'
import { Button } from 'antd';


const ClosingOrdersStatusLabel = (props) => {
    let buttonType = 'primary'
    switch (props.value) {
        case ClosingOrderStatusEnum.TOSEND:
            buttonType = 'primary'
            break;
        case ClosingOrderStatusEnum.TOCHECK:
            buttonType = 'warning'
            break;
        case ClosingOrderStatusEnum.SENT:
            buttonType = 'success'
            break;
        default:
            break;
    }

    return (
        <Button type={buttonType} className="cursor-default-I">
            {props.title}
        </Button>
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    };
};

export default connect(mapStateToProps)(ClosingOrdersStatusLabel);
