import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'antd';
import * as consts from '../../constants';

import languageManager from '../../services/languageManager';

const GenericError = ({ commonState, messageKey, code = '' }) => {

    let codeCol;
    if (!_.isEmpty(code)) {
        codeCol = <Col xs={{ span: 15, offset: 3 }} xl={{ span: 12, offset: 6 }}>
            <p>
                {code}
            </p>
        </Col>;
    }

    return (
        <div>
            <Row className='error-page'>
                <Col xs={{ span: 15, offset: 3 }} xl={{ span: 12, offset: 6 }}>
                    <span className={'fa-5x ' + consts.ICON.CLAIM}>&nbsp;</span>
                </Col>
            </Row>
            <Row>
                <Col className='center-text' xs={{ span: 15, offset: 3 }} xl={{ span: 12, offset: 6 }}>
                    <h3>{languageManager.getMessage(commonState, messageKey)}</h3>
                </Col>
                {codeCol}
            </Row>
        </div>
    );
};

GenericError.propTypes = {
    code: PropTypes.number,
    commonState: PropTypes.object.isRequired,
    messageKey: PropTypes.string.isRequired
};

export default GenericError;