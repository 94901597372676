import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { Table, Button, Tooltip, Space, notification, Popconfirm, Typography, Row, Col } from 'antd';
import { InfoCircleOutlined, DeleteOutlined, PlusSquareOutlined, EditOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, CheckOutlined, CloseOutlined, DoubleLeftOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/vendor_codes';

import { startLoading, endLoading } from '../../../shared/utility';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader';

class VendorCodesList extends Component {
   state = {
      componentLanguage: this.props.authState.defaultLanguage,
      pageLoader: true,
      tableLoader: false,
      itemsLoader: [],
      multipleActionLoader: false,
      itemList: [],
      fullListIds: [],

      current_page: 1,
      page_size: 50,
      total_result: 0,

      showTableFilters: false,
      filters: [],
      filtersValues: {},
      appliedFilters: [],

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'delete_selected',
            name: 'Cancella codici selezionati',
            needconfirmation: 'yes',
         },
      ],

      loader: true,
      loadingsActive: [],
      loadingsDelete: [],
      searchText: '',
      searchedColumn: '',
   };

   componentDidMount() {
      Promise.all([
         this.getCodesList(this.state.current_page, this.state.page_size, this.state.filtersValues),
         this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   getCodesList = (page, pageSize, filters) => {
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(page, pageSize, filters)
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  current_page: page,
                  page_size: pageSize,
                  total_result: response.data.total,
                  filters: response.list_filters,
                  appliedFilters: response.applied_filters,
                  tableLoader: false
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  duration: 0,
               })
            }

         }).catch((e) => {
            this.setState({ tableLoader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            try {
               this.setState({
                  activeMarketplacesList: response.activeMarketplacesList,
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  duration: 0,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   toggleActiveHandler = (itemId) => {
      this.setState({ loadingsActive: startLoading(this.state.loadingsActive, itemId) })
      storeActions.toggleActive(itemId)
         .then(response => {
            try {
               var itemIndex = _.findIndex(this.state.itemList, function (item) { return item.id === itemId })
               var newList = this.state.itemList
               newList[itemIndex]['active'] = response.data.active
               this.setState({ itemList: newList, loadingsActive: endLoading(this.state.loadingsActive, itemId) })
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            this.setState({ loadingsActive: endLoading(this.state.loadingsActive, itemId) });
         });
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loadingsDelete: startLoading(this.state.loadingsDelete, itemId) });
      storeActions.deleteItem(itemId)
         .then(response => {
            console.log(response);
            try {
               Promise.all([
                  this.getCodesList(this.state.current_page, this.state.page_size, this.state.filtersValues),
               ]).then(() => {
                  this.setState({
                     loadingsDelete: endLoading(this.state.loadingsDelete, itemId),
                     pageLoader: false
                  });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                     icon: <CheckCircleOutlined className="color-green-I" />,
                  })
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               this.setState({ loadingsDelete: endLoading(this.state.loadingsDelete, itemId) });
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
            this.setState({ loadingsDelete: endLoading(this.state.loadingsDelete, itemId) });
         });
   }
   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            return;
      }
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title } = Typography
      const table_columns = [
         {
            title: 'Marketplace', width: 150, key: 'marketplace', fixed: 'left',
            render: (record) => (
               record.marketplace.name
            ),
            sorter: (a, b) => a.marketplace.name.localeCompare(b.marketplace.name)
         },
         {
            title: 'Codice principale', width: 150, key: 'parent_code', fixed: 'left',
            render: (record) => (
               record.parent ? record.parent.code : null
            ),
            sorter: (a, b) => {
               a = a.parent || { code: '' }
               b = b.parent || { code: '' }
               return a.code.localeCompare(b.code)
            }
         },
         {
            title: 'Vendor Code', key: 'vendor_code', fixed: 'left',
            render: (record) => (
               <a href onClick={(e) => { this.props.history.push('/vendor_codes/edit/' + record.id) }}>{record.code}</a>
            ),
            sorter: (a, b) => a.code.localeCompare(b.code)
         },
         {
            title: 'Attivo', width: 50, dataIndex: 'active', key: 'percentage', align: 'center',
            render: (text, record) => (
               <Tooltip title={languageManager.getMessage(this.props.commonState, record.active ? 'common.deactivate' : 'common.activate')}>
                  <Button type={record.active ? 'success' : 'danger'} size="small" loading={this.state.loadingsActive[record.id]}
                     icon={record.active ? <CheckOutlined /> : <CloseOutlined />}
                     onClick={() => this.toggleActiveHandler(record.id)}
                  />
               </Tooltip>
            )
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <a href='!#' onClick={(e) => { e.preventDefault(); this.props.history.push('/vendor_codes/edit/' + record.id) }}>
                        <Button type="primary" size="small"
                           icon={<EditOutlined />}
                        />
                     </a>
                  </Tooltip>
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.delete')}>
                        <Button type="danger" size="small"
                           icon={<DeleteOutlined />}
                        />
                     </Tooltip>
                  </Popconfirm>
               </Space>),
         },
      ];

      const tableLayout = {
         bordered: true,
         size: "small",
         pagination: { pageSize: 50 },
         loading: this.state.tableLoader
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.vendor_codes.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Link to={{ pathname: '/vendor_codes/edit/' }}>
                           <Button
                              type="primary"
                              icon={<PlusSquareOutlined />}
                           >Aggiungi vendor code</Button>
                        </Link>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} scroll={{ x: 'max-content' }} />
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(VendorCodesList);