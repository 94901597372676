import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";

import SimpleBar from "simplebar-react";

import languageManager from '../../services/languageManager';

const SidebarContent = props => {
   return (
      <div id="sidebar-menu">
         <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">Main</li>

            <li>
               <Link to="/dashboard" className="waves-effect">
                  <i className="ti-home"></i>
                  {/* <span className="badge badge-pill badge-primary float-right">
                     2
                  </span> */}
                  <span>Dashboard</span>
               </Link>
            </li>

            <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.catalogue')}</li>
            <li>
               <Link to="/brands/list" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.brands')}</span>
               </Link>
            </li>
            <li>
               <Link to="/seasons/list" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.seasons')}</span>
               </Link>
            </li>
            <li>
               <Link to="/categories/list" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.categories')}</span>
               </Link>
            </li>
            <li>
               <Link to="/products/list" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.products')}</span>
               </Link>
            </li>
            <li>
               <Link to="/products/list/pending" className="waves-effect menu-indent">
                  {/* <i className="ti-home"></i> */}
                  <span>{languageManager.getMessage(props.commonState, 'nav.pending_products')}</span>
               </Link>
            </li>
            <li>
               <Link to="/search_products" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.search_products')}</span>
               </Link>
            </li>
            {/* <li>
               <Link to="/specifics/list" className="waves-effect">
                  <i className="ti-home"></i>
                  <span>{languageManager.getMessage(props.commonState, 'nav.specifics')}</span>
               </Link>
            </li> */}
            {/* <li>
               <Link to="/variations/list" className="waves-effect">
                  <i className="ti-home"></i>
                  <span>{languageManager.getMessage(props.commonState, 'nav.variations')}</span>
               </Link>
            </li> */}

            <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.purchase_orders')}</li>
            <li>
               <Link to="/purchase_orders/list" className="waves-effect">
                  {/* <i className="ti-home"></i> */}
                  <span>{languageManager.getMessage(props.commonState, 'nav.purchase_orders')}</span>
               </Link>
            </li>
            <li>
               <Link to="/purchase_orders/list/pending" className="waves-effect menu-indent">
                  {/* <i className="ti-home"></i> */}
                  <span>{languageManager.getMessage(props.commonState, 'nav.pending_purchase_orders')}</span>
               </Link>
            </li>
            <li>
               <Link to="/purchase_orders/list/accepted" className="waves-effect menu-indent">
                  {/* <i className="ti-home"></i> */}
                  <span>{languageManager.getMessage(props.commonState, 'nav.accepted_purchase_orders')}</span>
               </Link>
            </li>
            {/* <li>
               <Link to="/expiring_orders/list" className="waves-effect menu-indent">
                  <span>{languageManager.getMessage(props.commonState, 'nav.expiring_orders')}</span>
               </Link>
            </li> */}
            {/* <li>
               <Link to="/vendor_orders/list" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.vendor_orders')}</span>
               </Link>
            </li> */}

            <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.closing_po')}</li>
            <li>
               <Link to="/test/import_file" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.import_file')}</span>
               </Link>
            </li>
            <li>
               <Link to="/closing_orders/list" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.closing_po')}</span>
               </Link>
            </li>

            <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.chargebacks')}</li>
            <li>
               <Link to="/chargebacks/link" className="waves-effect">
                  <i className="ti-money"></i>
                  <span>{languageManager.getMessage(props.commonState, 'nav.chargebacks')}</span>
               </Link>
            </li>

            <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.data_processing')}</li>
            <li>
               <Link to="/dataprocessing/group_sizes" className="waves-effect">
                  <i className="ti-list-ol"></i>
                  <span>{languageManager.getMessage(props.commonState, 'nav.group_sizes')}</span>
               </Link>
            </li>
            <li>
               <Link to="/dataprocessing/search_asins" className="waves-effect">
                  <i className="ti-search"></i>
                  <span>{languageManager.getMessage(props.commonState, 'nav.search_asins')}</span>
               </Link>
            </li>
            <li>
               <Link to="/dataprocessing/search_asins_in_orders" className="waves-effect">
                  <i className="ti-search"></i>
                  <span>{languageManager.getMessage(props.commonState, 'nav.search_asins_in_orders')}</span>
               </Link>
            </li>
            <li>
               <Link to="/dataprocessing/search_ean_upc" className="waves-effect">
                  <i className="ti-search"></i>
                  <span>{languageManager.getMessage(props.commonState, 'nav.search_ean_upc')}</span>
               </Link>
            </li>

            <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.configuration')}</li>
            <li>
               <Link to="/marketplaces/list" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.marketplaces')}</span>
               </Link>
            </li>
            <li>
               <Link to="/vendor_codes/list" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.vendor_codes')}</span>
               </Link>
            </li>

            <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.analysis')}</li>
            <li>
               <Link to="/analysis/categories" className="waves-effect">
                  <span>{languageManager.getMessage(props.commonState, 'nav.categories')}</span>
               </Link>
            </li>

            {/* <li className="menu-title">{languageManager.getMessage(props.commonState, 'nav.test')}</li>
            <li>
               <Link to="/marketplaces/list" className="waves-effect">
                  <i className="ti-home"></i>
                  <span>{languageManager.getMessage(props.commonState, 'nav.marketplaces')}</span>
               </Link>
            </li> */}
         </ul>
      </div>
   );
};

class Sidebar extends Component {
   constructor(props) {
      super(props);
      this.state = {};
      document.body.setAttribute("data-sidebar", this.props.theme);
   }

   componentDidMount() {
      this.initMenu();
   }

   componentDidUpdate(prevProps) {
      if (this.props.type !== prevProps.type) {
         this.initMenu();
      }
   }

   initMenu() {
      // console.log(this.props.location.pathname.split('/'))
      if (this.props.type !== "condensed" || this.props.isMobile) {
         new MetisMenu("#side-menu");

         var matchingMenuItem = null;
         var ul = document.getElementById("side-menu");
         var items = ul.getElementsByTagName("a");
         for (var i = 0; i < items.length; ++i) {
            if (this.props.location.pathname === items[i].pathname) {
               matchingMenuItem = items[i];
               break;
            }
         }
         if (!matchingMenuItem) {
            for (var i = 0; i < items.length; ++i) {
               if (this.props.location.pathname.split('/')[1] === items[i].pathname.split('/')[1]) {
                  matchingMenuItem = items[i];
                  break;
               }
            }
         }
         if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
         }
      }
   }

   activateParentDropdown = item => {
      item.classList.add("mm-active");
      const parent = item.parentElement;

      if (parent) {
         parent.classList.add("mm-active"); // li
         const parent2 = parent.parentElement;

         if (parent2) {
            parent2.classList.add("mm-show");
            parent2.classList.add("mm-active"); // li

            const parent3 = parent.parentElement;

            if (parent3) {
               parent3.classList.add("mm-active"); // li
               parent3.childNodes[0].classList.add("mm-active"); //a
               const parent4 = parent3.parentElement;
               if (parent4) {
                  parent4.classList.add("mm-active");
               }
            }
         }
         return false;
      }
      return false;
   };

   render() {
      return (
         <React.Fragment>
            {this.props.type !== "condensed" ? (
               <SimpleBar style={{ maxHeight: "100%" }}>
                  <SidebarContent commonState={this.props.commonState} />
               </SimpleBar>
            ) : (
               <SidebarContent />
            )}
         </React.Fragment>
      );
   }
}

export default withRouter(Sidebar);
