import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { Table, Button, Space, notification, List, Modal, Typography, Row, Col } from 'antd'
import { QuestionCircleOutlined, DeleteOutlined, PlusSquareOutlined, EditOutlined } from '@ant-design/icons'
// import Highlighter from 'react-highlight-words'
import { DoubleLeftOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/seasons'

import { startLoading, endLoading } from '../../../shared/utility'

import Loader from '../../common/PageElements/Loader'
import PageHeader from '../../common/PageElements/PageHeader'

class VendorCodesList extends Component {
   state = {
      componentLanguage: this.props.authState.defaultLanguage,
      pageLoader: true,
      tableLoader: false,
      multipleActionLoader: false,
      itemList: [],
      fullListIds: [],

      current_page: 1,
      page_size: 50,
      total_result: 0,

      showTableFilters: false,
      filters: [],
      filtersValues: {},
      appliedFilters: [],

      selectedRowKeys: [],
      selectedAll: false,
      multipleActionList: [
         {
            action: 'delete_selected',
            name: 'Cancella stagioni selezionate',
            needconfirmation: 'yes',
         },
      ],

      loadersDelete: [],
   }

   componentDidMount() {
      Promise.all([
         this.getSeasonsList(),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         })
      })
   }

   getSeasonsList = () => {
      this.setState({ tableLoader: true })
      return storeActions.getList()
         .then(response => {
            console.log(response)
            this.setState({
               itemList: response.data,
               // fullListIds: response.full_list_ids,
               // current_page: page,
               // page_size: pageSize,
               // total_result: response.data.total,
               // filters: response.list_filters,
               // appliedFilters: response.applied_filters,
               tableLoader: false
            })
         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e)
         })
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loadingsDelete: startLoading(this.state.loadingsDelete, itemId) })
      storeActions.deleteItem(itemId)
         .then(response => {
            console.log(response)
            try {
               const deletedId = response.data
               const updatedList = this.state.itemList.filter(e => e.id !== deletedId)
               this.setState({
                  itemList: updatedList,
                  loadingsDelete: endLoading(this.state.loadingsDelete, itemId),
                  pageLoader: false
               })
               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
               })
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: e.error ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={e.error}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               })
               this.setState({ loadingsDelete: endLoading(this.state.loadingsDelete, itemId) })
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e)
            this.setState({ loadingsDelete: endLoading(this.state.loadingsDelete, itemId) })
         })
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title } = Typography
      const table_columns = [
         {
            title: 'Stagione', width: 150, key: 'season', fixed: 'left',
            render: (record) => (
               record.name
            ),
            sorter: (a, b) => a.name.localeCompare(b.name)
         },
         {
            title: 'descrizione', key: 'description',
            render: (record) => (
               record.description
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Link to={{pathname: '/seasons/edit/' + record.id}}>
                     <Button type="primary" size='small'
                        icon={<EditOutlined />}
                        disabled={this.state.loadersDelete[record.id]}
                     />
                  </Link>
                  <Button type="danger" size='small'
                     icon={<DeleteOutlined />}
                     loading={this.state.loadersDelete[record.id]}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'common.delete_item'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.deleteItemHandler(record.id)
                        })
                     }
                  />
               </Space>
            ),
         },
      ]

      const tableLayout = {
         bordered: true,
         size: "small",
         pagination: { pageSize: 50 },
         loading: this.state.tableLoader
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.seasons.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Link to={{ pathname: '/seasons/edit/' }}>
                           <Button
                              type="primary"
                              icon={<PlusSquareOutlined />}
                           >Aggiungi stagione</Button>
                        </Link>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} scroll={{ x: 'max-content' }} />
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      )

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      )
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   }
}

export default connect(mapStateToProps)(VendorCodesList)