export default {
    authenticationState: {
        isLoggedIn: false,
        user: undefined,
        userData: {
           languages: []
        },
        defaultLanguage: 'IT'
    },
    commonState: {
        errorCode: 0,
        recentNotification: 0,
        blocking: true,
        cfgLoading: 0,
        store: {
            countries: []
        },
        messages: [],
        debug: true
    },
    layoutState: {
        leftSideBarTheme: "dark",
        leftSideBarType: "default",
        topBarTheme: "dark",
        isMobile: false
    }
};