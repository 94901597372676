import { combineReducers } from 'redux';
import layoutReducer from './layoutReducer';
import authenticationReducer from './authenticationReducer';
import commonReducer from './commonReducer';

const rootReducer = combineReducers({
    layoutState: layoutReducer,
    authenticationState: authenticationReducer,
    commonState: commonReducer
});

export default rootReducer;