import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class EdiApi {

   /**
    *
    * @param
    * @returns {Promise<unknown>}
    */
   static readEdiFile(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.edi.readFile, { ...formData })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default EdiApi;