import _ from 'lodash';

class FetchFunctions {

    static get(url, params, token, isFile = false, isBlob = false) {
        return FetchFunctions.generic('GET', url, params, token, isFile, isBlob);
    }

    static post(url, params, token, isFile = false, isBlob = false, additionalParams = []) {
        return FetchFunctions.generic('POST', url, params, token, isFile, isBlob, process.env.REACT_APP_WEB_API_VERSION, additionalParams);
    }

    static put(url, params, token, isFile = false, isBlob = false) {
        return FetchFunctions.generic('PUT', url, params, token, isFile, isBlob);
    }

    static delete(url, params, token, isFile = false, isBlob = false) {
        return FetchFunctions.generic('DELETE', url, params, token, isFile, isBlob);
    }

    static generic(method, url, params, token, isFile, isBlob, version = process.env.REACT_APP_WEB_API_VERSION, additionalParams = []) {
        var requestInit = {
            method: method,
            mode: 'cors',
            cache: 'default',
            credentials: 'omit',
            headers: new Headers()
        };

        requestInit.headers.append('pragma', 'no-cache');

        /* POSTMAN HEADERS */
        // requestInit.headers.append('Accept', 'application/json');
        // requestInit.headers.append('Content-Type', 'application/json');
        // requestInit.headers.append('X-Requested-With', 'XMLHttpRequest');

        if (!_.isNil(version))
            requestInit.headers.append('api-version', version);

        // Check if this is an authentication request
        if (!_.isNil(token))
            requestInit.headers.append('Authorization', 'Bearer ' + token);

        if (!_.isNil(params)) {
            if (isFile) {
                var formData = new FormData();
                formData.append('file', params);
                requestInit.body = formData;
                if (!_.isNil(additionalParams)) {
                    for (let index = 0; index < additionalParams.length; index++) {
                        const element = additionalParams[index];
                        formData.append(element.name, element.value);
                    }
                }
            } else {
                requestInit.headers.append('Content-Type', 'application/json');
                requestInit.body = JSON.stringify(params);
            }
        }

        return fetch(url, requestInit)
            .then(function (response) {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.status);
                }

                return isBlob ? response.blob() : response.json();

            }).then(function (json) {
                return json;
            }).catch(function (error) {
                if (!_.isEmpty(error.message) && error.message === 'Failed to fetch')
                    throw new Error('Impossibile contattare il server');

                if (!_.isEmpty(error.message))
                    throw error.message;
                throw error;
            });
    }
}

export default FetchFunctions;
