import PurchaseOrdersApi from '../../../../../api/PurchaseOrdersApi';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getList(days = null) {
   return PurchaseOrdersApi.getExpiringList(days)
   .then(response => {
      // if (response.success === true) {
         if (response.data.success === true) {
            response.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getComplements() {
   // return PurchaseOrdersApi.getExpiringOrdersComplements()
   //    .then(response => {
   //       console.log(response);
   //       const responseData = {}
   //       _.forEach(response, (el, i) => {
   //          if(el.data.success !== true) {
   //             const error = el.data.error ? el.data.error : 'Server error'
   //             throw new Error(error)
   //          }
   //          responseData[i] = el.data.data;
   //       });
   //       return responseData;
   //    })
   //    .catch(error => {
   //       // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
   //       throw error;
   //    });
   return {};
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId) {
   return PurchaseOrdersApi.getExpiringItem(itemId)
      .then(response => {
         // if (response.success === true) {
         if (response.data.success === true) {
            console.log(response);
            // return response;
            return response.data;
         }
         else
            throw new Error('Server error');
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}