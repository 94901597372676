import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../services/languageManager'
import { startLoading, endLoading } from '../../../shared/utility'
import * as storeActions from '../store/actions/dashboard'

import { Image, Space, notification, Row, Col, Typography, Card, Table } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import AmazonDataModal from '../../catalogue/products/widget/AmazonDataModal'

class ProductsStats extends Component {
    state = {
        ...this.props,
        componentLoader: true,
        showProductAmazonDataModal: false,
        selectedProductAmazonDataId: null,
        // rangeChangeLoader: false,
        // rangeLoaders: [],
        // rangeSelection: [],
        // rangeSelected: false,
    }

    componentDidMount() {
        Promise.all([
            this.getProductsStatsData(),
            // this.prepareRangeSelection(),
        ]).then(() => {
            this.setState({
                componentLoader: false,
                // rangeChangeLoader: false,
            });
        });
    }

    getProductsStatsData = (range = null) => {
        // this.setState({ rangeLoaders: startLoading(this.state.rangeLoaders, range) });
        // asyncronous calls
        return storeActions.getProductsStatsData(range)
            .then(response => {
                console.log(response);
                try {
                    this.setState({
                        // rangeLoaders: endLoading(this.state.rangeLoaders, range),
                        accepted: response.data.accepted,
                        refused: response.data.refused,
                        notFound: response.data.not_found,
                    });
                } catch (e) {
                    // this.setState({ rangeLoaders: endLoading(this.state.rangeLoaders, range) });
                    notification.open({
                        message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                        description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                        icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                    })
                }

            }).catch((e) => {
                // this.setState({ rangeLoaders: endLoading(this.state.rangeLoaders, range) });
                notification.open({
                    message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                    description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                    icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                })
                console.log(e);
            });
    }

    showProductAmazonData = (productAmazonDataId) => {
        // console.log('showProductAmazonData: ' + productAmazonDataId)
        if (productAmazonDataId) {
            this.setState({
                selectedProductAmazonDataId: productAmazonDataId,
                showProductAmazonDataModal: true,
            })
        }
    }
    hideProductAmazonDataModal = () => {
        this.setState({
            selectedProductAmazonDataId: null,
            showProductAmazonDataModal: false,
        })
    }

    // prepareRangeSelection = () => {
    //     var ranges = []
    //     ranges.push({ value: OrdersStatsRangeEnum.DAILY, title: languageManager.getMessage(this.props.commonState, 'common.ranges.daily') })
    //     ranges.push({ value: OrdersStatsRangeEnum.WEEKLY, title: languageManager.getMessage(this.props.commonState, 'common.ranges.weekly') })
    //     ranges.push({ value: OrdersStatsRangeEnum.MONTHLY, title: languageManager.getMessage(this.props.commonState, 'common.ranges.monthly') })
    //     this.setState({ rangeSelection: ranges, rangeSelected: OrdersStatsRangeEnum.DAILY })
    //     return true
    // }

    // rangeSelectedChangeHandler = (range) => {
    //     this.setState({ rangeChangeLoader: true })
    //     Promise.all([
    //         this.getOrdersStatsData(range),
    //     ]).then(() => {
    //         console.log('Range change end')
    //         this.setState({
    //             rangeChangeLoader: false,
    //             rangeSelected: range
    //         });
    //     });
    // }

    render() {
        const { Title } = Typography;
        const productsRequestedColumns = [
            {
                title: "",
                width: 91, height: 91,
                key: 'img',
                render: (record) => (
                    !_.isNil(record.image) ? <Image width={75} src={record.image} /> : null
                )
            },
            {
                title: 'ASIN',
                width: 75,
                key: 'asin',
                render: (record) => (
                    record.asin
                ),
                sorter: (a, b) => a.asin.localeCompare(b.asin),
            },
            {
                title: 'Prodotto',
                key: 'title',
                render: (record) => (
                    record.amazon_title ? <a href='!#' onClick={(e) => {e.preventDefault(); this.showProductAmazonData(record.amazon_data_id)}}>{record.amazon_title}</a> : null
                ),
                sorter: (a, b) => a.amazon_title.localeCompare(b.amazon_title),
            },
            {
                title: 'Totale',
                width: 100,
                key: 'total',
                render: (record) => (
                    record.total
                ),
                sorter: (a, b) => a.total - b.total,
            },
            {
                title: 'Frequenza richiesta',
                width: 150,
                key: 'frequency',
                render: (record) => (
                    record.frequency + '/mese'
                ),
                sorter: (a, b) => a.frequency - b.frequency,
            },
            {
                title: 'Media richiesta',
                width: 150,
                key: 'avg',
                render: (record) => (
                    record.avg + '/ordine'
                ),
                sorter: (a, b) => a.avg - b.avg,
            },
            // {
            //     title: 'Media richiesta',
            //     width: 100,
            //     key: 'requested',
            //     render: (record) => (
            //         record.requested + '/ordine'
            //     ),
            //     sorter: (a, b) => a.requested - b.requested,
            // },
        ];

        return (
            <section>
                <Row gutter={[8, 24]}>
                    <Col span={24}>
                        <Card loading={this.state.componentLoader || this.state.rangeChangeLoader}
                            title={
                                <Space direction='vertical' size='small'>
                                    <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.products_stats.title')}</Title>
                                </Space>
                            }
                            // extra={
                            //     <Space size='small'>
                            //         {
                            //             this.state.rangeSelection.map((range) => {
                            //                 return (
                            //                     <Button
                            //                         key={range.value}
                            //                         type={range.value === this.state.rangeSelected ? 'primary' : 'default'}
                            //                         className={range.value === this.state.rangeSelected ? 'cursor-default-I' : ''}
                            //                         onClick={range.value === this.state.rangeSelected ? false : () => this.rangeSelectedChangeHandler(range.value)}
                            //                         disabled={this.state.rangeChangeLoader && !this.state.rangeLoaders[range.value]}
                            //                         loading={this.state.rangeLoaders[range.value]}
                            //                     >
                            //                         {range.title}
                            //                     </Button>
                            //                 )
                            //             })
                            //         }
                            //     </Space>
                            // }
                            bordered={false}
                            className="card-dashboard-stats"
                        >
                            <Row gutter={[24, 24]}>
                                {/* <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.waiting_value.box_title')}</Title>
                                        <Title level={1} type="danger">{this.state.waitingValue} €</Title>
                                    </Card >
                                </Col> */}
                                <Col span={24} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.most_accepted_products.box_title')}</Title>
                                        <Table
                                            key='orders_by_marketplace'
                                            dataSource={this.state.accepted}
                                            columns={productsRequestedColumns}
                                            size='small'
                                            pagination={false}
                                            className="table-in-card"
                                        />
                                    </Card >
                                </Col>
                                <Col span={24} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.most_refused_products.box_title')}</Title>
                                        <Table
                                            key='orders_by_vendor_code'
                                            dataSource={this.state.refused}
                                            columns={productsRequestedColumns}
                                            size='small'
                                            pagination={false}
                                            className="table-in-card"
                                        />
                                    </Card >
                                </Col>
                                <Col span={24} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>{languageManager.getMessage(this.props.commonState, 'component.dashboard.not_found_products.box_title')}</Title>
                                        <Table
                                            key='orders_by_vendor_code'
                                            dataSource={this.state.notFound}
                                            columns={productsRequestedColumns}
                                            size='small'
                                            pagination={false}
                                            className="table-in-card"
                                        />
                                    </Card >
                                </Col>
                                {/* <Col span={6} className='display-flex-I'>
                                    <Card loading={this.state.componentLoader} className='card-dashboard-box'>
                                        <Title level={4}>Prodotto più venduto</Title>
                                        <Title level={1}>{this.state.bestSellingItem.name}</Title>
                                        <Title level={4}>{this.state.bestSellingItem.cnt ? this.state.bestSellingItem.cnt + ' ordini' : ''}</Title>
                                    </Card >
                                </Col> */}
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <AmazonDataModal
                    visible={this.state.showProductAmazonDataModal}
                    productDataId={this.state.selectedProductAmazonDataId}
                    onClose={() => this.hideProductAmazonDataModal()}
                />
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        authState: state.authenticationState,
        commonState: state.commonState
    };
}

export default connect(mapStateToProps)(ProductsStats)