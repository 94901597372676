import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form, Input, Select, Switch, Button, Space, notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, CheckOutlined, CloseOutlined, InfoCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/priceRules';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

import SelectCategorySingle from '../../catalogue/categories/widget/SelectCategorySingle'

class PriceRulesEdit extends Component {

   state = {
      saveAndClose: false,
      loader: true,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage,
      categoriesTree: [],
      brandsList: [],
      taxRatesList: [],
      marketplacesList: [],
      showCategorySelect: false,
      showBrandSelect: false,
   };

   setForm = (response, lang = this.state.componentLanguage) => {
      const values = {
         marketplace_id: response.marketplace_id,
         name: response.name,
         description: response.description,
         variation_percentage: response.variation_percentage,
         variation_amount: response.variation_amount,
         tax_rate_id: response.tax_rate_id,
         on_category: response.on_category,
         category_id: response.category_id,
         on_brand: response.on_brand,
         brand_id: response.brand_id,
         id: response.id,
         language: lang
      }
      return values;
   }

   componentDidMount() {
      storeActions.getPriceRulesComplements()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  complementsResponse: response,
                  categoriesTree: response.categoriesTree,
                  brandsList: response.brandsList,
                  taxRatesList: response.taxRatesList,
                  marketplacesList: response.marketplacesList
               });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });

      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id;
         // asyncronous calls
         storeActions.getItem(itemId)
            .then(response => {
               console.log(response);
               try {
                  const updatedValues = this.setForm(response.data);
                  this.setState({
                     action: 'edit',
                     getItemResponse: response.data,
                     formValues: updatedValues,
                     loader: false
                  });
               } catch (e) {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
                  if (this.props.commonState.debug) console.log(e);
               }

            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage
         }
         this.setState({
            formValues: formValues,
            action: 'insert',
            loader: false
         });
      }
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   onFinish = values => {
      console.log(values);
      this.setState({ loader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            console.log(response);
            try {
               this.setState({ loader: false });
               if (this.state.saveAndClose) {
                  this.props.history.push('/price_rules/list');
               }

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               this.setState({ loader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <CloseCircleOutlined className="color-red-I" />,
               })
            }

         }).catch((e) => {
            this.setState({ loader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <CloseCircleOutlined className="color-red-I" />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <CloseCircleOutlined className="color-red-I" />,
      })
   };

   toggleCategorySelect = checked => {
      this.setState({ showCategorySelect: checked });
   }

   toggleBrandSelect = checked => {
      this.setState({ showBrandSelect: checked });
   }

   // formatNumber = event => {
   //    console.log(event.target.value)
   //    // value.replace(',', '.');
   // }

   render() {
      if (!this.props.authState.userData.languages) return '';
      // console.log(this.props.authState.userData.languages);
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 14 },
      };
      const { TextArea } = Input;
      const { Option } = Select;


      let form = (
         <Form
            {...layout}
            id="formPriceRules"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Nome variazione"
               name="name"
               placeholder="Inserisci il nome"
               rules={[{ required: true, message: 'Inserisci il nome' }]}
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Marketplace"
               name="marketplace_id"
               rules={[{ required: true }]}
            >

               <Select
                  showSearch
                  // style={{ width: 200 }}
                  placeholder="Seleziona il marketplace"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
               //  filterOption={(input, option) =>
               //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               //  }
               >
                  {
                     this.state.marketplacesList.map((marketplace) => {
                        return <Option key={marketplace.id} value={marketplace.id}>{marketplace.name + ' (' + marketplace.country.code + ')'}</Option>
                     })
                  }
               </Select>

            </Form.Item>
            <Form.Item
               label="Variazione percentuale"
               name="variation_percentage"
               rules={[{ message: 'Inserisci il valore' }]}
            >
               <Input
                  type="number"
                  addonAfter="%"
                  style={{ width: 150 }}
                  min={0}
                  max={90}
                  step={0.01}
                  precision={2}
               />
            </Form.Item>
            <Form.Item
               label="Variazione fissa"
               name="variation_amount"
               rules={[{ message: 'Inserisci il valore' }]}
            >
               <Input
                  type="number"
                  addonAfter="€"
                  style={{ width: 150 }}
                  min={0}
                  max={90}
                  step={0.01}
                  precision={2}
               />
            </Form.Item>
            <Form.Item
               label="Regime IVA"
               name="tax_rate_id"
               rules={[]}
            >

               <Select
                  showSearch
                  placeholder="Seleziona il regime IVA"
                  optionFilterProp="children"
               >
                  {
                     this.state.taxRatesList.map((tax) => {
                        return <Option key={tax.id} value={tax.id}>{tax.name[this.state.componentLanguage]}</Option>
                     })
                  }
               </Select>

            </Form.Item>
            <Form.Item
               label="Variazione su categoria"
               name="on_category"
            >
               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={this.state.formValues.active}
                  onChange={this.toggleCategorySelect}
               />
            </Form.Item>

            {this.state.showCategorySelect ? (
               <Form.Item
                  label="Seleziona la categoria"
                  name="category_id"
                  rules={[{ required: this.state.showCategorySelect }]}
               >
                  <SelectCategorySingle
                     categories_tree={this.state.categoriesTree}
                     placeholder="Seleziona la categoria"
                  />
               </Form.Item>
            ) : ''}

            <Form.Item
               label="Variazione su brand"
               name="on_brand"
            >
               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={this.state.formValues.active}
                  onChange={this.toggleBrandSelect}
               />
            </Form.Item>

            {this.state.showBrandSelect ? (
               <Form.Item
                  label="Seleziona il brand"
                  name="brand_id"
                  rules={[{ required: this.state.showBrandSelect }]}
               >
                  <Select
                     showSearch
                     placeholder="Seleziona il brand"
                     optionFilterProp="children"
                  >
                     {
                        this.state.brandsList.map((brand) => {
                           return <Option key={brand.id} value={brand.id}>{brand.name}</Option>
                        })
                     }
                  </Select>
               </Form.Item>
            ) : ''}


            <Form.Item
               label="Attivo"
            >
               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={this.state.formValues.active}
               />
            </Form.Item>
            <Form.Item
               label="Descrizione"
               name="description"
               rules={[{ message: 'Inserisci la descrizione' }]}
            >
               <TextArea
                  rows={4}
               />
            </Form.Item>

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>

         </Form>
      );

      function onChange(value) {
         console.log(`selected ${value}`);
      }

      function onBlur() {
         console.log('blur');
      }

      function onFocus() {
         console.log('focus');
      }

      function onSearch(val) {
         console.log('search:', val);
      }



      const pageContent = (
         <div className="card">
            <div className="card-header">{languageManager.getMessage(this.props.commonState, 'component.priceRules.title')}</div>
            <div className="card-body">
               {this.state.loader ? <Loader /> : form}
            </div>
            <div className="card-footer">
               <div className="align-items-center row">
                  <div className="col-sm-6">
                     <Space>
                        <Button
                           size='large'
                           icon={<LeftCircleOutlined />}
                           onClick={() => { this.props.history.goBack() }}
                        >Indietro</Button>
                     </Space>
                  </div>

                  <div className="col-sm-6">
                     <div className="float-right d-none d-md-block">

                        <Space>
                           <Button form="formPriceRules" htmlType="button" onclick={this.onReset}>Annulla</Button>
                           <Button form="formPriceRules" type="primary" htmlType="submit">Salva</Button>
                           <Button form="formPriceRules" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                        </Space>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <PageHeader
               title={languageManager.getMessage(this.props.commonState, 'component.priceRules.title')}
               buttons={[]}
            />
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(PriceRulesEdit);