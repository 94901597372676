import React from 'react'
import { connect } from 'react-redux'
// import _ from 'lodash';

import { OrderStatusEnum } from '../../../shared/enums.ts'
import { Button } from 'antd';


const OrdersStatusLabel = (props) => {
    let buttonType = 'primary'
    switch (props.value) {
        case OrderStatusEnum.WAITING_ACCEPTANCE:
            buttonType = 'primary'
            break;
        case OrderStatusEnum.ACCEPTED_TOCHECK:
        case OrderStatusEnum.UPDATED_TOCHECK:
        case OrderStatusEnum.REFUSED_TOCHECK:
        case OrderStatusEnum.UNKNOWN:
            buttonType = 'warning'
            break;
        case OrderStatusEnum.CLOSED:
            buttonType = 'success'
            break;
        case OrderStatusEnum.REFUSED:
            buttonType = 'danger'
            break;
        default:
            break;
    }

    return (
        <Button type={buttonType} className="cursor-default-I">
            {props.title}
        </Button>
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    };
};

export default connect(mapStateToProps)(OrdersStatusLabel);
