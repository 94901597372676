import React from 'react'
import { connect } from 'react-redux'
// import _ from 'lodash';

import languageManager from '../../../services/languageManager'
import { ClosingOrderStatusEnum } from '../../../shared/enums.ts'
import DropdownMenu from '../../common/components/DropdownMenu'


const ClosingOrdersStatusButton = (props) => {

    const list = [
        {
            id: ClosingOrderStatusEnum.TOSEND,
            name: languageManager.getMessage(props.commonState, 'common.components.closing_order_status_button.TOSEND')
        },
        {
            id: ClosingOrderStatusEnum.TOCHECK,
            name: languageManager.getMessage(props.commonState, 'common.components.closing_order_status_button.TOCHECK')
        },
        {
            id: ClosingOrderStatusEnum.SENT,
            name: languageManager.getMessage(props.commonState, 'common.components.closing_order_status_button.SENT')
        },
    ]
    return (
        /* <DropdownButton
            type='primary'
            title='Azioni multiple'
            action={this.multipleActionHandler}
            values={this.state.multipleActionList}
            disabled={!hasSelected}
            loading={this.state.multipleActionLoader}
        /> */
        <DropdownMenu
            type='primary'
            title={languageManager.getMessage(props.commonState, 'common.components.closing_order_status_button.title')}
            action={props.action}
            values={list}
            disabled={props.disabled}
            loading={props.loader}
        />
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    };
};

export default connect(mapStateToProps)(ClosingOrdersStatusButton);
