import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Form, Input, Button, Space, notification, Row, Col, Typography } from 'antd';
import { InfoCircleOutlined, DoubleLeftOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';
import * as storeActions from './store/actions/brands';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader'

class BrandsEdit extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageloader: true,
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage
   };

   componentDidMount() {
      if (this.props.match.params.id) {
         const itemId = this.props.match.params.id;
         this.setState({ action: 'edit' });
         // asyncronous calls
         storeActions.getItem(itemId)
            .then(response => {
               console.log(response);
               try {
                  const updatedValues = {
                     code: response.data.code,
                     name: response.data.name,
                     // description: response.data.description[this.state.componentLanguage],
                     // visible: response.data.visible[this.state.componentLanguage],
                     id: response.data.id,
                     language: this.state.componentLanguage
                  }
                  this.setState({ formValues: updatedValues, pageloader: false });
               } catch (e) {
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               }

            }).catch((e) => {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
      else {
         const formValues = {
            language: this.state.componentLanguage
         }
         this.setState({ formValues: formValues, action: 'insert', pageloader: false });
      }

   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   onFinish = values => {
      this.setState({ pageloader: true });
      storeActions.createOrUpdate(values)
         .then(response => {
            this.setState({ pageloader: false });
            console.log(response);
            try {
               if (this.state.saveAndClose) {
                  this.props.history.push('/brands/list');
               }

               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {

            }

         }).catch((e) => {
            this.setState({ pageloader: false });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   // onReset = () => {
   //   form.resetFields();
   // };

   render() {
      if (this.state.pageloader) return <Loader />
      const { Title } = Typography
      const layout = {
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };

      let form = (
         <Form
            {...layout}
            id="createBrand"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               label="Codice"
               name="code"
            >
               <Input />
            </Form.Item>
            <Form.Item
               label="Name"
               name="name"
               rules={[{ required: true, message: 'Inserisci il nome' }]}
            >
               <Input />
            </Form.Item>

            {/* <Form.Item
               label="Visibile"
            >

               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  defaultChecked={this.state.formValues.visible}
               />
            </Form.Item> */}

            {/* <Form.Item
               label="Descrizione"
               name="description"
               rules={[{ message: 'Inserisci la descrizione' }]}
            >
               <TextArea
                  rows={4}
               />
            </Form.Item> */}

            <Form.Item label="id" name="id" noStyle><Input type="hidden" /></Form.Item>
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>

         </Form>
      );


      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.brands.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
            <div className="card-body">
               {form}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/brands/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Button form="createBrand" htmlType="button" onClick={this.onReset}>Annulla</Button>
                        <Button form="createBrand" type="primary" htmlType="submit">Salva</Button>
                        <Button form="createBrand" type="primary" htmlType="submit" onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                     </Space>
                  </Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(BrandsEdit);