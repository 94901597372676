import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import API_BASE from '../../../api/apiBase'
import API_CONFIG from '../../../api/apiConfig'

import { Form, Input, Select, Button, Space, notification, Alert, List, Row, Col, Typography } from 'antd'
import { Upload, message } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { InfoCircleOutlined, DoubleLeftOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/test'
import * as vendorCodeActions from '../../configuration/vendor_codes/store/actions/vendor_codes'

import Loader from '../../common/PageElements/Loader'

class ImportFile extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      importLoader: false,
      selectedFileType: null,
      showImportResult: false,
      vendorCodeList: {},
      formValues: {},
      componentLanguage: this.props.authState.defaultLanguage,
      draggerDisable: false,
      import_type: [
         {
            id: 1,
            name: 'EDIFILE Ordine'
         },
         {
            id: 2,
            name: 'EXCEL Ordini da chiudere'
         },
         {
            id: 3,
            name: 'DETTAGLI PO'
         },
         {
            id: 5,
            name: 'Disponibilità Fornitore'
         },
         {
            id: 4,
            name: 'Magazzini Amazon'
         },
         {
            id: 6,
            name: 'Stato PO confermati'
         },
         {
            id: 7,
            name: 'Stato PO storici'
         },
         {
            id: 8,
            name: 'Aggiorna quantità disponibili via EDI'
         },
      ]
   };

   componentDidMount() {
      Promise.all([
         this.getVendorCodeList()
      ]).then(() => {
         const formValues = {
            language: this.state.componentLanguage
         }
         this.setState({ formValues: formValues, pageLoader: false });
      });
   }

   getVendorCodeList = () => {
      return vendorCodeActions.getList()
         .then(response => {
            console.log(response);
            try {
               this.setState({ vendorCodeList: response.data });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
      console.log(this.state);
   }

   setFileType = (value) => {
      console.log(value)
      this.setState({ selectedFileType: value })
   }

   onFinish = values => {
      console.log(values);
      this.setState({ importLoader: true });
      const data = {};
      data.stock = values.quantity;
      data.vendor_code_id = values.vendor_code_id;
      data.language = values.language;
      data.file_path = values.fileImport[0].response.data.file_path;
      data.file_name = values.fileImport[0].response.data.file_name;
      data.file_type = values.file_type;
      if (!data.file_path || !data.file_name) {
         this.setState({ importLoader: false, draggerDisable: false });
         notification.open({
            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
            description: languageManager.getMessage(this.props.commonState, 'notification.error.dataNotValid'),
            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
         })
      }
      else {
         storeActions.importFile(data)
            .then(response => {
               console.log(response);
               try {
                  // this.props.history.goBack();
                  this.setState({ response: response.data, showImportResult: true, importLoader: false, draggerDisable: false });

                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                     description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               } catch (e) {
                  this.setState({ importLoader: false, draggerDisable: false });
                  notification.open({
                     message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                     description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                     icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
                  })
               }

            }).catch((e) => {
               this.setState({ importLoader: false, draggerDisable: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            });
      }
   };

   onFinishFailed = errorInfo => {
      notification.open({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
         icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
      })
   };

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }

   render() {
      if (this.state.pageLoader || _.isNil(this.props.authState.userData.languages)) return <Loader />
      const { Title } = Typography
      const layout = {
         // layout: 'inline',
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };
      const { Option } = Select;
      const scope = this;

      const normFile = e => {
         console.log('Upload event:', e);
         if (Array.isArray(e)) {
            return e;
         }
         return e && e.fileList;
      };

      const draggerProps = {
         name: 'file',
         multiple: false,
         disabled: this.state.draggerDisable,
         action: API_BASE.url + API_CONFIG.appUrls.files.fileImport,
         onChange(info) {
            scope.setState({ draggerDisable: info.fileList.length });
            console.log("CHANGE: ", info);
            const { status } = info.file;
            if (status !== 'uploading') {
               console.log(info.file, info.fileList);
            }
            if (status === 'done') {
               message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
               message.error(`${info.file.name} file upload failed.`);
            }
         },
      };

      let form = (
         <Form
            {...layout}
            id="importFile"
            name="basic"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               name="fileImport"
               rules={[{ required: true, message: 'Scegli un file' }]}
               valuePropName="fileList"
               getValueFromEvent={normFile}
               colon={false}
               wrapperCol={24}
            >
               <Upload.Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                     <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                     Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                     band files
                  </p>
               </Upload.Dragger>
            </Form.Item>
            <Form.Item
               label="Tipo di file"
               name="file_type"
               rules={[{ required: true, message: 'Scegli il tipo di file' }]}
            >

               <Select
                  showSearch
                  size="large"
                  // className="width-70px-I"
                  placeholder="Seleziona il tipo di file"
                  optionFilterProp="children"
                  onChange={this.setFileType}
               >
                  {
                     this.state.import_type.map((map) => {
                        return <Option key={map.id} value={map.id}>{map.name}</Option>
                     })
                  }
               </Select>

            </Form.Item>
            {
               this.state.selectedFileType === 8 ? ([
                  <Form.Item
                     label="Quantità disponibile"
                     name="quantity"
                     key="quantity"
                     rules={[{ required: true, message: 'Inserisci la quantità' }]}
                  >
                     <Input type='number' min={0} step={1} />
                  </Form.Item>,
                     <Form.Item
                        label="Vendor Code"
                        name="vendor_code_id"
                        rules={[{ required: true, message: 'Scegli il vendor code' }]}
                     >

                        <Select
                           showSearch
                           placeholder="Seleziona il vendor code"
                           optionFilterProp="children"
                        >
                           {
                              this.state.vendorCodeList.map((vendorCode) => {
                                 return <Option key={vendorCode.id} value={vendorCode.id}>{vendorCode.code + ' - ' + vendorCode.description}</Option>
                              })
                           }
                        </Select>
                     </Form.Item>
               ]) : null
            }
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>
         </Form>
      );

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.purchase_orders.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Select
                           showSearch
                           className="width-70px-I"
                           placeholder="Seleziona la lingua"
                           defaultValue={this.state.componentLanguage}
                           optionFilterProp="children"
                           onChange={this.setDefaultLanguageHandler}
                        >
                           {
                              this.props.authState.userData.languages.map((lang) => {
                                 return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                              })
                           }
                        </Select>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               {
                  this.state.response && this.state.response.successes ?
                     <Alert
                        closable
                        message="Importazione completata"
                        description={<List
                           size="small"
                           dataSource={this.state.response.successes}
                           renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                        />}
                        type="success"
                        showIcon
                     />
                     : ''
               }
               {
                  this.state.response && this.state.response.warnings && this.state.response.warnings.length ?
                     <Alert
                        closable
                        message="Attenzione"
                        description={<List
                           size="small"
                           dataSource={this.state.response.warnings}
                           renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                        />}
                        type="warning"
                        showIcon
                     />
                     : ''
               }
               {
                  this.state.response && this.state.response.details ?
                     <Alert
                        closable
                        message="Dettagli importazione"
                        type="info"
                        description={<List
                           size="small"
                           dataSource={this.state.response.details}
                           renderItem={item => <List.Item className='padding-0-I'>{item}</List.Item>}
                        />}
                        showIcon
                     />
                     : ''
               }
               {form}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Button form="importFile" onClick={() => window.location.reload()} loading={this.state.importLoader}>Annulla</Button>
                        <Button form="importFile" type="primary" htmlType="submit" onClick={this.handleSaveAndClose} loading={this.state.importLoader}>Importa</Button>
                     </Space>
                  </Col>
               </Row>
            </div>
         </div>
      );
      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);


      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(ImportFile);