import ClosingPurchaseOrdersApi from '../../../../../api/ClosingPurchaseOrdersApi';
import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getListExtended(params = [], filterValues = {}) {
   return ClosingPurchaseOrdersApi.getListExtended(params, filterValues)
      .then(response => {
         if (response.data.success === true) {
            response.data.data.data.forEach(el => {
               el.key = el.id;
            });
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getItem(itemId) {
   return ClosingPurchaseOrdersApi.getItem(itemId)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function getClosingOrdersComplements() {
   return ClosingPurchaseOrdersApi.getClosingOrdersComplements()
      .then(response => {
         console.log(response);
         const responseData = {}
         _.forEach(response, (el, i) => {
            if(el.data.success !== true) {
               const error = el.data.error ? el.data.error : 'Server error'
               throw new Error(error)
            }
            responseData[i] = el.data.data;
         });
         return responseData;
      })
      .catch(error => {
         // dispatch(systemFatalError(consts.ERROR_CODES.HTTP.UNAUTHORIZED));
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function sendClosingPOUpdates(orderId, lineIds) {
   return ClosingPurchaseOrdersApi.sendClosingPOUpdates(orderId, lineIds)
      .then(response => {
         if (response.data.success === true) {
            console.log(response);
            return response.data;
         }
         else
            throw new Error(response.data.data.error ? response.data.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

export function sendMultipleUpdates(itemIds) {
   return ClosingPurchaseOrdersApi.sendMultipleUpdates(itemIds)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function exportOrders(filename, orderIds) {
   return ClosingPurchaseOrdersApi.exportOrders(filename, orderIds)
      .then(response => {
         console.log(response);
         let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
         return true;
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function exportToCSV(filename, orderId, lineIds) {
   return ClosingPurchaseOrdersApi.exportToCSV(filename, orderId, lineIds)
      .then(response => {
         console.log(response);
         let blob = new Blob(['\ufeff' + response.data], { type: 'application/*' })
         let link = document.createElement('a')
         link.href = window.URL.createObjectURL(blob)
         link.download = filename;
         link.click();
         return true;
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function deleteItem(itemId, return_list = false) {
   return ClosingPurchaseOrdersApi.deleteItem(itemId, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}
export function deleteItems(itemIds, return_list = false) {
   return ClosingPurchaseOrdersApi.deleteItems(itemIds, return_list)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function updateOrderStatus(orderId, newStatus) {
   return ClosingPurchaseOrdersApi.updateOrderStatus(orderId, newStatus)
      .then(response => {
         console.log(response);
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}