import React, { Component } from 'react';
import _ from 'lodash'
import Moment from 'moment'
import { CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';
import { Card, Form, InputNumber, DatePicker, Button, Space, Select, Descriptions } from 'antd';
import { formatCountdown } from 'antd/lib/statistic/utils';

const { Option } = Select;

class TabPrices extends Component {
   state = {
      ...this.props,
      operator: false,
      value: false,
      preview: false,
      startDate: null,
      endDate: null,
      validateForm: false,
      variationOperators: [
         {
            value: '=',
            label: 'Prezzo fisso'
         },
         {
            value: '%-',
            label: 'Sconto percentuale'
         },
         {
            value: '%+',
            label: 'Aumento percentuale'
         },
         {
            value: '-',
            label: 'Sconto fisso'
         },
         {
            value: '+',
            label: 'Aumento fisso'
         }
      ],
      actualPrice: null
   }

   componentDidMount = () => {
      console.log(this.state);
      if (!_.isNil(this.state.marketplace.custom_prices[0])) {
         // console.log(this.state.currentSpecific.id);
         // console.log(this.getListIndex(this.state.specificsList, this.state.currentSpecific.specific.id));
         this.setState(
            {
               operator: this.state.marketplace.custom_prices[0].variation_operator,
               value: this.state.marketplace.custom_prices[0].variation_amount,
            }
         )
      }
   }

   componentDidUpdate = (prevProps, prevState) => {
      // console.log('prevState', prevState)
      // console.log('state', this.state)
      // console.log('prevProps', prevProps)
      // console.log('props', this.props)

      if (!_.isEqual(prevState.marketplace, this.props.marketplace)) {
         console.log('PROPS UPDATE')
         this.setState(
            {
               ...this.props,
            }
         )
         if (!_.isNil(this.state.marketplace.custom_prices[0])) {
            // console.log(this.state.currentSpecific.id);
            // console.log(this.getListIndex(this.state.specificsList, this.state.currentSpecific.specific.id));
            this.setState(
               {
                  operator: this.state.marketplace.custom_prices[0].variation_operator,
                  value: this.state.marketplace.custom_prices[0].variation_amount,
               }
            )
         }
      }
   }

   // getListIndex = (list, id) => {
   //    let found
   //    _.forEach(list, function (obj, index) {
   //       // console.log(spec, index);
   //       if (obj.id === id) {
   //          found = index
   //          return false
   //       }
   //    });
   //    return parseInt(found)
   // }


   // handleSpecificChange = (value) => {
   //    // console.log(value);
   //    // console.log(this.getListIndex(this.state.specificsList, value));
   //    this.setState(
   //       {
   //          valuesList: this.state.specificsList[this.getListIndex(this.state.specificsList, value)].specific_values,
   //          currentSpecific: this.state.specificsList[this.getListIndex(this.state.specificsList, value)],
   //          currentValue: null,
   //          currentSpecificId: null
   //       }
   //    )
   // };

   // handleValueChange = (value) => {
   //    // this.setState(
   //    //    {
   //    //       currentValue: this.state.valuesList[this.getListIndex(this.state.valuesList, value)],
   //    //       currentSpecificId: value
   //    //    }
   //    // )
   //    let specific = null
   //    _.forEach(this.state.valuesList, function (obj) {
   //       if (obj.id === value) {
   //          specific = obj
   //          return false
   //       }
   //    });
   //    if (!_.isNil(specific)) {
   //       specific.specific = { ...this.state.currentSpecific }
   //       specific.specific.specific_values = null
   //       this.state.updateSpecific(this.state.index, specific)
   //    }
   // };

   handleSavePrice = () => {
      console.log('save prices')
      const variationAmount = parseFloat(this.state.value).toFixed(2).toString()
      const preview = parseFloat(this.state.preview).toFixed(2).toString()
      const startDate = !_.isNil(this.state.startDate) ? Moment(this.state.startDate).format('YYYY-MM-DD HH:mm') : null
      const endDate = !_.isNil(this.state.endDate) ? Moment(this.state.endDate).format('YYYY-MM-DD HH:mm') : null
      this.state.savePrice(this.state.operator, variationAmount, startDate, endDate)
   }

   calculatePreviewPrice = (operator, value) => {
      let preview
      const basePrice = parseFloat(this.state.marketplace.prices.price_list)
      switch (operator) {
         case '=':
            preview = value;
            break;
         case '%+':
            preview = basePrice * (1 + value / 100);
            break;
         case '%-':
            preview = basePrice * (1 - value / 100);
            break;
         case '+':
            preview = basePrice + value;
            break;
         case '-':
            preview = basePrice - value;
            break;
         default:
            preview = 0;
            break;
      }
      return parseFloat(preview).toFixed(2)
   }

   setOperation = (value) => {
      this.setState({ operator: value, preview: this.calculatePreviewPrice(value, this.state.value) })
   }
   setValue = (value) => {
      this.setState({ value: value, preview: this.calculatePreviewPrice(this.state.operator, parseFloat(value)) })
   }
   setDates = (values) => {
      console.log(values)
      this.setState({ startDate: values[0], endDate: values[1] })
   }
   render() {
      console.log('tabPrices state', this.state)
      const { RangePicker } = DatePicker
      return (
         <Card title={this.state.marketplace.name}>
            <Descriptions column={1} size='small'>
               <Descriptions.Item label='Prezzo di listino'>{this.state.marketplace.prices.price_list}</Descriptions.Item>
               {
                  this.state.marketplace.prices.price_sale ?
                     <Descriptions.Item label='Prezzo scontato'>
                        {this.state.marketplace.prices.price_sale + '€'}
                        {!_.isNil(this.state.marketplace.prices.rules_set_name) ? ' (' + this.state.marketplace.prices.rules_set_name + ')' : ''}
                     </Descriptions.Item>
                     : ''
               }
            </Descriptions>
            <Space direction='vertical' size='small'>
               <Row>
                  <Col>
                     <Space>
                        <Select
                           size="small"
                           showSearch
                           // className="width-70px-I"
                           placeholder="Seleziona operazione"
                           optionFilterProp="children"
                           onChange={this.setOperation}
                        >
                           {
                              this.state.variationOperators.map((operator) => {
                                 return <Option key={operator.value} value={operator.value}>{operator.label}</Option>
                              })
                           }
                        </Select>
                        <InputNumber
                           size="small"
                           // formatter={value => `€ ${value}`}
                           parser={value => value.replace(/[^0-9,]/g, '')}
                           onChange={this.setValue}
                        />
                        <InputNumber
                           size="small"
                           value={this.state.preview}
                           disabled
                           formatter={value => `€ ${value}`}
                        />
                        <Button
                           size="small"
                           type='success'
                           icon={<CheckOutlined />}
                           onClick={this.handleSavePrice}
                        />
                        <Button
                           size="small"
                           type='danger' icon={<DeleteOutlined />}></Button>
                     </Space>
                  </Col>
               </Row>
               <Row>
                  <Col>
                     <RangePicker
                        className="width-300px-I"
                        size="small"
                        showTime
                        format='L HH:mm'
                        onChange={this.setDates}
                     />
                  </Col>
               </Row>
            </Space>
         </Card>
         /* <Select
            showSearch
            style={{ width: 300 }}
            placeholder="Seleziona la specifica"
            onChange={this.handleSpecificChange}
            optionFilterProp="children"
            filterOption={(input, option) =>
               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
               optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            value={_.isNil(this.state.currentSpecific) ? '' : this.state.currentSpecific.name.it}

         >
            {this.state.specificsList.map(item => (
               <Option key={item.id} value={item.id}>
                  {item.t_name}
               </Option>
            ))}
         </Select>
         <Select
            showSearch
            style={{ width: 300 }}
            placeholder="Seleziona il valore"
            onChange={this.handleValueChange}
            optionFilterProp="children"
            filterOption={(input, option) =>
               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            filterSort={(optionA, optionB) =>
               optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
            }
            value={_.isNil(this.state.currentValue) ? '' : this.state.currentValue.name.it}
         >
            {this.state.valuesList.map(item => (
               <Option key={item.id} value={item.id}>
                  {item.name.it}
               </Option>
            ))}
         </Select>
         <Button type="danger" icon={<DeleteOutlined />} onClick={() => this.state.deleteSpecific(this.state.index)} />
         {/* {
            this.state.index === 'new_spec' ?
               <Button type="success" icon={<CheckOutlined />} onClick={() => this.state.saveSpecific()} />
               :
               <Button type="danger" icon={<DeleteOutlined />} onClick={() => this.state.deleteSpecific()} />
         } */
      )

   }
};


export default TabPrices;

