import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { Table, Button, Tooltip, Space, notification, Popconfirm, Typography, Row, Col } from 'antd';
import { InfoCircleOutlined, SettingOutlined, DeleteOutlined, PlusSquareOutlined, EditOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, CheckOutlined, CloseOutlined, DoubleLeftOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/marketplaces';

import { startLoading, endLoading } from '../../../shared/utility';

import Loader from '../../common/PageElements/Loader';
import PageHeader from '../../common/PageElements/PageHeader';

class MarketplacesList extends Component {
   state = {
      pageloader: true,
      itemList: [],
      loadingsActive: [],
      loadingsDelete: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage
   };

   componentDidMount() {
      console.log(this.state);

      // asyncronous calls
      storeActions.getList()
         .then(response => {
            console.log(response);
            try {
               this.setState({ itemList: response.data, pageloader: false });
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   deleteItemHandler = (itemId) => {
      this.setState({ loadingsActive: startLoading(this.state.loadingsDelete, itemId) });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               this.setState({ itemList: response.data, loadingsActive: endLoading(this.state.loadingsDelete, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   confirmHandler = (onConfirm, itemId) => {
      switch (onConfirm) {
         case 'deleteItem':
            if (itemId) {
               this.deleteItemHandler(itemId);
            }
            break;
         default:
            return;
      }
   }
   toggleActiveHandler = (itemId) => {
      this.setState({ loadingsActive: startLoading(this.state.loadingsActive, itemId) });
      storeActions.toggleActive(itemId)
         .then(response => {
            console.log(response);
            try {
               this.setState({ itemList: response.data, loadingsActive: endLoading(this.state.loadingsActive, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.updateOk'),
                  icon: <CheckCircleOutlined className="color-green-I" />,
               })
            } catch (e) {
               this.setState({ loadingsActive: endLoading(this.state.loadingsActive, itemId) });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            this.setState({ loadingsActive: endLoading(this.state.loadingsActive, itemId) });
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
         });
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title } = Typography
      const table_columns = [
         {
            title: 'Nome', width: 250, key: 'name', fixed: 'left',
            render: (record) => (
               <a href='!#' onClick={(e) => { e.preventDefault(); this.props.history.push('/marketplaces/edit/' + record.id) }}>{record.name}</a>
            ),
            sorter: (a, b) => a.name.localeCompare(b.name)
         },
         { title: 'Descrizione', dataIndex: 'description', key: 'description' },
         {
            title: 'Tipo Marketplace', width: 150, key: 'type',
            render: (record) => (
               record.marketplace_base.name
            ),
            sorter: (a, b) => a.marketplace_base.name.localeCompare(b.marketplace_base.name)
         },
         {
            title: 'Nazione', width: 100, key: 'country',
            render: (record) => (
               record.country.name[this.state.componentLanguage]
            ),
            sorter: (a, b) => a.country.name[this.state.componentLanguage].localeCompare(b.country.name[this.state.componentLanguage])
         },
         {
            title: 'Lingua', width: 100, key: 'language',
            render: (record) => (
               record.language.name[this.state.componentLanguage]
            ),
            sorter: (a, b) => a.language.name[this.state.componentLanguage].localeCompare(b.language.name[this.state.componentLanguage])
         },
         {
            title: 'Valuta', width: 100, key: 'currency',
            render: (record) => (
               record.currency.name[this.state.componentLanguage]
            ),
            sorter: (a, b) => a.currency.name[this.state.componentLanguage].localeCompare(b.currency.name[this.state.componentLanguage])
         },
         {
            title: 'Attivo', width: 50, dataIndex: 'active', key: 'percentage', align: 'center',
            render: (text, record) => (
               <Tooltip title={languageManager.getMessage(this.props.commonState, record.active ? 'common.deactivate' : 'common.activate')}>
                  <Button type={record.active ? 'success' : 'danger'} size="small" loading={this.state.loadingsActive[record.id]}
                     icon={record.active ? <CheckOutlined /> : <CloseOutlined />}
                     onClick={() => this.toggleActiveHandler(record.id)}
                  />
               </Tooltip>
            )
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.config')}>
                     <Button type="primary" size="small"
                        icon={<SettingOutlined />}
                        onClick={() => this.props.history.push('/marketplaces/config/' + record.id)}
                     />
                  </Tooltip>
                  <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.edit')}>
                     <Button type="primary" size="small"
                        icon={<EditOutlined />}
                        onClick={() => this.props.history.push('/marketplaces/edit/' + record.id)}
                     />
                  </Tooltip>
                  <Popconfirm
                     placement="topRight"
                     title={languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm')}
                     onConfirm={() => this.confirmHandler('deleteItem', record.id)}
                     okText={languageManager.getMessage(this.props.commonState, 'common.yes')}
                     cancelText={languageManager.getMessage(this.props.commonState, 'common.no')}
                  >
                     <Tooltip title={languageManager.getMessage(this.props.commonState, 'common.delete')}>
                        <Button type="danger" size="small"
                           icon={<DeleteOutlined />}
                        />
                     </Tooltip>
                  </Popconfirm>
               </Space>),
         },
      ];

      const tableLayout = {
         bordered: true,
         size: "small",
         pagination: { pageSize: 50 }
      }

      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.marketplaces.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Link to={{ pathname: '/marketplaces/edit/' }}>
                           <Button
                              type="primary"
                              icon={<PlusSquareOutlined />}
                           >Aggiungi marketplace</Button>
                        </Link>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} scroll={{ x: 'max-content' }} />
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(MarketplacesList);