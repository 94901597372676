import ChargebacksApi from '../../../../api/ChargebacksApi';
// import _ from 'lodash';

/**
 *
 * @param
 * @returns {function(*): Promise<unknown>}
 */
export function importChargebacks(formData) {
   return ChargebacksApi.importChargebacks(formData)
      .then(response => {
         if (response.data.success === true) {
            return response.data;
         }
         else
            throw new Error(response.data.error ? response.data.error : 'Server error');
      })
      .catch(error => {
         throw error;
      });
}